import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import Axios from 'axios'
import Swal from 'sweetalert2'

import Menus from './Menus'
import Pie from './Pie'

const alerta = (tipo,titulo) =>{
  Swal.fire({
  type: tipo,
  title: titulo
  });
}

class Buzon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      asunto:'',
      tipo:'',
      mensaje:''
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentWillMount () {
    window.H5_loading.show();
    window.scrollTo(0, 0)
  }
  
  componentDidMount () {
    window.H5_loading.hide();
  }

  handleChange(e) {
    const {name, value} = e.target;
    this.setState({[name]:value})
  }

  handleClick () {
    const {asunto, tipo, mensaje} = this.state;

    if(!asunto || !tipo || !mensaje){
      alerta('error','Un campo requerido está vacio');
    }else{
      window.H5_loading.show();
      var params = new URLSearchParams();
      params.append('_USUARIO', sessionStorage.usuario);
      params.append('_ROL', sessionStorage._ROL);
      params.append('_Activa', sessionStorage._Activa);
      params.append('asunto', asunto);
      params.append('tipo', tipo);
      params.append('mensaje', mensaje);

      Axios.post(`${window.ws}wsBuzon.php`, params)
        .then(response => {
          if(response.data.elError===1){
            alerta('success',''+response.data.mensaje);
            this.listaAsistencia();
            this.setState({
              asunto: '',
              tipo: '',
              mensaje: ''
            });
          }else{
            alerta('error',''+response.data.mensaje);
          }
        })
        .catch(function (error) {
          alerta('error',''+error);
          console.log(error)
        })
        .then(function(response) {
          window.H5_loading.hide();
        });
    }
  }

  render () {
    return (
      <div className="flex_smenu">
        <Menus />
        <div id={this.props.ver_smenu}>
          <div className="max_width padding_width">
            <h10>Buzón de quejas y sugerencias</h10>
            <div className="linea"></div>
            <div id="cl"><br/></div>
            
            <div className="pa_flex_2">
              <div className="de_flex_2 infcontacto" data-aos="fade-right">
                <form id="formulario">
                  <h25>Deja tu mensaje:</h25>
                  <div id="cl"><br/></div>
                  <input type="text" name="asunto" onChange={this.handleChange} placeholder="Asunto:" value={this.state.asunto}/>
                  <textarea name="mensaje" id="area" onChange={this.handleChange} placeholder="Mensaje:" value={this.state.mensaje}/>                  
                  <center><div onClick={this.handleClick} className="button puntero">ENVIAR</div></center>
                </form>
              </div>
              <div className="de_flex_2" data-aos="fade-left">
                <h13>Siguenos en redes sociales:</h13>
                <div className="redes_contacto">
                  {
                    window._my_redes.map((item, i) => 
                      <a href={item.link} target="_new" className="de_flex_contacto" key={i}><img src={item.imagen} alt={item.nombre} title={item.nombre}/></a>
                    )
                  }
                </div>
              </div>
            </div>

        </div>
        <Pie />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    ver_smenu: state.ver_smenu
  }
};

const Liga = withRouter(Buzon);
export default connect(mapStateToProps)(Liga);