import { createStore } from 'redux';
import reducerMenu from './reducerMenu';

const store = createStore(reducerMenu);

export default store;
/*
import { createStore, combineReducers } from 'redux';

import reducerCarrito from './reducerCarrito'
import reducerMenu from './reducerMenu'

const reducer = combineReducers ({
  reducerCarrito,
  reducerMenu
});

const store = createStore(reducer);

export default store;
*/