import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import Axios from 'axios'
import Swal from 'sweetalert2'
import Modal from 'react-bootstrap/Modal';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { Element, Link as Ir } from 'react-scroll';

import Menus from './Menus'
import Pie from './Pie'

const alerta = (tipo,titulo) =>{
  Swal.fire({
  type: tipo,
  title: titulo
  });
}

class Usuarios extends Component {
  constructor(props) {
    super(props);
    this.state = {
      c_nombre: '',
      c_correo: '',
      c_status: '',
      c_rol: '',
      c_ubicacion: '',

      show: false,
      ver: 0,

      lista_usuarios: [],
      
      nombre:'',
      correo:'',
      curp:'',
      telefono:'',
      estado:'',
      municipio:'',
      pass:'',
      pass2:'',
      fecha_nac:'',
      ubicacion:'',

      fecha:'',
      hora:'',
      status:'',
      logeo:'',
      rol:'',
    };
    this.botonEnviar = this.botonEnviar.bind(this);
    this.handleChange = this.handleChange.bind(this);
    //this.handleClick = this.handleClick.bind(this);
  }

  componentWillMount () {
    window.H5_loading.show();
    window.scrollTo(0, 0)

    if(!sessionStorage._ROL || sessionStorage._ROL!=='ADMIN'){
      sessionStorage.setItem('_mensajeAdmin', 'error_1')
      this.props.history.push('inicio');
    }
  }
  
  componentDidMount () {
    window.H5_loading.hide();
  }

  handleChange(e) {
    const {name, value} = e.target;
    this.setState({[name]:value})
  }

  handleShow(ver){
    window.H5_loading.show();
    var params = new URLSearchParams();
    params.append('_USUARIO', sessionStorage.usuario);
    params.append('_ROL', sessionStorage._ROL);
    params.append('_Activa', sessionStorage._Activa);
    params.append('id', ver);

    Axios.post(`${window.ws}ConsultarUsuarios.php?que=1`, params)
        .then(response => {
          if(response.data.elError===2){
            alerta('error',''+response.data.mensaje);
          }else{
            this.setState({
              nombre: response.data.us_nombre,
              correo: response.data.us_correo,
              curp: response.data.us_curp,
              telefono: response.data.us_telefono,
              estado: response.data.us_estado,
              municipio: response.data.us_municipio,
              fecha_nac: response.data.us_fecha_nac,

              fecha: response.data.us_fecha,
              hora: response.data.us_hora,
              status: response.data.us_status,
              logeo: response.data.us_logeo,
              rol: response.data.us_rol,
              ubicacion: response.data.us_ubicacion,

              show: true,
              ver: ver 
            })
          }
        })
        .catch(error => {
          console.log(error)
          alerta('error',''+error);
        })
        .then(function() {
            window.H5_loading.hide();
        })
  };
  handleHide(){
    this.setState({ show: false });
  };

  delSo (e) {
      Swal.fire({
        title: '¿Deseas desactivar esta cuenta?',
        //text: "You won't be able to revert this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, desactivar!'
      }).then((result) => {
        if (result.value) {
              window.H5_loading.show();

              var params = new URLSearchParams();
              params.append('_USUARIO', sessionStorage.usuario);
              params.append('_ROL', sessionStorage._ROL);
              params.append('_Activa', sessionStorage._Activa);
              params.append('id', e);

              Axios.post(`${window.ws}ConsultarUsuarios.php?que=2`, params)
                  .then(response => {
                    if(response.data.elError===1){
                      alerta('success',''+response.data.mensaje);
                      this.listaUsuario();
                    }else{
                      alerta('error',''+response.data.mensaje);
                    }
                  })
                  .catch(error => {
                    console.log(error)
                    //alerta('error',''+error);
                  })
                  .then(function() {
                      window.H5_loading.hide();
                  })
        }
      })
  }

  botonEnviar () {
    this.listaUsuario();
  }

  handleClick(e) {
    const {ver, nombre, correo, curp, telefono, estado, municipio, fecha_nac, pass, pass2, status, rol, ubicacion} = this.state;
    if(nombre===''){
      alerta('error','Ingresa el nombre completo');
    }else if(correo===''){
      alerta('error','Ingresa el correo electrónico');
    }else if(window.validarMail(correo)===false){
      alerta('error','Ingrese un correo electrónico valido');
    }else if(curp===''){
      alerta('error','Ingresa el CURP');
    }else if(pass!==pass2){
      alerta('error','Las contraseñas no coinciden');
    }else if(telefono===''){
      alerta('error','Ingresa el número telefónico');
    }else if(fecha_nac===''){
      alerta('error','Ingresa la fecha de nacimiento');
    }else if(estado===''){
      alerta('error','Selecciona el Estado');
    }else if(municipio===''){
      alerta('error','Selecciona el Municipio');
    }else{
      window.H5_loading.show();
      //let formData = new FormData(document.getElementById('formulario'));
      //Axios.post(window.ws+'ConsultarUsuarios.php?que=3', formData)
      var params = new URLSearchParams();
      params.append('_USUARIO', sessionStorage.usuario);
      params.append('_ROL', sessionStorage._ROL);
      params.append('_Activa', sessionStorage._Activa);
      params.append('id', ver);
      params.append('nombre', nombre);
      params.append('correo', correo);
      params.append('curp', curp);
      params.append('telefono', telefono);
      params.append('estado', estado);
      params.append('municipio', municipio);
      params.append('pass', pass);
      params.append('pass2', pass2);
      params.append('fecha_nac', fecha_nac);
      params.append('status', status);
      params.append('rol', rol);
      params.append('ubicacion', ubicacion);

      Axios.post(`${window.ws}ConsultarUsuarios.php?que=3`, params)
        .then(response => {
          if(response.data.elError===1){
            alerta('success',''+response.data.mensaje);
            this.listaUsuario();
            this.setState({show: false})
          }else{
            alerta('error',''+response.data.mensaje);
          }
        })
        .catch(function (error) {
          alerta('error',''+error);
          console.log(error)
        })
        .then(function(response) {
          window.H5_loading.hide();
        });
    }
  }

  listaUsuario() {
    window.H5_loading.show();
    var params = new URLSearchParams();
    params.append('_USUARIO', sessionStorage.usuario);
    params.append('_ROL', sessionStorage._ROL);
    params.append('_Activa', sessionStorage._Activa);
    params.append('nombre', this.state.c_nombre);
    params.append('correo', this.state.c_correo);
    params.append('status', this.state.c_status);
    params.append('rol', this.state.c_rol);
    params.append('ubicacion', this.state.c_ubicacion);

    Axios.post(`${window.ws}ConsultarUsuarios.php`, params)
        .then(response => {
          if(response.data.elError===2){
            alerta('error',''+response.data.mensaje);
          }else{
            this.setState({lista_usuarios: response.data})
            if(response.data.elError===1){
              alerta('warning',''+response.data.mensaje);
            }
          }
        })
        .catch(error => {
          console.log(error)
          alerta('error',''+error);
        })
        .then(function() {
            window.H5_loading.hide();
        })
  }

  render () {
    return (
      <div className="flex_smenu">
        <Menus />
        <div id={this.props.ver_smenu}>
          <div className="max_width padding_width">
            <h10>Consultar usuarios</h10>
            <div className="linea"></div>
            <div id="cl"><br/></div>
            <form id="formulario2">
              <div className="pa_flex_form">
                <div className="de_flex_form">
                  Nombre:<br/>
                  <input type="text" name="c_nombre" onChange={this.handleChange}/>
                  <div id="cl"></div>
                  Correo:<br/>
                  <input type="text" name="c_correo" onChange={this.handleChange}/>
                  <div id="cl"></div>
                </div>
                <div className="de_flex_form">
                  Encargo:<br/>
                  <select name="c_rol" className="select" onChange={this.handleChange}>
                    <option value=""></option>
                    <option value="vacio">Sin cargo</option>
                    <option value="CAP">Capturista</option>
                    <option value="TEL">Telefonista</option>
                    <option value="SUP">Supervisor</option>
                    <option value="REC">Recepción</option>
                    <option value="VEN">Vendedor</option>
                    <option value="ADMIN">Administrador</option>
                  </select>
                  <div id="cl"></div>
                  Ubicación:<br/>
                  <select name="c_ubicacion" className="select" onChange={this.handleChange}>
                    <option value=""></option>
                    {
                      window._my_ubicacion.map((item, i) =>
                        <option value={i} key={i}>{item}</option>
                      )
                    }
                  </select>
                  <div id="cl"></div>
                  Estatus:<br/>
                  <select name="c_status" className="select" onChange={this.handleChange}>
                    <option value=""></option>
                    <option value="0">Nuevo</option>
                    <option value="1">Validado</option>
                    <option value="3">Aprovado</option>
                    <option value="2">Inactivo</option>
                  </select>
                </div>
              </div>
              <div id="cl"><br/></div>
              <Ir to="recorrer" activeClass="active" spy={true} smooth={true} duration={1000}><div onClick={this.botonEnviar} className="button puntero">Consultar</div></Ir>
            </form>
            <div id="cl"></div>
            <Element name="recorrer"></Element>
            {
              this.state.lista_usuarios.length > 0 ?
                <div>
                  <div id="cl"><br/></div>
                  <div className="linea"></div>
                  <div id="cl"><br/></div>
                  <ReactHTMLTableToExcel
                    className="derecha sin_submit"
                    table="table-to-xls"
                    filename="Usuarios"
                    buttonText={<img src="img/ico_excel.svg" alt="Excel" className="derecha sin_submit" title="Descargar Excel"/>}
                    />
                  <div id="cl"></div>
                  Resultados encontrados: {this.state.lista_usuarios.length}
                  <div className="tabla_responsiva">
                    <table className="carrillo" cellspacing="3">
                      <tr>
                        <th>#</th>
                        <th>Nombre</th>
                        <th>Correo</th>
                        <th>Teléfono</th>
                        <th>Estatus</th>
                        <th>Encargo</th>
                        <th>Logeo</th>
                        <th></th>
                      </tr>
                      {
                        this.state.lista_usuarios.map((item, i) =>
                          <tr key={i}>
                            <td>{item[0]}</td>
                            <td>{item[2]}</td>
                            <td>{item[3]}</td>
                            <td>{item[4]}</td>
                            <td style={item[5] === 2 ? {backgroundColor:'#ff859a'}: null}>
                              {
                                item[5] === 0 ?
                                  'Nuevo'
                                : item[5] === 1 ?
                                  'Validado'
                                : item[5] === 2 ?
                                  'Inactivo'
                                : 
                                  'Aprovado'
                              }
                            </td>
                            <td>
                              {
                                item[6] === 'CAP' ?
                                  'Capturista'
                                : item[6] === 'TEL' ?
                                  'Telefonista'
                                : item[6] === 'SUP' ?
                                  'Supervisor'
                                : item[6] === 'REC' ?
                                  'Recepción'
                                : item[6] === 'VEN' ?
                                  'Vendedor'
                                : item[6] === 'ADMIN' ?
                                  'Administrador'
                                : 
                                  'Sin cargo'
                              }
                              <br/>
                              {
                                window._my_ubicacion.filter((p, n) => n===item[14]).map(ubi => ubi)
                              }
                            </td>
                            <td>{item[7]}</td>
                            <td>
                              <img src="img/editar.png" alt="Editar" title="Editar" height="20px" onClick={this.handleShow.bind(this, item[1])} className="grises puntero"/>
                              {item[5] === 2 ? null : <img src="img/eliminar.png" alt="Desactivar" title="Desactivar" height="20px" onClick={this.delSo.bind(this, item[1])} className="grises puntero"/>}
                            </td>
                          </tr>
                        )
                      }
                    </table>
                  </div>
                  <table id="table-to-xls" style={{display:"none"}}>
                    <tr>
                      <th>#</th>
                      <th>Nombre</th>
                      <th>Correo</th>
                      <th>CURP</th>
                      <th>Teléfono</th>
                      <th>Fecha de nacimiento</th>
                      <th>Estado</th>
                      <th>Municipio/Delegación</th>
                      <th>Fecha registro</th>
                      <th>Hora registro</th>
                      <th>Estatus</th>
                      <th>Rol</th>
                      <th>Ubicación</th>
                      <th>Contraseña WebMail</th>
                      <th>Logeo</th>
                      <th>IP</th>
                    </tr>
                    {
                      this.state.lista_usuarios.map((item, i) =>
                        <tr key={i}>
                          <td>{item[0]}</td>
                          <td>{item[2]}</td>
                          <td>{item[3]}</td>
                          <td>{item[15]}</td>
                          <td>{item[4]}</td>
                          <td>{item[8]}</td>
                          <td>{item[9]}</td>
                          <td>{item[10]}</td>
                          <td>{item[11]}</td>
                          <td>{item[12]}</td>
                          <td>
                            {
                              item[5] === 0 ?
                                'Nuevo'
                              : item[5] === 1 ?
                                'Validado'
                              : item[5] === 2 ?
                                'Inactivo'
                              : 
                                'Aprovado'
                            }
                          </td>
                          <td>
                            {
                              item[6] === 'CAP' ?
                                'Capturista'
                              : item[6] === 'TELE' ?
                                'Telefonista'
                              : item[6] === 'SUP' ?
                                'Supervisor'
                              : item[6] === 'REC' ?
                                'Recepción'
                              : item[6] === 'VEN' ?
                                'Vendedor'
                              : item[6] === 'ADMIN' ?
                                'Administrador'
                              : 
                                'Sin cargo'
                            }
                          </td>
                          <td>
                            {
                              window._my_ubicacion.filter((p, n) => n===item[14]).map(ubi => ubi)
                            }
                          </td>
                          <td>{item[16]}</td>
                          <td>{item[7]}</td>
                          <td>{item[13]}</td>
                        </tr>
                      )
                    }
                  </table>
                </div> 
              :
                null
            }
          </div>
          <Pie />
        </div>
        <Modal
          show={this.state.show}
          onHide={this.handleHide.bind(this)}
          dialogClassName="modal-90w"
          className="modal_fer"
          aria-labelledby="example-custom-modal-styling-title">

          <Modal.Body className="modal_cuadro" data-aos="fade-down">
            <div className="jh-close" onClick={this.handleHide.bind(this)}>X</div>
              <form id="formulario">
                <center><h10>Editar usuario</h10></center>
                <div id="cl"><br/></div>
                <table>
                  <tr>
                    <td>Nombre:</td>
                    <td><input type="text" name="nombre" onChange={this.handleChange} placeholder="Nombre completo" value={this.state.nombre} required/></td>
                  </tr>
                  <tr>
                    <td>Correo:</td>
                    <td><input type="text" name="correo" onChange={this.handleChange} placeholder="Correo electrónico" value={this.state.correo} required/></td>
                  </tr>
                  <tr>
                    <td>Contraseña:&nbsp;&nbsp;&nbsp;</td>
                    <td>
                      <input type="password" name="pass" onChange={this.handleChange} placeholder="Contraseña" value={this.state.pass}/>
                      <input type="password" name="pass2" onChange={this.handleChange} placeholder="Confirmar contraseña" value={this.state.pass2}/>
                    </td>
                  </tr>
                  <tr>
                    <td>CURP:</td>
                    <td><input type="text" name="curp" placeholder="Clave Única de Registro de Población" onChange={this.handleChange} value={this.state.curp} required/></td>
                  </tr>
                  <tr>
                    <td>Teléfono:</td>
                    <td><input type="text" name="telefono" onChange={this.handleChange} placeholder="Teléfono" value={this.state.telefono} required/></td>
                  </tr>
                  <tr>
                    <td>Fecha de nacimiento:</td>
                    <td><input type="date" name="fecha_nac" onChange={this.handleChange} placeholder="Día/Mes/Año" value={this.state.fecha_nac} required/></td>
                  </tr>
                  <tr>
                    <td>Estado:</td>
                    <td>
                      <select name="estado" className="select" onChange={this.handleChange} required>
                        <option value="" selected={this.state.estado === "" ? true : false}>- Selecciona -</option>
                        {
                          window._my_estado.map((item, i) => 
                            <option value={item.estado} key={i} selected={this.state.estado === item.estado ? true : false}>{item.estado}</option>
                          )
                        }
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td>Municipio<br/>Delegación:</td>
                    <td>
                      <select name="municipio" className="select" onChange={this.handleChange} required>
                        <option value="" selected={this.state.municipio === "" ? true : false}>- Selecciona -</option>
                        {
                          window._my_estado.filter(p => p.estado === this.state.estado).map(esta => 
                              esta.municipios.map((item, i) =>
                                <option value={item} key={i} selected={this.state.municipio === item ? true : false}>{item}</option>
                              )
                          )
                        }
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td>Cargo:</td>
                    <td>
                      <select name="rol" className="select" onChange={this.handleChange} required>
                        <option value="" selected={this.state.rol === "" ? true : false}></option>
                        <option value="CAP" selected={this.state.rol === "CAP" ? true : false}>Capturista</option>
                        <option value="TEL" selected={this.state.rol === "TEL" ? true : false}>Telefonista</option>
                        <option value="SUP" selected={this.state.rol === "SUP" ? true : false}>Supervisor</option>
                        <option value="REC" selected={this.state.rol === "REC" ? true : false}>Recepción</option>
                        <option value="VEN" selected={this.state.rol === "VEN" ? true : false}>Vendedor</option>
                        <option value="ADMIN" selected={this.state.rol === "ADMIN" ? true : false}>Administrador</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td>Ubicación:</td>
                    <td>
                      <select name="ubicacion" className="select" onChange={this.handleChange} required>
                        <option value="" selected={this.state.ubicacion === "" ? true : false}></option>
                        {
                          window._my_ubicacion.map((item, i) =>
                            <option value={i} key={i} selected={this.state.ubicacion === i ? true : false}>{item}</option>
                          )
                        }
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td>Estatus:</td>
                    <td>
                      <select name="status" className="select" onChange={this.handleChange} required>
                        <option value="" selected={this.state.status === "" ? true : false}></option>
                        <option value="0" selected={this.state.status === 0 ? true : false}>Nuevo</option>
                        <option value="1" selected={this.state.status === 1 ? true : false}>Validado</option>
                        <option value="3" selected={this.state.status === 3 ? true : false}>Aprovado</option>
                        <option value="2" selected={this.state.status === 2 ? true : false}>Inactivo</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td>Fecha registro:</td>
                    <td><h11>{this.state.fecha}</h11></td>
                  </tr>
                  <tr>
                    <td>Hora registro:</td>
                    <td><h11>{this.state.hora}</h11></td>
                  </tr>
                  <tr>
                    <td>Fecha logeo:</td>
                    <td><h11>{this.state.logeo}</h11></td>
                  </tr>
                </table>
                <div id="cl"><br/></div>
                <center><div onClick={this.handleClick.bind(this)} className="button_login puntero">Editar</div></center>
                <div id="cl"></div>
                <div className="espaciado_final"></div>
              </form>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    ver_smenu: state.ver_smenu
  }
};

const Liga = withRouter(Usuarios);
export default connect(mapStateToProps)(Liga);