import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import Axios from 'axios';
import Swal from 'sweetalert2'
import Modal from 'react-bootstrap/Modal';

const alerta = (tipo,titulo) =>{
  Swal.fire({
  type: tipo,
  title: titulo
  });
}

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nombre:'',
      correo:'',
      curp:'',
      telefono:'',
      estado:'',
      municipio:'',
      pass:'',
      pass2:'',
      fecha_nac:'',

      usuario:'',
      contrasena:'',

      show: false,
      ver: 0,

      rec_correo: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.recuperarCorreo = this.recuperarCorreo.bind(this);
    this.elLogeo = this.elLogeo.bind(this);
  }

  componentWillMount () {
    window.H5_loading.show();
    window.scrollTo(0, 0)

    if(window.location.href.substr(0, 6)==='https:' && window.location.href!=='http://localhost:3000/'){
      window.location.href = window.rutas;
    }

    if(sessionStorage.usuario){
      /*
      sessionStorage.removeItem('usuario')
      sessionStorage.removeItem('_ROL')
      sessionStorage.removeItem('_Nombre')
      */
      window.location.href = window.rutas+"inicio";
    }

    sessionStorage.removeItem('_mensajeAdmin')
    if(sessionStorage.usuario && sessionStorage._ROL && sessionStorage._ROL!==''){
      this.props.history.push('inicio');
    }else{
      if(sessionStorage._mensaje==='error_1'){
        alerta('error','No puedes ingresar a esta sección, primero debes iniciar tu sesión');
        sessionStorage.removeItem('_mensaje')
      }
      if(sessionStorage._mensaje==='error_2'){
        alerta('error','No tienes permisos para ingresar a esta sección');
        sessionStorage.removeItem('_mensaje')
      }
    }
  }
  
  componentDidMount () {
    window.H5_loading.hide();
  }

  handleShow(ver){
    window.H5_loading.show();
    this.setState({ show: true, ver: ver });
    window.H5_loading.hide();
  };
  handleHide(){
    this.setState({ show: false });
  };

  handleChange(e) {
    const {name, value} = e.target;
    this.setState({[name]:value})
  }

  elLogeo() {
    const {usuario, contrasena} = this.state;
    if(usuario===''){
      alerta('error','Ingresa tu usuario');
    }else if(contrasena===''){
      alerta('error','Ingresa tu contraseña');
    }else{
      window.H5_loading.show();
      let formData2 = new FormData(document.getElementById('formulario2'));
      Axios.post(window.ws+'wsLogin.php', formData2)
      .then(function (response) {
        if(response.data.elError===1){
          sessionStorage.setItem('usuario', usuario.toLowerCase())
          sessionStorage.setItem('_ROL', response.data.elRol)
          sessionStorage.setItem('_Nombre', response.data.elNombre)
          sessionStorage.setItem('_Activa', response.data.elActiva)
          window.location.href = window.rutas+"inicio";
        }else{
          alerta('error',''+response.data.mensaje);
        }
      })
      .catch(function (error) {
        //alerta('error','asd '+error);
        console.log(error)
      })
      .then(function(response) {
        window.H5_loading.hide();
        //window.location.href = window.rutas+'carrito';
      });
    }
  }

  recuperarCorreo(e) {
    const correo = this.state.rec_correo;
    if(correo===''){
      alerta('error','Ingresa tu correo electrónico');
    }else if(window.validarMail(correo)===false){
      alerta('error','Ingrese un correo electrónico valido');
    }else{
      window.H5_loading.show();
      let formData = new FormData(document.getElementById('formulario3'));
      Axios.post(window.ws+'wsRecuperarCorreo.php', formData)
      .then(response => {
        if(response.data.elError===1){
          alerta('success',''+response.data.mensaje);
          this.setState({
            rec_correo:'',
          });
        }else{
          alerta('error',''+response.data.mensaje);
        }
      })
      .catch(function (error) {
        alerta('error',''+error);
        console.log(error)
      })
      .then(function(response) {
        window.H5_loading.hide();
        //window.location.href = window.rutas+'carrito';
      });
    }
  }

  handleClick(e) {
    const {nombre, correo, curp, telefono, estado, municipio, fecha_nac, pass, pass2} = this.state;
    if(nombre===''){
      alerta('error','Ingresa tu nombre completo');
    }else if(correo===''){
      alerta('error','Ingresa tu correo electrónico');
    }else if(window.validarMail(correo)===false){
      alerta('error','Ingrese un correo electrónico valido');
    }else if(curp===''){
      alerta('error','Ingresa tu CURP');
    }else if(pass===''){
      alerta('error','Ingresa una contraseña');
    }else if(pass2===''){
      alerta('error','Confirma la contraseña');
    }else if(pass!==pass2){
      alerta('error','Las contraseñas no coinciden');
    }else if(telefono===''){
      alerta('error','Ingresa tu número telefónico');
    }else if(fecha_nac===''){
      alerta('error','Ingresa tu fecha de nacimiento');
    }else if(estado===''){
      alerta('error','Selecciona un Estado');
    }else if(municipio===''){
      alerta('error','Selecciona un Municipio');
    }else{
      window.H5_loading.show();
      let formData = new FormData(document.getElementById('formulario'));
      Axios.post(window.ws+'wsRegistroUsuario.php', formData)
      .then(response => {
        if(response.data.elError===1){
          alerta('success',''+response.data.mensaje);
          this.setState({
            nombre:'',
            correo:'',
            curp:'',
            telefono:'',
            estado:'',
            municipio:'',
            pass:'',
            pass2:'',
            show: false,
          });
        }else{
          alerta('error',''+response.data.mensaje);
        }
      })
      .catch(function (error) {
        alerta('error',''+error);
        console.log(error)
      })
      .then(function(response) {
        window.H5_loading.hide();
        //window.location.href = window.rutas+'carrito';
      });
    }
  }

  render () {
    return (
      <div className="fondo_invictus">
        <div className="max_width">
          <div className="flex_login">
            <div className="login_ver">
              <form id="formulario2">
                <center><h10>Iniciar sesión con tu cuenta</h10></center>
                <div id="cl"><br/></div>
                Usuario:<br/>
                <input type="text" name="usuario" onChange={this.handleChange} value={this.state.usuario}/>
                Contraseña:<br/>
                <input type="password" name="contrasena" onChange={this.handleChange} value={this.state.contrasena}/>
                <div id="cl"><br/></div>
                <center><div onClick={this.elLogeo} className="button_login puntero">INICIAR SESIÓN</div></center>
              </form>
              <div id="cl"><br/><center></center><br/></div>
              <div className="pa_flex_olvidaste">
                <div className="links puntero" onClick={this.handleShow.bind(this, 1)}>Registrarme</div>
                <div className="espacio">|</div>
                <div className="links puntero" onClick={this.handleShow.bind(this, 2)}>¿Olvidaste tu contraseña?</div>
              </div>
            </div>
          </div>
        </div>
        <Modal
            show={this.state.show}
            onHide={this.handleHide.bind(this)}
            dialogClassName="modal-90w"
            className="modal_fer"
            aria-labelledby="example-custom-modal-styling-title">

            <Modal.Body className="modal_cuadro" data-aos="fade-down">
              <div className="jh-close" onClick={this.handleHide.bind(this)}>X</div>
              {
                this.state.ver === 1 ? 
                  <form id="formulario">
                    <center><h10>Proporciona los siguientes datos y registrate</h10></center>
                    <div id="cl"><br/></div>
                    <table>
                      <tr>
                        <td>Nombre:</td>
                        <td><input type="text" name="nombre" onChange={this.handleChange} placeholder="Nombre completo" value={this.state.nombre} required/></td>
                      </tr>
                      <tr>
                        <td>Correo:</td>
                        <td><input type="text" name="correo" onChange={this.handleChange} placeholder="Correo electrónico" value={this.state.correo} required/></td>
                      </tr>
                      <tr>
                        <td>Contraseña:&nbsp;&nbsp;&nbsp;</td>
                        <td>
                          <input type="password" name="pass" onChange={this.handleChange} placeholder="Contraseña" value={this.state.pass} required/>
                          <input type="password" name="pass2" onChange={this.handleChange} placeholder="Confirmar contraseña" value={this.state.pass2} required/>
                        </td>
                      </tr>
                      <tr>
                        <td>CURP:</td>
                        <td><input type="text" name="curp" placeholder="Clave Única de Registro de Población" onChange={this.handleChange} value={this.state.curp} required/></td>
                      </tr>
                      <tr>
                        <td>Teléfono:</td>
                        <td><input type="text" name="telefono" onChange={this.handleChange} placeholder="Teléfono" value={this.state.telefono} required/></td>
                      </tr>
                      <tr>
                        <td>Fecha de nacimiento:</td>
                        <td><input type="date" name="fecha_nac" onChange={this.handleChange} placeholder="Día/Mes/Año" value={this.state.fecha_nac} required/></td>
                      </tr>
                      <tr>
                        <td>Estado:</td>
                        <td>
                          <select name="estado" className="select" onChange={this.handleChange} required>
                            <option value="" selected={this.state.estado === "" ? true : false}>- Selecciona -</option>
                            {
                              window._my_estado.map((item, i) => 
                                <option value={item.estado} key={i} selected={this.state.estado === item.estado ? true : false}>{item.estado}</option>
                              )
                            }
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <td>Municipio<br/>Delegación:</td>
                        <td>
                          <select name="municipio" className="select" onChange={this.handleChange} required>
                            <option value="" selected={this.state.municipio === "" ? true : false}>- Selecciona -</option>
                            {
                              window._my_estado.filter(p => p.estado === this.state.estado).map(esta => 
                                  esta.municipios.map((item, i) =>
                                    <option value={item} key={i} selected={this.state.municipio === item ? true : false}>{item}</option>
                                  )
                              )
                            }
                          </select>
                        </td>
                      </tr>
                    </table>
                    <div id="cl"><br/></div>
                    <center><div onClick={this.handleClick} className="button_login puntero">Registrar</div></center>
                    <div id="cl"></div>
                    <div className="espaciado_final"></div>
                  </form>
                :
                  <form id="formulario3">
                    <center><h10>Recuperar mi contraseña</h10></center>
                    <div className="espaciado_final"></div>
                    *Correo:<br/>
                    <input type="text" name="rec_correo" onChange={this.handleChange} placeholder="Correo electrónico" value={this.state.rec_correo} required/>
                    <div id="cl"><br/></div>
                    <center><div onClick={this.recuperarCorreo} className="button_login puntero">Enviar</div></center>
                    <div id="cl"></div>
                    <div className="espaciado_final"></div>
                  </form>
              }
            </Modal.Body>
          </Modal>
      </div>
    )
  }
}

export default withRouter(Login);