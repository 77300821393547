import React, { Component } from 'react';
import { Link } from 'react-router-dom'

import Axios from 'axios';
import Notification from 'react-web-notification';

class Pie extends Component {
  constructor() {
    super();
    this.state = {
      ignore: true,
      title: '',
    }
  }

  //NOTIFICACION
    handlePermissionGranted(){
      console.log('Permission Granted');
      this.setState({
        ignore: false
      });
    }
    handlePermissionDenied(){
      console.log('Permission Denied');
      this.setState({
        ignore: true
      });
    }
    handleNotSupported(){
      console.log('Web Notification not Supported');
      this.setState({
        ignore: true
      });
    }

    handleNotificationOnClick(e, tag){
      console.log(e, 'Notification clicked tag:' + tag);
    }

    handleNotificationOnError(e, tag){
      console.log(e, 'Notification error tag:' + tag);
    }

    handleNotificationOnClose(e, tag){
      console.log(e, 'Notification closed tag:' + tag);
    }

    handleNotificationOnShow(e, tag){
      this.playSound();
      console.log(e, 'Notification shown tag:' + tag);
    }

    playSound(filename){
      document.getElementById('sound').play();
    }

  /*
    handleButtonClick() {
      if(this.state.ignore) {
        return;
      }

      const now = Date.now();

      const title = 'React-Web-Notification' + now;
      const body = 'Hello' + new Date();
      const tag = now;
      const icon = 'http://mobilusoss.github.io/react-web-notification/example/Notifications_button_24.png';
      // const icon = 'http://localhost:3000/Notifications_button_24.png';

      // Available options
      // See https://developer.mozilla.org/en-US/docs/Web/API/Notification/Notification
      const options = {
        tag: tag,
        body: body,
        icon: icon,
        lang: 'en',
        dir: 'ltr',
        sound: 'imgs/sound.mp3'  // no browsers supported https://developer.mozilla.org/en/docs/Web/API/notification/sound#Browser_compatibility
      }
      this.setState({
        title: title,
        options: options
      });
    }
  */
  componentDidMount () {
    if(sessionStorage._ROL==='VEN'){
      setInterval(() => {

        var params = new URLSearchParams();
        params.append('_USUARIO', sessionStorage.usuario);
        params.append('_ROL', sessionStorage._ROL);
        params.append('_Activa', sessionStorage._Activa);

        Axios.post(window.ws+'notificar.php', params)
          .then(response => {
            console.log('lol')
            if(response.data.notificaciones>0){

              if(this.state.ignore) {
                return;
              }
              const now = Date.now();

                const title = 'Invictus - MetLife';
                const body = 'Tienes un nuevo cliente asignado';
                const tag = now;
                const icon = 'img/notificacion.png';

                const options = {
                  tag: tag,
                  body: body,
                  icon: icon,
                  lang: 'en',
                  dir: 'ltr',
                  sound: 'img/sound.mp3'
                }
                this.setState({
                  title: title,
                  options: options
                });

            }
          })
          .catch(error => console.error(error))
      }, 50000);
    }
  }
  //FIN NOTIFICACION

  render () {
    return (
      <div className="pie">
        {
          sessionStorage._ROL==='VEN' ?
            <div>
              <Notification
                ignore={this.state.ignore && this.state.title !== ''}
                notSupported={this.handleNotSupported.bind(this)}
                onPermissionGranted={this.handlePermissionGranted.bind(this)}
                onPermissionDenied={this.handlePermissionDenied.bind(this)}
                onShow={this.handleNotificationOnShow.bind(this)}
                onClick={this.handleNotificationOnClick.bind(this)}
                onClose={this.handleNotificationOnClose.bind(this)}
                onError={this.handleNotificationOnError.bind(this)}
                timeout={5000}
                title={this.state.title}
                options={this.state.options}
              />
              <audio id='sound' preload='auto'>
                <source src='imgs/sound.mp3' type='audio/mpeg' />
                <source src='imgs/sound.ogg' type='audio/ogg' />
                <embed hidden='true' autostart='false' loop='false' src='imgs/sound.mp3' />
              </audio>
            </div>
          :
            null
        }
        MetLife Invictus v1.15.{window._my_version}<br/>
        Todos los derechos reservados © {window.ano}
        <center><Link to="aviso" className="a_pie">Aviso de Privacidad</Link></center>
      </div>
    )
  }
}

export default React.memo(Pie);