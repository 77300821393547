import React, { Component } from 'react';
//import { Link } from 'react-router-dom'
import Axios from 'axios';

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

class Header extends Component {
	constructor(props) {
	    super(props);
	    this.state = {
	      lista_avisos: [],
	    };
	  }

	componentWillMount () {
		window.H5_loading.show();
		this.listaAvisos();
	}

	componentDidMount () {
		window.H5_loading.hide();
	}

	listaAvisos() {
	    window.H5_loading.show();
	    var params = new URLSearchParams();
	    params.append('_USUARIO', sessionStorage.usuario);
	    params.append('_ROL', sessionStorage._ROL);
	    params.append('_Activa', sessionStorage._Activa);

	    params.append('que', 1);

	    Axios.post(`${window.ws}wsAvisos.php`, params)
	        .then(response => {
	          if(response.data.elError===2){
	            console.log(response.data.mensaje)
	          }else{
	            this.setState({lista_avisos: response.data})
	          }
	        })
	        .catch(error => {
	          console.log(error)
	        })
	        .then(function() {
	            window.H5_loading.hide();
	        })
	  }

	render () {
		return (
			<div>
				{
					this.state.lista_avisos.length > 0 ?
						<Carousel showArrows={true} showStatus={false} showIndicators={true} showThumbs={false} interval={5000} infiniteLoop={true} autoPlay>
							{
				                this.state.lista_avisos.map((item, i) => 
				                  <div className="carousel_img" key={i}>
				                    <img src={`${window.ws}avisos/AV_${item[1]}.${item[5]}`} alt={item[2]}/>
				                    {
				                      item[3] || item[4] ?
				                        <p className="leyenda">
											<center>
												<div className="leyenda_texto">
													{
														item[4] ?
															<a href={item[4]} target="_new">{item[3] ? item[3] : "Ver más"}</a>
														:
															item[3]
													}
												</div>
											</center>
				                        </p>
				                      :
				                        null
				                    }
				                  </div>
				                )
				            }
						</Carousel>
					:
						null
				}
			</div>
		)
	}
}

export default Header;