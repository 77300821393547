import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';

import Menus from './Menus'
import Pie from './Pie'

class Aviso extends Component {
  componentWillMount () {
    window.H5_loading.show();
    window.scrollTo(0, 0)
  }
  
  componentDidMount () {
    window.H5_loading.hide();
  }

  render () {
    return (
      <div className="flex_smenu">
        <Menus />
        <div id={this.props.ver_smenu}>
          <div className="max_width padding_width">
            <center><h9>AVISO DE PRIVACIDAD</h9></center>
            <div id="cl"><br/></div>
            <div className="at_j">
              <b>INVICTUS, S.A DE C.V.</b> así como sus filiales y subsidiarias en adelante, tienen entre sus objetivos, la convicción de proteger los datos personales sensibles, en su caso, proporcionados por la persona física a quien corresponden dichos datos, en adelante <b>“EL TITULAR”</b>, de lo anterior <b>INVICTUS, S.A DE C.V.</b> ha establecido las siguientes disposiciones para proteger dicha información en la medida de los posible, las cuales pueden cambiar en cualquier momento. Por lo que sugerimos consultarlas periódicamente. 
              <br/><br/>
              Los términos que no estén expresamente definidos en estas disposiciones, tendrán el significado que les atribuye a los mismos en la <b>LEY FEDERAL DE PROTECCIÓN DE DATOS PERSONALES EN POSESIÓN DE LOS PARTICULARES</b> en adelante <b>“LA LEY”.</b> 
              <ul className="lista_avi">
                <li>
                  INFORMACIÓN PERSONAL SOLICITADA. <b>INVICTUS, S.A DE C.V.</b> podrá solicitar información a <b>“EL TITULAR”</b>, con el objeto de poder proporcionarles la mayor experiencia personalizada en el uso de los servicios que se les otorga. El tipo de información que se solicita al titular de los servicios incluye, de manera enunciativa, más no limitativa, el nombre(s), apellido(s), dirección de correo electrónico, teléfono, datos fiscales, dirección de domicilio, entre otros. No toda la información solicitada al usuario al momento de registrarse es obligatoria de proporcionarse, salvo aquella que <b>INVICTUS, S.A DE C.V.</b> considere conveniente y que así se le haga saber al usuario al momento del registro, por lo que solicitará dicha información cuando considere sea oportuna o necesaria.
                </li>
                <li>
                  <b>“EL TITULAR”</b> debe garantizar que los datos personales que proporciona son correctos, completos, veraces y actuales y que se hace responsable de comunicar a su cliente cualquier modificación y que a su vez este tiene la obligación de notificar a <b>INVICTUS, S.A DE C.V.</b> dichas modificaciones.
                </li>
                <li>
                  Los términos “Datos Personales” y “Datos Sensibles” tendrán el significado que se les atribuye en el artículo 3° de <b>“LA LEY”.</b>
                </li>
                <li>
                  USO, FINALIDAD Y TRATAMIENTO DE LA INFORMACIÓN. <b>INVICTUS, S.A DE C.V.</b> utiliza la tecnología más avanzada a su alcance para la protección de la información proporcionada por <b>“EL TITULAR”</b> de los servicios. Esta tecnología encriptado, codifica y previene la intercepción de la información suministrada por el <b>“EL TITULAR”</b>, incluyendo tarjetas de crédito y direcciones de correo electrónico. <b>“EL TITULAR”</b> que facilite sus datos de carácter personal presta su consentimiento expreso para su utilización por <b>INVICTUS, S.A DE C.V.</b> con la finalidad y realización de actividades propias de su objeto social. <b>“EL TITULAR”</b> que facilite datos de carácter personal queda informado que dicha comunicación se produce en el mismo momento que proporcione los datos a <b>INVICTUS, S.A DE C.V.</b>
                  <br/><br/>
                  TRATAMIENTO. <b>“EL TITULAR”</b> consciente inequívocamente que los datos personales que nos facilite a lo largo de todas las comunicaciones y que sean incorporados a los ficheros que contengan datos de carácter personal, de los que será responsable <b>INVICTUS, S.A DE C.V.</b> así como el tratamiento informatizado o no, de los mismos con el fin de que puedan ser utilizados por <b>INVICTUS, S.A DE C.V.</b> con una finalidad informativa personalizada o no, estadística, así como actividades propias autorizando a <b>INVICTUS, S.A DE C.V.</b> la extracción, almacenamiento de datos al objeto de mejorar nuestra atención en el desarrollo de los servicios prestados e informarle de nuestros actuales y futuros servicios a través de los diferentes medios que se establecen. <b>INVICTUS, S.A DE C.V.</b> se reserva el derecho de ceder la información proporcionada por el <b>“EL TITULAR”</b> a cualquiera de sus filiales o subsidiarias, ya sean presentes, o aquella que se constituyan o adquieran en el futuro, a nivel nacional como internacional. De igual modo, por virtud de relación contractual que se establezca entre <b>“EL TITULAR”</b> y <b>“INVICTUS, S.A DE C.V.”</b>, esta última podrá proporcionar información personal obtenida a las personas físicas o morales que tengan relación con la implementación de la referida relación contractual. Del mismo modo, <b>“EL TITULAR”</b> manifiesta que está consciente en que <b>INVICTUS, S.A DE C.V.</b> podrá transmitir la información proporcionada a cualquier autoridad judicial autoritaria, administrativa o de cualquier índole que en términos de legislación aplicable le sea solicitado.
                </li>
                <li>
                  De igual forma, <b>“EL TITULAR”</b> podrá enviar un comunicado a la misma dirección de correo electrónico, cuando su deseo sea el de acceso y rectificación de cualquier dato en la información proporcionada, conforme a lo previsto en <b>“LA LEY”</b>, <b>“EL TITULAR”</b> tendrá en todo momento el derecho a cancelar sus datos personales conforme a lo previsto en <b>“LA LEY”</b>.
                </li>
                <li>
                  PROTECCIÓN DE LA INFORMACIÓN <b>INVICTUS, S.A DE C.V.</b> expresa su compromiso de proteger la seguridad de la información proporcionada por <b>“EL TITULAR”</b>. Para dichos fines, utilizamos una gran variedad de tecnologías y procedimientos de seguridad, para impedir, en la medida de lo posible, el acceso, uso o divulgación no autorizados. Cuando se transmite la información altamente confidencial (como número de tarjetas bancarias) a través de la red internet, la protegemos mediante encriptación. Será responsabilidad del usuario el que permanezca con carácter confidencial, el uso de cualquier contraseña.
                </li>
                <li>
                  CONTACTO. En caso de dudas y/o comentarios en este aviso de privacidad, <b>“EL TITULAR”</b> podrá contactar a <b>INVICTUS, S.A DE C.V.</b> mediante la dirección electrónica y al teléfono proporcionado. Para lo anterior, deberá hacernos saber fehacientemente los Datos Personales y /o Datos Sensibles que usted desea sean rectificados o revisados, así como el propósito para el cual los aportó y en general cumplir los requisitos mencionados en el art. 29 de <b>“LA LEY”</b>, obteniendo una respuesta sobre el mismo, en un plazo de 15 días hábiles.
                </li>
                <li>
                  MARCO LEGAL VIGENTE. <b>“EL TITULAR”</b> se obliga, a respetar toda la normativa legal vigente relacionada con la utilización, gestión y uso de sistemas de información <b>INVICTUS, S.A DE C.V.</b> extiende el presente aviso de privacidad, de conformidad con lo dispuesto por la Ley Federal de Protección de Datos Personales, así como por las normas aplicables a nivel nacional e internacional.
                </li>
              </ul>
          </div>
        </div>
        <Pie />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    ver_smenu: state.ver_smenu
  }
};

const Liga = withRouter(Aviso);
export default connect(mapStateToProps)(Liga);