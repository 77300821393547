import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import Inicio from './components/Inicio'
import Login from './components/Login'
import MiCuenta from './components/MiCuenta'
import Aviso from './components/Aviso'
import Avisos from './components/Avisos'
import Errores from './components/Errores'
import Usuarios from './components/Usuarios'
import Polizas from './components/Polizas'
import Expediente from './components/Expediente'
import Checador from './components/Checador'
import Actividades from './components/Actividades'
import Historial from './components/Historial'
import Buzon from './components/Buzon'

class App extends Component {
  render () {
    return (
      <Router>
	      <Switch>
	        <Route exact path="/" component={Login} />
	        {sessionStorage._ROL ? <Route path={window.path+"/inicio"} component={Inicio} /> : null}
	        
	        <Route path={window.path+"/aviso"} component={Aviso} />
	        <Route path={window.path+"/error"} component={Errores} />
	        {sessionStorage._ROL ? <Route path={window.path+"/micuenta"} component={MiCuenta} /> : null}
	        {sessionStorage._ROL==='ADMIN' ? <Route path={window.path+"/avisos"} component={Avisos} /> : null}
	        {sessionStorage._ROL==='ADMIN' ? <Route path={window.path+"/usuarios"} component={Usuarios} /> : null}
	        {sessionStorage._ROL==='ADMIN' ? <Route path={window.path+"/expediente"} component={Expediente} /> : null}
	        {sessionStorage._ROL!=='ADMIN' && sessionStorage._ROL!=='CAP' && sessionStorage._ROL!=='REC' && sessionStorage._ROL!=='SUP' && sessionStorage._ROL!=='KAR' ?  null : <Route path={window.path+"/polizas"} component={Polizas} />}
	        {sessionStorage._ROL!=='ADMIN' && sessionStorage._ROL!=='SUP' && sessionStorage._ROL!=='TEL' && sessionStorage._ROL!=='REC' && sessionStorage._ROL!=='VEN' && sessionStorage._ROL!=='KAR' ?  null : <Route path={window.path+"/actividades"} component={Actividades} />}
	        {sessionStorage._ROL==='ADMIN' ?  <Route path={window.path+"/historial"} component={Historial} /> : null}
	        {sessionStorage._ROL!=='ADMIN' && sessionStorage._ROL!=='REC' && sessionStorage._ROL!=='SUP' ?  null : <Route path={window.path+"/checador"} component={Checador} />}
	        {sessionStorage._ROL==='ADMIN' || !sessionStorage._ROL ? null : <Route path={window.path+"/buzon"} component={Buzon} />}

	        <Route component={Login} />
	      </Switch>
      </Router>
    )
  }
}

export default App;
