import React, { Component } from 'react';
import { connect } from 'react-redux';
import Axios from 'axios'
import Swal from 'sweetalert2'
import Modal from 'react-bootstrap/Modal';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { Element, Link as Ir } from 'react-scroll';
import { Link } from 'react-router-dom'

import Menus from './Menus'
import Pie from './Pie'

var total_polizas = [1];

const alerta = (tipo,titulo) =>{
  Swal.fire({
  type: tipo,
  title: titulo
  });
}

class Polizas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      w_usuarios: [],
      consultaDos: 1,

      existe: 0,
      cuantas_polizas: total_polizas,
      numero_polizas: 1,
      p_pa: '',

      inicial: 0,
      mostrar: 25,
      cantidad: 25,

      c_id: '',
      c_nombre: '',
      c_rfc: '',
      c_estado: '',
      c_municipio: '',
      c_status: '',
      c_fecha_1: '',
      c_fecha_2: '',
      c_n_ano: '',
      c_n_mes: '',
      c_n_dia: '',

      di_1: 'di_1',
      di_2: 'di_2',

      show: false,
      ver: '',

      lista_polizas: [],
      
      p_id: '',
      p_polizas: [],
      p_polizas_actuales: '',
      p_curp: '',
      p_nombres: '',
      p_apellido_p: '',
      p_apellido_m: '',
      p_correo_1: '',
      p_correo_2: '',
      p_tel_1: '',
      p_tel_2: '',
      p_tel_3: '',
      p_estado: '',
      p_municipio: '',
      p_cp: '',
      p_colonia: '',
      p_calle: '',
      p_numero: '',
      p_fecha: '',
      p_jubilado: '',
      p_ubicacion: '',
      p_ufecha_div: '',
      p_status: '',
      p_registro: '',
      p_cuidado: '',
      documento: '',

      fecha_tipo: 1,
    };
    this.botonEnviar = this.botonEnviar.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.rfcChange = this.rfcChange.bind(this);
    this.mostrarChange = this.mostrarChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.onSubir = this.onSubir.bind(this);
  }

  componentWillMount () {
    window.H5_loading.show();
    window.scrollTo(0, 0)
    this.listaUsuarios();

    if(sessionStorage._ROL==='SUP' || sessionStorage._ROL==='REC'){
      //this.listaPolizas(1, 'msj');
    }
  }
  
  componentDidMount () {
    window.H5_loading.hide();
  }

  cualConsultaDos(e) {
    this.setState({consultaDos:e})
    this.listaPolizas(e);
    this.setState({
      inicial: 0,
      mostrar: 25,
      cantidad: 25,
    })
  }

  rfcChange(e) {
    var rfc = e.target.value.toUpperCase()
    this.setState({p_id: rfc})
    if(rfc.length===13){
      if(window.validarRfc(rfc)===false){
        alerta('error','Ingrese un RFC valido');
      }else{
        window.H5_loading.show();

        var params = new URLSearchParams();
        params.append('_USUARIO', sessionStorage.usuario);
        params.append('_ROL', sessionStorage._ROL);
        params.append('_Activa', sessionStorage._Activa);

        params.append('id', rfc);

        Axios.post(`${window.ws}VerificarRFC.php`, params)
            .then(response => {
              if(response.data.elError===2){
                alerta('error',''+response.data.mensaje);
              }else{
                if(response.data.elError===3){
                  alerta('warning',''+response.data.mensaje);
                  this.setState({
                    ver: rfc,

                    p_id: response.data.p_id,
                    p_polizas_actuales: response.data.p_polizas,
                    p_curp: response.data.p_curp,
                    p_nombres: response.data.p_nombres,
                    p_apellido_p: response.data.p_apellido_p,
                    p_apellido_m: response.data.p_apellido_m,
                    p_correo_1: response.data.p_correo_1,
                    p_correo_2: response.data.p_correo_2,
                    p_tel_1: response.data.p_tel_1,
                    p_tel_2: response.data.p_tel_2,
                    p_tel_3: response.data.p_tel_3,
                    p_estado: response.data.p_estado,
                    p_municipio: response.data.p_municipio,
                    p_cp: response.data.p_cp,
                    p_colonia: response.data.p_colonia,
                    p_calle: response.data.p_calle,
                    p_numero: response.data.p_numero,
                    p_fecha: response.data.p_fecha,
                    p_jubilado: response.data.p_jubilado,
                    p_ubicacion: response.data.p_ubicacion,
                    p_ufecha_div: response.data.p_ufecha_div,
                    p_status: response.data.p_status,
                    p_registro: response.data.p_registro,
                    p_cuidado: response.data.p_cuidado,
                    documento: response.data.p_ine,
                  })
                }else{
                  this.reset();
                }
                this.setState({existe: response.data.elError})
              }
            })
            .catch(error => {
              console.log(error)
            })
            .then(function() {
                window.H5_loading.hide();
            })
      }
    }
  }

  handleChange(e) {
    const {name, value} = e.target;
    this.setState({[name]:value})
  }

  mostrarChange(e) {
    const {name, value} = e.target;
    this.setState({
      [name]:value,
      inicial: 0,
      mostrar: parseInt(value),
      cantidad: parseInt(value)
    })
  }

  verSiguiente(e){
    if(e===2){
      this.setState({
        mostrar: this.state.mostrar+this.state.cantidad,
        inicial: this.state.inicial+this.state.cantidad, 
      });
    }else{
      this.setState({
        mostrar: this.state.mostrar-this.state.cantidad,
        inicial: this.state.inicial-this.state.cantidad, 
      });
    }
  };

  tipoConsulta(id){
    if(id===1){
      this.setState({ di_1: 'di_1', di_2: 'di_2' });
    }else{
      this.setState({ di_1: 'di_2', di_2: 'di_1' });
    }
  }

  handleShow(ver, poliza){
    window.H5_loading.show();
    this.setState({show: true})
    if(ver!==''){
      let consulta_poli = this.state.lista_polizas.filter(p => p.p_id === ver)
      if(poliza){
        consulta_poli = this.state.lista_polizas.filter(p => p.p_id === ver && p.p_polizas === poliza)
      }
      consulta_poli.map(item => { //eslint-disable-line
        this.setState({
          ver: ver,
          existe: 4,

          p_id: item.p_id,
          p_curp: item.p_curp,
          p_nombres: item.p_nombres,
          p_apellido_p: item.p_apellido_p,
          p_apellido_m: item.p_apellido_m,
          p_correo_1: item.p_correo_1,
          p_correo_2: item.p_correo_2,
          p_tel_1: item.p_tel_1,
          p_tel_2: item.p_tel_2,
          p_tel_3: item.p_tel_3,
          p_estado: item.p_estado,
          p_municipio: item.p_municipio,
          p_cp: item.p_cp,
          p_colonia: item.p_colonia,
          p_calle: item.p_calle,
          p_numero: item.p_numero,

          p_jubilado: item.p_jubilado,
          p_ubicacion: item.p_ubicacion,
          p_ufecha_div: item.p_ufecha_div,
          p_registro: item.p_registro,

          p_fecha: item.p_fecha,
          p_pa: item.p_polizas,
          p_polizas_actuales: item.p_polizas,
          p_status: item.p_status,

          p_cuidado: item.p_cuidado,
          
          documento: item.p_ine,
        })
      })
    }
    window.H5_loading.hide();
  };
  handleHide(){
    this.setState({
      show: false,
      existe: 0,
      p_id: '',
    })
    total_polizas = [1];
    this.reset();
  };

  reset(){
    total_polizas = [1];
    this.setState({
      cuantas_polizas: total_polizas,
      numero_polizas: 1,
      p_pa: '',

      p_polizas: [],
      p_polizas_actuales: '',
      p_curp: '',
      p_nombres: '',
      p_apellido_p: '',
      p_apellido_m: '',
      p_correo_1: '',
      p_correo_2: '',
      p_tel_1: '',
      p_tel_2: '',
      p_tel_3: '',
      p_estado: '',
      p_municipio: '',
      p_cp: '',
      p_colonia: '',
      p_calle: '',
      p_numero: '',
      p_fecha: '',
      p_jubilado: '',
      p_ubicacion: '',
      p_ufecha_div: '',
      p_status: '',
      p_registro: '',
      p_cuidado: '',
      documento: '',

      ver: '',
    })
  };

  handleClick(e) {
    const {ver, existe, consultaDos, p_id, p_pa, p_polizas, p_polizas_actuales, numero_polizas, p_curp, p_nombres, p_apellido_p, p_apellido_m, p_correo_1, p_correo_2, p_tel_1, p_tel_2, p_tel_3, p_estado, p_municipio, p_cp, p_colonia, p_calle, p_numero, p_fecha, p_jubilado, p_ubicacion, p_ufecha_div, p_status, p_registro, p_cuidado} = this.state;
    var polizas = p_polizas.slice(0, numero_polizas)

    if(!p_id || !p_ufecha_div || (!p_polizas_actuales && consultaDos===1 && existe===4) || (polizas.length===0 && existe===1) || !p_nombres || !p_apellido_p || !p_apellido_m || !p_tel_1 || !p_cp || !p_fecha || p_jubilado==='' || p_ubicacion===''){
      alerta('error','Un campo requerido está vacio');
    }else{
      if((p_correo_1 && window.validarMail(p_correo_1)===false) || (p_correo_2 && window.validarMail(p_correo_2)===false)){
        alerta('error','Ingrese un correo electrónico valido');
      }else{
        if(window.validarRfc(p_id.toUpperCase())===false){
          alerta('error','Ingrese un RFC valido');
        }else{
          //if(!p_curp && p_curp/*!p_curp && window.validarRfc(p_curp.toUpperCase())===false*/){
            //alerta('error','Ingrese un CURP valido');
          //}else{
            window.H5_loading.show();
            let params = new URLSearchParams();
            params.append('_USUARIO', sessionStorage.usuario);
            params.append('_ROL', sessionStorage._ROL);
            params.append('_Activa', sessionStorage._Activa);
            params.append('id', ver);
            params.append('existe', existe);
            params.append('consultaDos', consultaDos);

            if(consultaDos===1 && existe===4){
              params.append('p_pa', p_pa);
            }
            
            params.append('p_id', p_id);
            params.append('p_polizas', polizas);
            params.append('p_polizas_actuales', p_polizas_actuales);
            params.append('p_curp', p_curp);
            params.append('p_nombres', p_nombres);
            params.append('p_apellido_p', p_apellido_p);
            params.append('p_apellido_m', p_apellido_m);
            params.append('p_correo_1', p_correo_1);
            params.append('p_correo_2', p_correo_2);
            params.append('p_tel_1', p_tel_1);
            params.append('p_tel_2', p_tel_2);
            params.append('p_tel_3', p_tel_3);
            params.append('p_estado', p_estado);
            params.append('p_municipio', p_municipio);
            params.append('p_cp', p_cp);
            params.append('p_colonia', p_colonia);
            params.append('p_calle', p_calle);
            params.append('p_numero', p_numero);
            params.append('p_fecha', p_fecha);
            params.append('p_jubilado', p_jubilado);
            params.append('p_ubicacion', p_ubicacion);

            let la_fecha_div = p_ufecha_div+"-01"
            params.append('p_ufecha_div', la_fecha_div);
            params.append('p_status', p_status);
            params.append('p_registro', p_registro);
            params.append('p_cuidado', p_cuidado);

            Axios.post(`${window.ws}wsPolizas.php`, params)
              .then(response => {
                if(response.data.elError===1){
                  alerta('success',''+response.data.mensaje);
                  if(ver!=='' || this.state.lista_polizas.length>0){
                    this.listaPolizas();
                  }
                  this.handleHide();
                }else{
                  alerta('error',''+response.data.mensaje);
                }
              })
              .catch(function (error) {
                alerta('error',''+error);
                console.log(error)
              })
              .then(function(response) {
                window.H5_loading.hide();
              });
          //}
        }
      }
    }
  }

  onSubir(e) {
    if (!e.target.files[0]) {
      alerta('error','Un campo requerido está vacio');
    }else{
      if(e.target.files[0].type === "image/png" || 
        e.target.files[0].type === "image/jpeg" || 
        e.target.files[0].type === "image/jpg" || 
        e.target.files[0].type === "application/pdf"
      ){
        window.H5_loading.show();

        const config = {
          headers: {
              'content-type': 'multipart/form-data'
          }
        }

        const data = new FormData();
        data.append('file', e.target.files[0]);
        data.append('_USUARIO', sessionStorage.usuario);
        data.append('_ROL', sessionStorage._ROL);
        data.append('_Activa', sessionStorage._Activa);
        data.append('id', this.state.ver);

        Axios.post(window.ws+'wsDocumento.php', data, config)
        .then(response => {
          if(response.data.elError===1){
            alerta('success',response.data.mensaje)
            this.setState({
              documento: response.data.documento
            })
          }else{
            alerta('error',response.data.mensaje)
          }
        })
        .catch(error => {
          //alerta('error',''+error);
          console.log(error)
        })
        .then(function() {
          window.H5_loading.hide();
        })
      }else{
        alerta('error','El archivo debe estar en formato PNG, JPG o PDF');
      }
    }
  }

  delSo (e) {
      Swal.fire({
        title: this.state.consultaDos===1 ? '¿Deseas cancelar esta póliza?' : '¿Deseas cancelar el cliente?',
        //text: "You won't be able to revert this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, cancelar!'
      }).then((result) => {
        if (result.value) {
              window.H5_loading.show();

              let params = new URLSearchParams();
              params.append('_USUARIO', sessionStorage.usuario);
              params.append('_ROL', sessionStorage._ROL);
              params.append('_Activa', sessionStorage._Activa);
              params.append('id', e);
              params.append('consultaInner', this.state.consultaDos);

              Axios.post(`${window.ws}wsPolizas.php?que=2`, params)
                  .then(response => {
                    if(response.data.elError===1){
                      alerta('success',''+response.data.mensaje);
                      this.listaPolizas();
                    }else{
                      alerta('error',''+response.data.mensaje);
                    }
                  })
                  .catch(error => {
                    console.log(error)
                    //alerta('error',''+error);
                  })
                  .then(function() {
                      window.H5_loading.hide();
                  })
        }
      })
  }

  botonEnviar () {
    this.listaPolizas();
    this.setState({
      inicial: 0,
      mostrar: 25,
      cantidad: 25,
    })
  }

  listaPolizas(inner, msj) {
    window.H5_loading.show();
    let params = new URLSearchParams();
    params.append('_USUARIO', sessionStorage.usuario);
    params.append('_ROL', sessionStorage._ROL);
    params.append('_Activa', sessionStorage._Activa);
    params.append('c_id', this.state.c_id);
    params.append('c_nombre', this.state.c_nombre);
    params.append('c_rfc', this.state.c_rfc);
    params.append('c_estado', this.state.c_estado);
    params.append('c_municipio', this.state.c_municipio);
    params.append('c_status', this.state.c_status);
    params.append('c_fecha_1', this.state.c_fecha_1);
    params.append('c_fecha_2', this.state.c_fecha_2);
    params.append('c_n_ano', this.state.c_n_ano);
    params.append('c_n_mes', this.state.c_n_mes);
    params.append('c_n_dia', this.state.c_n_dia);
    params.append('fecha_tipo', this.state.fecha_tipo);

    if(inner>0){
      params.append('consultaInner', inner);
    }else{
      params.append('consultaInner', this.state.consultaDos);
    }

    Axios.post(`${window.ws}wsPolizas.php?que=1`, params)
        .then(response => {
          if(response.data.elError===2){
            alerta('error',''+response.data.mensaje);
          }else{
            this.setState({lista_polizas: response.data.lista_array})
            if(response.data.elError===1 && msj!=='msj'){
              alerta('warning',''+response.data.mensaje);
            }
          }
        })
        .catch(error => {
          console.log(error)
          alerta('error',''+error);
        })
        .then(function() {
            window.H5_loading.hide();
        })
  }

  onPolizaChange(index, e) {
    var polizas = this.state.p_polizas.slice();
    polizas[index] = e.target.value;
    this.setState({
      p_polizas: polizas
    });
  }

  anadir(e) {
    let numeros
    if(e==='pon'){
      numeros=this.state.numero_polizas+1
      total_polizas.push(numeros);
    }else{
      var index = total_polizas.indexOf(this.state.numero_polizas);
      if (index > -1) {
        total_polizas.splice(index, 1);
      }
      numeros=this.state.numero_polizas-1
    }
    
    this.setState({
      cuantas_polizas: total_polizas,
      numero_polizas: numeros,
    })
  }

  listaUsuarios() {
    window.H5_loading.show();
    Axios.get(window.ws+'listas.php')
      .then(response => {
        //window.alerta('success','LOLERO'+response.data);
        this.setState({w_usuarios: response.data})
      })
      .catch(error => {
        //window.alerta('error','LOLERO'+error);
        console.log(error)
      })
      .then(function() {
        window.H5_loading.hide();
      })
  }


  render () {
    const _my_jubilados = [{n:0, d:'Activo ISSSTE'}, {n:1, d:'Jubilado ISSSTE'}, {n:2, d:'Activo IMSS'}, {n:3, d:'Jubilado IMSS'}, {n:4, d:'Otro'}];
    return (
      <div className="flex_smenu">
        <Menus />
        <div id={this.props.ver_smenu}>
          <div className="max_width padding_width">
            <h10>Pólizas - Clientes</h10>
            <div className="linea"></div>
            <div id="cl"><br/></div>
            <div className="flex_nueva">
              <div className="nueva" onClick={this.handleShow.bind(this, '')}>+</div>
              <div className="l_nueva" onClick={this.handleShow.bind(this, '')}>Nueva</div>
            </div>
            <div id="cl"></div>
            {
                sessionStorage._ROL==='ADMIN' ?
                  <div className="pa_flex_form">
                    <div className="de_flex_form">
                      <div className="consulta" id={this.state.di_1} onClick={this.tipoConsulta.bind(this, 1)}>Consulta Rápida</div>
                    </div>
                    <div className="de_flex_form">
                      <div className="consulta" id={this.state.di_2} onClick={this.tipoConsulta.bind(this, 2)}>Consulta Avanzada</div>
                    </div>
                  </div>
              :
                null
            }
            <div id="cl"><br/></div>
            <form id="formulario2">
              <div className="pa_flex_form">
                <div className="de_flex_form">
                  Póliza:<br/>
                  <input type="text" placeholder="Número de póliza" name="c_id" onChange={this.handleChange}/>
                  <div id="cl"></div>
                  Registro Federal de Contribuyentes (RFC):<br/>
                  <input type="text" name="c_rfc" onChange={this.handleChange}/>
                  <div id="cl"></div>
                  {
                    this.state.di_1 === 'di_2' ?
                      <div>
                        Nombre/Apellidos:<br/>
                        <input type="text" name="c_nombre" onChange={this.handleChange}/>
                        <div id="cl"></div>
                        Fecha de nacimiento:<br/>
                        <table>
                          <tr>
                            <th>Año</th>
                            <th>Mes</th>
                            <th>Día</th>
                          </tr>
                          <tr>
                            <td><input type="number" name="c_n_ano" maxlength="4" minlength="4" max={window.ano-15} min={window.ano-120} onChange={this.handleChange}/></td>
                            <td><input placeholder="1 a 12" type="number" name="c_n_mes" maxlength="2" minlength="2" max="12" onChange={this.handleChange}/></td>
                            <td><input type="number" name="c_n_dia" maxlength="2" minlength="2" max="31" onChange={this.handleChange}/></td>
                          </tr>
                        </table>
                        <div id="cl"></div>
                      </div>
                    :
                      null
                  }
                  <div id="cl"><br/></div>
                  {
                    sessionStorage._ROL==='ADMIN' && this.state.lista_polizas.length>0 ?
                      <div className="flex_consulta">
                        <Ir to="recorrer" activeClass="active" spy={true} smooth={true} duration={1000} className="consultaDos" id={this.state.consultaDos===1 ? 'dic_1' : 'dic_2'} onClick={this.cualConsultaDos.bind(this, 1)}>Polizas</Ir>
                        <Ir to="recorrer" activeClass="active" spy={true} smooth={true} duration={1000} className="consultaDos" id={this.state.consultaDos===2 ? 'dic_1' : 'dic_2'} onClick={this.cualConsultaDos.bind(this, 2)}>Clientes</Ir>
                      </div>
                    :
                      null
                  }
                </div>
                <div className="de_flex_form">
                  {
                    this.state.di_1 === 'di_2' ?
                      <div>
                        Estado:
                        <br/>
                        <select name="c_estado" className="select" onChange={this.handleChange}>
                          <option value="">- Selecciona -</option>
                          {
                            window._my_estado.map((item, i) => 
                              <option value={item.estado} key={i}>{item.estado}</option>
                            )
                          }
                        </select>
                        <div id="cl"></div>
                        Municipio/Delegación:
                        <br/>
                        <select name="c_municipio" className="select" onChange={this.handleChange}>
                          <option value="">- Selecciona -</option>
                          {
                            window._my_estado.filter(p => p.estado === this.state.c_estado).map(esta => 
                                esta.municipios.map((item, i) =>
                                  <option value={item} key={i}>{item}</option>
                                )
                            )
                          }
                        </select>
                        <div id="cl"></div>
                        Estatus:<br/>
                        <select name="c_status" className="select" onChange={this.handleChange}>
                          <option value=""></option>
                          <option value="1">Vigente</option>
                          <option value="0">Cancelada</option>
                        </select>
                        <div id="cl"></div>
                        Periodo o fecha:<br/>
                        <input type="date" name="c_fecha_1" placeholder="Fecha inicial (Día/Mes/Año)" onChange={this.handleChange}/>
                        <div id="cl"></div>
                        <input type="date" name="c_fecha_2" placeholder="Fecha final (Día/Mes/Año)" onChange={this.handleChange}/>
                        <div id="cl"></div>
                        <input type="radio" value="1" name="fecha_tipo" checked={this.state.fecha_tipo===1 ? true : null} onChange={this.handleChange}/>&nbsp;Polizas&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <input type="radio" value="2" name="fecha_tipo" checked={this.state.fecha_tipo===2 ? true : null} onChange={this.handleChange}/>&nbsp;Dividendos
                        <div id="cl"></div>
                      </div>
                    :
                      null
                  }
                </div>
              </div>
              <div id="cl"><br/></div>
              <Ir to="recorrer" activeClass="active" spy={true} smooth={true} duration={1000}><div onClick={this.botonEnviar} className="button puntero">Consultar</div></Ir>
            </form>
            <div id="cl"></div>
            <Element name="recorrer"></Element>
            {
              this.state.lista_polizas.length > 0 ?
                <div>
                  <div id="cl"><br/></div>
                  <div className="linea"></div>
                  <div id="cl"><br/></div>
                  {
                    sessionStorage._ROL==='ADMIN' ?
                      <div>
                        <ReactHTMLTableToExcel
                          className="derecha sin_submit"
                          table="table-to-xls"
                          filename="Polizas"
                          buttonText={<img src="img/ico_excel.svg" alt="Excel" className="derecha sin_submit" title="Descargar Excel"/>}
                          />
                        <div id="cl"><br/></div>
                      </div>
                    :
                      null
                  }
                  {/*{this.state.inicial} de {this.state.mostrar} suma {this.state.cantidad}*/}
                  <div className="pa_flex_siguiente">
                    Resultados encontrados: {this.state.lista_polizas.length}
                    {
                      this.state.lista_polizas.length>25 ? 
                        <select name="mostrar" className="select" onChange={this.mostrarChange} style={{width:90,float:'right'}}>
                          <option value={25} selected={this.state.cantidad === 25 ? true : false}>25</option>
                          {this.state.lista_polizas.length>=50 ? <option value={50} selected={this.state.cantidad === 50 ? true : false}>50</option> : null}
                          {this.state.lista_polizas.length>=100 ? <option value={100} selected={this.state.cantidad === 100 ? true : false}>100</option> : null}
                          {this.state.lista_polizas.length>=500 ? <option value={500} selected={this.state.cantidad === 500 ? true : false}>500</option> : null}
                          {this.state.lista_polizas.length>=1000 ? <option value={1000} selected={this.state.cantidad === 1000 ? true : false}>1000</option> : null}
                          <option value={99999999999999999999} selected={this.state.cantidad === 99999999999999999999 ? true : false}>Todos</option>
                        </select>
                      :
                        null
                    }
                  </div>
                  <div className="tabla_responsiva">
                    <table className="carrillo" cellspacing="3">
                      <tr>
                        <th>#</th>
                        <th>Póliza(s)</th>
                        <th>RFC</th>
                        <th>Nombre</th>
                        {
                          sessionStorage._ROL==='ADMIN' ?
                            <>
                              <th>Edad</th>
                              <th>Teléfono</th>
                              <th>Correo</th>
                              <th>Estatus</th>
                              <th>Fecha</th>
                            </>
                          :
                            null
                        }
                        <th></th>
                      </tr>
                      {
                        this.state.lista_polizas.slice(this.state.inicial, this.state.mostrar).map((item, i) =>
                          <tr key={i}>
                            <td>{item.contador}</td>
                            <td style={item.p_status === 0 ? {backgroundColor:'#ff859a'} : null} title={item.p_status===0 ? 'Inactivo' : 'Activo'}>{item.p_polizas}</td>
                            <td>{item.p_id}</td>
                            <td>{item.p_nombres}&nbsp;{item.p_apellido_p}&nbsp;{item.p_apellido_m}</td>
                            {
                              sessionStorage._ROL==='ADMIN' ?
                                <>
                                  <td>{item.p_id ? <div>{window._my_nacimiento(item.p_id)}<br/>{window._my_edad(item.p_id)} años</div> : ''}</td>
                                  <td>
                                    {
                                      item.p_tel_1.substr(-1)==="W" || item.p_tel_1.substr(-1)==="w" ?
                                        <a href={`https://wa.me/521${item.p_tel_1.slice(0, -1)}?text=Hola%20${item.p_nombres}`} target="new" className="green">{item.p_tel_1.slice(0, -1)}</a>
                                      :
                                        <a href={`tel:${item.p_tel_1}`} target="new" className="blue">{item.p_tel_1}</a>
                                    }
                                  </td>
                                  <td>{item.p_correo_1}</td>
                                  <td style={item.p_status === 0 ? {backgroundColor:'#ff859a'}: null}>
                                    {
                                      item.p_status > 0 ?
                                        this.state.consultaDos===1 ? 'Vigente' : 'Activo'
                                      : 
                                        this.state.consultaDos===1 ? 'Cancelada' : 'Cancelado'
                                    }
                                  </td>
                                  <td>{item.p_fecha}</td>
                                </>
                              :
                                null
                            }
                            <td>
                              <img src="img/editar.png" alt="Editar" title="Editar" height="20px" onClick={this.handleShow.bind(this, item.p_id, item.p_polizas)} className="grises puntero"/>
                              {item.p_status>0 && sessionStorage._ROL==='ADMIN' ? <img src="img/eliminar.png" alt="Cancelar" title="Cancelar" height="20px" onClick={this.delSo.bind(this, this.state.consultaDos===1 ? item.p_polizas : item.p_id)} className="grises puntero"/> : null}
                            </td>
                          </tr>
                        )
                      }
                      {/*
                        this.state.lista_polizas.map((item, i) =>
                          <tr key={i}>
                            <td>{item[0]}</td>
                            <td>{item[1]}</td>
                            <td>{item[2]} {item[3]} {item[4]}</td>
                            <td>{item[8] ? <div>{window._my_nacimiento(item[8])}<br/>{window._my_edad(item[8])} años</div> : ''}</td>
                            <td>{item[5]}</td>
                            <td>{item[6]}</td>
                            <td style={item[7] === 1 ? {backgroundColor:'#ff859a'}: null}>
                              {
                                item[7] === 0 ?
                                  'Vigente'
                                : 
                                  'Cancelada'
                              }
                            </td>
                            <td>
                              <img src="img/editar.png" alt="Editar" title="Editar" height="20px" onClick={this.handleShow.bind(this, item[1])} className="grises puntero"/>
                              {item[7] === 1 ? null : <img src="img/eliminar.png" alt="Cancelar" title="Cancelar" height="20px" onClick={this.delSo.bind(this, item[1])} className="grises puntero"/>}
                            </td>
                          </tr>
                        )
                      */}
                    </table>
                    <div id="cl"><br/></div>
                    
                  </div>
                  <div className="pa_flex_siguiente">
                    <div>{this.state.inicial === 0 ? null : <button onClick={this.verSiguiente.bind(this,1)} className="siguiente">Anterior</button>}</div>
                    <div>{this.state.mostrar >= this.state.lista_polizas.length ? null : <button onClick={this.verSiguiente.bind(this,2)} className="siguiente">Siguiente</button>}</div>
                  </div>
                  {
                    sessionStorage._ROL==='ADMIN' ?
                      <table id="table-to-xls" style={{display:"none"}}>
                        <tr>
                          <th>#</th>
                          <th>Póliza(s)</th>
                          <th>Nombre</th>
                          <th>Apellido paterno</th>
                          <th>Apellido materno</th>
                          <th>RFC</th>
                          {/*<th>CURP</th>*/}
                          <th>Edad</th>
                          <th>Fecha de nacimiento</th>
                          <th colspan="2">Correos electrónicos</th>
                          <th colspan="6">Teléfonos</th>
                          <th>Estado</th>
                          <th>Municipio/Delegación</th>
                          <th>Código postal</th>
                          <th>Colonia/Fraccionamiento</th>
                          <th>Calle</th>
                          <th>Número</th>
                          <th>Estatus</th>
                          <th>Fecha (registro/asistió)</th>
                          <th>Jubilado</th>
                          <th>Ubicación</th>
                          <th>Fecha de retiro de dividendos</th>
                          <th>Cuidado</th>
                          <th>IP de registro</th>
                          <th>Usuario registró</th>
                        </tr>
                        {
                          this.state.lista_polizas.map((item, i) =>
                            <tr key={i}>
                              <td>{item.contador}</td>
                              <td>{item.p_polizas}</td>
                              <td>{item.p_nombres}</td>
                              <td>{item.p_apellido_p}</td>
                              <td>{item.p_apellido_m}</td>
                              <td>{item.p_id}</td>
                              {/*<td>{item.p_curp}</td>*/}

                              <td>{item.p_id ? window._my_edad(item.p_id) : null}</td>
                              <td>{item.p_id ? window._my_nacimiento(item.p_id) : null}</td>

                              <td>{item.p_correo_1}</td>
                              <td>{item.p_correo_2}</td>

                              {
                                item.p_tel_1.substr(-1)==="W" || item.p_tel_1.substr(-1)==="w" ?
                                  <>
                                    <td>{item.p_tel_1.slice(0, -1)}</td>
                                    <td>WhatsApp</td>
                                  </>
                                :
                                  <>
                                    <td>{item.p_tel_1}</td>
                                    <td>Normal</td>
                                  </>
                              }
                              {
                                item.p_tel_2.substr(-1)==="W" || item.p_tel_2.substr(-1)==="w" ?
                                  <>
                                    <td>{item.p_tel_2.slice(0, -1)}</td>
                                    <td>WhatsApp</td>
                                  </>
                                :
                                  <>
                                    <td>{item.p_tel_2}</td>
                                    <td>{item.p_tel_2 ? "Normal" : ""}</td>
                                  </>
                              }
                              {
                                item.p_tel_3.substr(-1)==="W" || item.p_tel_3.substr(-1)==="w" ?
                                  <>
                                    <td>{item.p_tel_3.slice(0, -1)}</td>
                                    <td>WhatsApp</td>
                                  </>
                                :
                                  <>
                                    <td>{item.p_tel_3}</td>
                                    <td>{item.p_tel_3 ? "Normal" : ""}</td>
                                  </>
                              }

                              <td>{item.p_estado}</td>
                              <td>{item.p_municipio}</td>
                              <td>{item.p_cp}</td>
                              <td>{item.p_colonia}</td>
                              <td>{item.p_calle}</td>
                              <td>{item.p_numero}</td>

                              <td>
                                {
                                  item.p_status > 0 ?
                                    this.state.consultaDos===1 ? 'Vigente' : 'Activo'
                                  : 
                                    this.state.consultaDos===1 ? 'Cancelada' : 'Cancelado'
                                }
                              </td>
                              <td>{item.p_fecha}</td>
                              
                              <td>{_my_jubilados.filter(p => p.n===item.p_jubilado).map(jubi => jubi.d)}</td>
                              <td>{window._my_ubicacion.filter((p, n) => n===item.p_ubicacion).map(ubi => ubi)}</td>
                              <td>{item.p_ufecha_div}</td>
                              <td>{item.p_cuidado}</td>
                              <td>{item.p_ip}</td>
                              <td>{this.state.w_usuarios.filter(p => p[0]===item.p_registro).map(nombre => nombre[1])}</td>
                            </tr>
                          )
                        }
                      </table>
                    :
                      null
                  }
                </div> 
              :
                null
            }
          </div>
          <Pie />
        </div>
        <Modal
          show={this.state.show}
          onHide={this.handleHide.bind(this)}
          dialogClassName="modal-90w"
          className="modal_fer"
          aria-labelledby="example-custom-modal-styling-title">

          <Modal.Body className="modal_cuadro_dos" data-aos="fade-down">
            <div className="jh-close" onClick={this.handleHide.bind(this)}>X</div>
              <form id="formulario">
                <center><h10>{this.state.existe===0 || this.state.existe===1 ? 'Registrar póliza' : 'Editar póliza'}</h10></center>
                <div id="cl"><br/></div>
                <div className="pa_flex_reg">
                  <div className="de_flex_reg">
                    {
                      this.state.existe===4 && sessionStorage._ROL!=='ADMIN' ?
                        <div>
                          *Registro Federal de Contribuyentes (RFC): <h11>{this.state.p_id}</h11>
                        </div>
                      :
                        <div>
                          *Registro Federal de Contribuyentes (RFC):
                          <br/>
                          <input type="text" name="p_id" value={this.state.p_id} maxlength="13" minlength="13" onChange={this.state.existe===4 ? this.handleChange : this.rfcChange} required/>
                          <div id="cl"></div>
                        </div>
                    }
                    {
                      this.state.existe!==0 && this.state.existe!==1 && sessionStorage._ROL!=='CAP' ?
                        <div className="poli_regi_act">
                          <Link to={{pathname:`actividades`, state: this.state.p_id}}>Registrar actividad</Link>
                        </div>
                      :
                        null
                    }
                    {
                      this.state.existe>0 ?
                        <div>
                          {
                            this.state.existe===3 || (this.state.ver!=='' && this.state.consultaDos===2) ?
                              <div>
                                <br/>
                                Pólizas actuales: <h11>{this.state.p_polizas_actuales}</h11>
                                <div id="cl"><br/></div>
                              </div>
                            :
                              null
                          }
                          {
                            this.state.existe!==3 && this.state.consultaDos===1 && this.state.ver!=='' ? 
                              <div>
                                *Póliza:
                                {
                                  sessionStorage._ROL==='ADMIN' ?
                                    <input type="text" name="p_polizas_actuales" placeholder="Número de póliza" maxlength="6" minlength="6" value={this.state.p_polizas_actuales} onChange={this.handleChange} required/>
                                  :
                                    <h11>&nbsp;{this.state.p_polizas_actuales}</h11>
                                }
                                <div id="cl"></div>
                              </div>
                            :
                              <div>
                                {
                                  //this.state.p_polizas.map((item, i) => <div key={i}>{item}</div>)
                                }
                                *Nueva póliza:
                                {
                                  this.state.cuantas_polizas.map((item, i) => 
                                    <input type="text" value={this.state.p_polizas[i]} placeholder="Número de poliza" maxlength="6" minlength="6" onChange={this.onPolizaChange.bind(this, i)} key={i}/>
                                  )
                                }
                                <div className="flex_anadir">
                                  {this.state.numero_polizas>1 ? <div className="circulo_acciones" title="Quitar" onClick={this.anadir.bind(this,'quita')}>-</div> : null}
                                  <div className="circulo_acciones" title="Añadir" onClick={this.anadir.bind(this,'pon')}>+</div>
                                </div>
                              </div>
                          }



                          *Fecha que asistió:<br/>
                          <input type="date" name="p_fecha" placeholder="Fecha (Día/Mes/Año)" value={this.state.consultaDos===2 ? this.state.p_fecha : this.state.p_fecha.split(" ")[0]} onChange={this.handleChange} required/>
                          <div id="cl"></div>
                          *Jubilado:
                          <br/>
                          <select name="p_jubilado" className="select" onChange={this.handleChange} required>
                            <option value="" selected={this.state.p_jubilado === "" ? true : false}></option>
                            {
                              _my_jubilados.map((item, i) =>
                                <option value={item.n} selected={this.state.p_jubilado === item.n ? true : false}>{item.d}</option>
                              )
                            }
                          </select>
                          <div id="cl"></div>
                          *Ultima fecha de dividendos:<br/>
                          <input type="month" name="p_ufecha_div" value={this.state.p_ufecha_div ? this.state.p_ufecha_div.slice(0, 7) : ""} onChange={this.handleChange}/>
                          <div id="cl"></div>
                          *Ubicación:
                          <br/>
                          <select name="p_ubicacion" className="select" onChange={this.handleChange} required>
                            <option value="" selected={this.state.p_ubicacion === "" ? true : false}></option>
                            {
                              window._my_ubicacion.map((item, i) =>
                                <option value={i} key={i} selected={this.state.p_ubicacion === i ? true : false}>{item}</option>
                              )
                            }
                          </select>
                          <div id="cl"></div>
                          Cuidado:
                          <br/>
                          <select name="p_cuidado" className="select" onChange={this.handleChange}>
                            <option value="" selected={this.state.p_cuidado === "" ? true : false}></option>
                            <option value="Sí" selected={this.state.p_cuidado === "Sí" ? true : false}>Sí</option>
                          </select>
                          <div id="cl"></div>

                          *Nombres:
                          <br/>
                          <input type="text" name="p_nombres" placeholder="Nombre o nombres" value={this.state.p_nombres} onChange={this.handleChange} required/>
                          <div id="cl"></div>
                          *Apellido paterno:
                          <br/>
                          <input type="text" name="p_apellido_p" value={this.state.p_apellido_p} onChange={this.handleChange} required/>
                          <div id="cl"></div>
                          *Apellido materno:
                          <br/>
                          <input type="text" name="p_apellido_m" value={this.state.p_apellido_m} onChange={this.handleChange} required/>
                          <div id="cl"></div>
                          {/*
                          Clave Única de Registro de Población (CURP):
                          <br/>
                          <input type="text" name="p_curp" maxlength="18" value={this.state.p_curp} onChange={this.handleChange}/>
                          <div id="cl"></div>
                          */}
                          {
                            sessionStorage._ROL==='ADMIN' && this.state.existe===4 ?
                              <div>
                                Estatus:<br/>
                                <select name="p_status" className="select" onChange={this.handleChange}>
                                  <option value="" selected={this.state.p_status === "" ? true : false}></option>
                                  <option value="1" selected={this.state.p_status === 1 ? true : false}>{this.state.consultaDos===2 ? 'Activo' : 'Vigente'}</option>
                                  <option value="0" selected={this.state.p_status === 0 ? true : false}>{this.state.consultaDos===2 ? 'Cancelado' : 'Cancelada'}</option>
                                </select>
                                Fecha registro: <h11>{this.state.p_fecha}</h11>
                                <div id="cl"></div>
                              </div>
                            :
                              null
                          }
                        </div>  
                      :
                        null
                    }          
                  </div>
                  <div className="de_flex_reg">
                    {
                      this.state.existe>0 ?
                        <div>
                          Teléfonos:
                          <br/>
                          <div className="flex_telefonos">
                            <input type="text" name="p_tel_1" placeholder="*Telefóno 1" value={this.state.p_tel_1} onChange={this.handleChange} required/>
                            {
                              this.state.p_tel_1 ?
                                <div>
                                  {
                                    this.state.p_tel_1.substr(-1)==="W" || this.state.p_tel_1.substr(-1)==="w" ?
                                      <a href={`https://wa.me/521${this.state.p_tel_1.slice(0, -1)}?text=Hola%20${this.state.p_nombres}`} target="new"><img src="img/r_whats-01.svg" title="WhatsApp" alt="WhatsApp"/></a>
                                    :
                                      <a href={`tel:${this.state.p_tel_1}`} target="new"><img src="img/con_1.svg" title="Teléfono" alt="Teléfono"/></a>
                                  }
                                </div>
                              :
                                null
                            }
                          </div>
                          <div className="flex_telefonos">
                            <input type="text" name="p_tel_2" placeholder="Telefóno 2" value={this.state.p_tel_2} onChange={this.handleChange}/>
                            {
                              this.state.p_tel_2 ?
                                <div>
                                  {
                                    this.state.p_tel_2.substr(-1)==="W" || this.state.p_tel_2.substr(-1)==="w" ?
                                      <a href={`https://wa.me/521${this.state.p_tel_2.slice(0, -1)}?text=Hola%20${this.state.p_nombres}`} target="new"><img src="img/r_whats-01.svg" title="WhatsApp" alt="WhatsApp"/></a>
                                    :
                                      <a href={`tel:${this.state.p_tel_2}`} target="new"><img src="img/con_1.svg" title="Teléfono" alt="Teléfono"/></a>
                                  }
                                </div>
                              :
                                null
                            }
                          </div>
                          <div className="flex_telefonos">
                            <input type="text" name="p_tel_3" placeholder="Telefóno 3" value={this.state.p_tel_3} onChange={this.handleChange}/>
                            {
                              this.state.p_tel_3 ?
                                <div>
                                  {
                                    this.state.p_tel_3.substr(-1)==="W" || this.state.p_tel_3.substr(-1)==="w" ?
                                      <a href={`https://wa.me/521${this.state.p_tel_3.slice(0, -1)}?text=Hola%20${this.state.p_nombres}`} target="new"><img src="img/r_whats-01.svg" title="WhatsApp" alt="WhatsApp"/></a>
                                    :
                                      <a href={`tel:${this.state.p_tel_3}`} target="new"><img src="img/con_1.svg" title="Teléfono" alt="Teléfono"/></a>
                                  }
                                </div>
                              :
                                null
                            }
                          </div>
                          <div id="cl"></div>
                          <h12><b>Nota: Añadir la letra "W" al final del número si el teléfono tiene WhatsApp.</b></h12>
                          <div id="cl"><br/></div>
                          Correos electrónicos:
                          <br/>
                          <input type="text" name="p_correo_1" placeholder="e-mail 1" value={this.state.p_correo_1} onChange={this.handleChange}/>
                          <div id="cl"></div>
                          <input type="text" name="p_correo_2" placeholder="e-mail 2" value={this.state.p_correo_2} onChange={this.handleChange}/>
                          <div id="cl"></div>
                          Estado:
                          <br/>
                          <select name="p_estado" className="select" onChange={this.handleChange}>
                            <option value="" selected={this.state.p_estado === "" ? true : false}>- Selecciona -</option>
                            {
                              window._my_estado.map((item, i) => 
                                <option value={item.estado} key={i} selected={this.state.p_estado === item.estado ? true : false}>{item.estado}</option>
                              )
                            }
                          </select>
                          <div id="cl"></div>
                          Municipio/Delegación:
                          <br/>
                          <select name="p_municipio" className="select" onChange={this.handleChange}>
                            <option value="" selected={this.state.p_municipio === "" ? true : false}>- Selecciona -</option>
                            {
                              window._my_estado.filter(p => p.estado === this.state.p_estado).map(esta => 
                                  esta.municipios.map((item, i) =>
                                    <option value={item} key={i} selected={this.state.p_municipio === item ? true : false}>{item}</option>
                                  )
                              )
                            }
                          </select>
                          <div id="cl"></div>
                          *Código postal:
                          <br/>
                          <input type="text" name="p_cp" value={this.state.p_cp} onChange={this.handleChange} required/>
                          <div id="cl"></div>
                          Colonia/Fraccionamiento:
                          <br/>
                          <input type="text" name="p_colonia" value={this.state.p_colonia} onChange={this.handleChange}/>
                          <div id="cl"></div>
                          Calle:
                          <br/>
                          <input type="text" name="p_calle" value={this.state.p_calle} onChange={this.handleChange}/>
                          <div id="cl"></div>
                          Número:
                          <br/>
                          <input type="text" name="p_numero" value={this.state.p_numero} onChange={this.handleChange}/>
                          <div id="cl"></div>
                          {
                            this.state.ver!=='' && (sessionStorage._ROL==='ADMIN' || sessionStorage._ROL==='VEN') ? 
                              <div>
                                <div id="cl"><br/></div>
                                Identificación:
                                <br/>
                                {
                                  this.state.documento ?
                                    <a href={`${window.ws}documentos/DOC_${this.state.p_id}.${this.state.documento}`} target="_new">
                                      <img src="img/documento.svg" title="Ver archivo" alt="Ver archivo" className="icono_archivo"/>
                                    </a>
                                  :
                                    null
                                }
                                <input onChange={this.onSubir} id="file-upload" type="file" accept=".png, .jpg, .pdf"/>
                              </div>
                            :
                              null
                          }
                        </div>
                      :
                        null
                    }
                  </div>
                </div>
                <h12>Campos obligatorios (*)</h12>
                <div id="cl"><br/></div>
                {this.state.existe===0 ? null : <center><div onClick={this.handleClick} className="button puntero">{this.state.existe===1 ? 'Registrar' : 'Editar'}</div></center>}
                <div id="cl"></div>
                <div className="espaciado_final"></div>
              </form>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    ver_smenu: state.ver_smenu
  }
};

const Liga = Polizas;
export default connect(mapStateToProps)(Liga);