import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import Swal from 'sweetalert2'
import Modal from 'react-bootstrap/Modal';
import Axios from 'axios';

import Menus from './Menus'
import Pie from './Pie'

const alerta = (tipo,titulo) =>{
  Swal.fire({
  type: tipo,
  title: titulo
  });
}

class Avisos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      ver: '',
      av_titulo: '',
      av_imagen: '',
      av_descripcion: '',
      av_link: '',
      documento: '',
      lista_avisos: [],

      inicial: 0,
      mostrar: 25,
      cantidad: 25,
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onSubir = this.onSubir.bind(this);
    this.mostrarChange = this.mostrarChange.bind(this);
  }

  componentWillMount () {
    window.H5_loading.show();
    this.listaAvisos();
    window.scrollTo(0, 0)
  }
  
  componentDidMount () {
    window.H5_loading.hide();
  }

  handleChange(e) {
    const {name, value} = e.target;
    this.setState({[name]:value})
  }

  mostrarChange(e) {
    const {name, value} = e.target;
    this.setState({
      [name]:value,
      inicial: 0,
      mostrar: parseInt(value),
      cantidad: parseInt(value)
    })
  }

  verSiguiente(e){
    if(e===2){
      this.setState({
        mostrar: this.state.mostrar+this.state.cantidad,
        inicial: this.state.inicial+this.state.cantidad, 
      });
    }else{
      this.setState({
        mostrar: this.state.mostrar-this.state.cantidad,
        inicial: this.state.inicial-this.state.cantidad, 
      });
    }
  };

  handleClick(e) {
    const {ver, av_titulo, av_descripcion, av_link, documento} = this.state;
    if(!av_titulo){
      alerta('error','Un campo requerido está vacio');
    }else{
      window.H5_loading.show();
      var params = new URLSearchParams();
      params.append('_USUARIO', sessionStorage.usuario);
      params.append('_ROL', sessionStorage._ROL);
      params.append('_Activa', sessionStorage._Activa);
      params.append('id', ver);
      
      params.append('av_titulo', av_titulo);
      params.append('av_descripcion', av_descripcion);
      params.append('av_link', av_link);
      params.append('documento', documento);

      Axios.post(`${window.ws}wsAvisos.php`, params)
        .then(response => {
          if(response.data.elError===1){
            alerta('success',''+response.data.mensaje);
            this.listaAvisos();
            this.handleHide();
          }else{
            alerta('error',''+response.data.mensaje);
          }
        })
        .catch(function (error) {
          alerta('error',''+error);
          console.log(error)
        })
        .then(function(response) {
          window.H5_loading.hide();
        });
    }
  }

  onSubir(e) {
    if (!e.target.files[0]) {
      alerta('error','Un campo requerido está vacio');
    }else{
      if(e.target.files[0].type === "image/png" || 
        e.target.files[0].type === "image/jpeg" || 
        e.target.files[0].type === "image/jpg"
      ){
        window.H5_loading.show();

        const config = {
          headers: {
              'content-type': 'multipart/form-data'
          }
        }

        const data = new FormData();
        data.append('file', e.target.files[0]);
        data.append('_USUARIO', sessionStorage.usuario);
        data.append('_ROL', sessionStorage._ROL);
        data.append('_Activa', sessionStorage._Activa);
        data.append('id', this.state.ver);

        Axios.post(window.ws+'wsImagen.php', data, config)
        .then(response => {
          if(response.data.elError===1){
            alerta('success',response.data.mensaje)
            this.setState({
              documento: response.data.documento
            })
          }else{
            alerta('error',response.data.mensaje)
          }
        })
        .catch(error => {
          //alerta('error',''+error);
          console.log(error)
        })
        .then(function() {
          window.H5_loading.hide();
        })
      }else{
        alerta('error','El archivo debe estar en formato PNG o JPG');
      }
    }
  }

  handleShow(ver){
    window.H5_loading.show();
    this.setState({show: true})
    if(ver!==''){
      this.state.lista_avisos.filter(p => p[1] === ver).map(item => { //eslint-disable-line
        this.setState({
          ver: ver,
          av_titulo: item[2],
          av_descripcion: item[3],
          av_link: item[4],
          documento: item[5],
        })
      })
    }
    window.H5_loading.hide();
  };

  handleHide(){
    this.setState({
      show: false,
      ver: '',
      av_titulo: '',
      av_descripcion: '',
      av_link: '',
      documento: '',
    })
  };

  delSo (e) {
      Swal.fire({
        title: '¿Deseas eliminar el aviso?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, eliminar!'
      }).then((result) => {
        if (result.value) {
              window.H5_loading.show();

              var params = new URLSearchParams();
              params.append('_USUARIO', sessionStorage.usuario);
              params.append('_ROL', sessionStorage._ROL);
              params.append('_Activa', sessionStorage._Activa);
              params.append('id', e);
              params.append('que', 2);

              Axios.post(`${window.ws}wsAvisos.php`, params)
                  .then(response => {
                    if(response.data.elError===1){
                      alerta('success',''+response.data.mensaje);
                      this.listaAvisos();
                    }else{
                      alerta('error',''+response.data.mensaje);
                    }
                  })
                  .catch(error => {
                    console.log(error)
                    //alerta('error',''+error);
                  })
                  .then(function() {
                      window.H5_loading.hide();
                  })
        }
      })
  }

  listaAvisos() {
    window.H5_loading.show();
    var params = new URLSearchParams();
    params.append('_USUARIO', sessionStorage.usuario);
    params.append('_ROL', sessionStorage._ROL);
    params.append('_Activa', sessionStorage._Activa);
    params.append('que', 1);

    Axios.post(`${window.ws}wsAvisos.php`, params)
        .then(response => {
          if(response.data.elError===2){
            alerta('error',''+response.data.mensaje);
          }else{
            this.setState({lista_avisos: response.data})
          }
        })
        .catch(error => {
          console.log(error)
          alerta('error',''+error);
        })
        .then(function() {
            window.H5_loading.hide();
        })
  }

  render () {
    return (
      <div className="flex_smenu">
        <Menus />
        <div id={this.props.ver_smenu}>
          <div className="max_width padding_width">
            <h10>Avisos</h10>
            <div className="linea"></div>
            <div id="cl"><br/></div>
            <div className="flex_nueva">
              <div className="nueva" onClick={this.handleShow.bind(this, '')}>+</div>
              <div className="l_nueva" onClick={this.handleShow.bind(this, '')}>Nuevo</div>
            </div>
            <div id="cl"><br/></div>
            {
              this.state.lista_avisos.length > 0 ?
                <div className="tabla_responsiva">
                  <table className="carrillo" cellspacing="3">
                    <tr>
                      <th>#</th>
                      <th>Título</th>
                      <th>Descripción</th>
                      <th>Link</th>
                      <th>Imagen</th>
                      <th>Fecha</th>
                      <th></th>
                    </tr>
                    {
                      this.state.lista_avisos.map((item, i) =>
                        <tr key={i}>
                          <td>{item[0]}</td>
                          <td>{item[2]}</td>
                          <td>{item[3]}</td>
                          <td>{item[4] ? <a href={item[4]} target="_new" className="circulo_avisos">Enlace</a> : "N/A"}</td>
                          <td>{item[5] ? <a href={`${window.ws}avisos/AV_${item[1]}.${item[5]}`} target="_new" className="circulo_avisos">Ver</a> : "N/A"}</td>
                          <td>{item[6]}</td>
                          <td>
                            <img src="img/editar.png" alt="Editar" title="Editar" height="20px" onClick={this.handleShow.bind(this, item[1])} className="grises puntero"/>
                            <img src="img/eliminar.png" alt="Eliminar" title="Eliminar" height="20px" onClick={this.delSo.bind(this, item[1])} className="grises puntero"/>
                          </td>
                        </tr>
                      )
                    }
                  </table>
                </div>
              :
                <center>No hay avisos registrados</center>
            }
          </div>
          <Pie />
        </div>
        <Modal
          show={this.state.show}
          onHide={this.handleHide.bind(this)}
          dialogClassName="modal-90w"
          className="modal_fer"
          aria-labelledby="example-custom-modal-styling-title">

          <Modal.Body className="modal_cuadro" data-aos="fade-down">
            <div className="jh-close" onClick={this.handleHide.bind(this)}>X</div>
              <form id="formulario">
                <center><h10>{this.state.ver==='' ? 'Registrar aviso' : 'Editar aviso'}</h10></center>
                <div id="cl"><br/></div>
                <table>
                  <tr>
                    <td>*Título:</td>
                    <td><input type="text" name="av_titulo" onChange={this.handleChange} value={this.state.av_titulo} required/></td>
                  </tr>
                  <tr>
                    <td>Descripción:&nbsp;</td>
                    <td><input type="text" name="av_descripcion" onChange={this.handleChange} value={this.state.av_descripcion}/></td>
                  </tr>
                  <tr>
                    <td>Link:</td>
                    <td><input type="text" name="av_link" onChange={this.handleChange} placeholder="ej. https://www.youtube.com/" value={this.state.av_link}/></td>
                  </tr>
                  <tr>
                    <td>*Imagen:</td>
                    <td>
                      {
                        this.state.documento ?
                          <a href={`${window.ws}avisos/AV_${this.state.ver ? this.state.ver : ''}.${this.state.documento}`} target="_new">
                            <img src="img/documento.svg" title="Ver imagen" alt="Ver imagen" className="icono_archivo"/>
                          </a>
                        :
                          null
                      }
                      <input onChange={this.onSubir} id="file-upload" type="file" accept=".png, .jpg"/>
                    </td>
                  </tr>
                </table>
                <h12>Campos obligatorios (*)</h12>
                <div id="cl"><br/></div>
                <center><div onClick={this.handleClick} className="button_login puntero">{this.state.ver==='' ? 'Registrar' : 'Editar'}</div></center>
                <div id="cl"></div>
                <div className="espaciado_final"></div>
              </form>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    ver_smenu: state.ver_smenu
  }
};

const Liga = withRouter(Avisos);
export default connect(mapStateToProps)(Liga);