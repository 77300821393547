import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import Axios from 'axios'
import Swal from 'sweetalert2'

import Menus from './Menus'
import Pie from './Pie'

const alerta = (tipo,titulo) =>{
  Swal.fire({
  type: tipo,
  title: titulo
  });
}

class MiCuenta extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nombre:'',
      correo:'',
      curp:'',
      telefono:'',
      estado:'',
      municipio:'',
      pass:'',
      pass2:'',
      fecha_nac:'',
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillMount () {
    window.H5_loading.show();
    this.listaUsuarios();
    window.scrollTo(0, 0)
  }
  
  componentDidMount () {
    window.H5_loading.hide();
  }

  handleChange(e) {
    const {name, value} = e.target;
    this.setState({[name]:value})
  }

  handleClick(e) {
    const {nombre, correo, curp, telefono, estado, municipio, fecha_nac, pass, pass2} = this.state;
    if(nombre===''){
      alerta('error','Ingresa el nombre completo');
    }else if(correo===''){
      alerta('error','Ingresa el correo electrónico');
    }else if(window.validarMail(correo)===false){
      alerta('error','Ingrese un correo electrónico valido');
    }else if(curp===''){
      alerta('error','Ingresa el CURP');
    }else if(pass!==pass2){
      alerta('error','Las contraseñas no coinciden');
    }else if(telefono===''){
      alerta('error','Ingresa el número telefónico');
    }else if(fecha_nac===''){
      alerta('error','Ingresa la fecha de nacimiento');
    }else if(estado===''){
      alerta('error','Selecciona el Estado');
    }else if(municipio===''){
      alerta('error','Selecciona el Municipio');
    }else{
      window.H5_loading.show();
      //let formData = new FormData(document.getElementById('formulario'));
      //Axios.post(window.ws+'ConsultarUsuarios.php?que=3', formData)
      var params = new URLSearchParams();
      params.append('_USUARIO', sessionStorage.usuario);
      params.append('_ROL', sessionStorage._ROL);
      params.append('_Activa', sessionStorage._Activa);
      params.append('nombre', nombre);
      params.append('correo', correo);
      params.append('curp', curp);
      params.append('telefono', telefono);
      params.append('estado', estado);
      params.append('municipio', municipio);
      params.append('pass', pass);
      params.append('pass2', pass2);
      params.append('fecha_nac', fecha_nac);

      params.append('micuenta', 'micuenta');

      Axios.post(`${window.ws}ConsultarUsuarios.php?que=3`, params)
        .then(response => {
          if(response.data.elError===1){
            alerta('success',''+response.data.mensaje);
            this.listaUsuarios();
          }else{
            alerta('error',''+response.data.mensaje);
          }
        })
        .catch(function (error) {
          alerta('error',''+error);
          console.log(error)
        })
        .then(function(response) {
          window.H5_loading.hide();
        });
    }
  }

  listaUsuarios() {
    var params = new URLSearchParams();
    params.append('_USUARIO', sessionStorage.usuario);
    params.append('_ROL', sessionStorage._ROL);
    params.append('_Activa', sessionStorage._Activa);
    params.append('micuenta', 'micuenta');

    Axios.post(`${window.ws}listas.php`, params)
    .then(response => {
      //alerta('success','LOLERO'+response.data);
      if(response.data.elError===1){
        this.setState({
          nombre: response.data.nombre,
          correo: response.data.correo,
          curp: response.data.curp,
          telefono: response.data.telefono,
          estado: response.data.estado,
          municipio: response.data.municipio,
          fecha_nac: response.data.fecha_nac,
        })
      }else{
        alerta('error',''+response.data.mensaje);
      }
    })
    .catch(error => {
      alerta('error',''+error);
      console.log(error)
    })
    .then(function() {
      window.H5_loading.hide();
    })
  }

  render () {
    return (
      <div className="flex_smenu">
        <Menus />
        <div id={this.props.ver_smenu}>
          <div className="max_width padding_width">
            <h10>Mi cuenta</h10>
            <div className="linea"></div>
            <div id="cl"><br/></div>
            <form id="formulario">
              <table style={{width:'100%'}}>
                <tr>
                  <td>Nombre:</td>
                  <td><input type="text" name="nombre" onChange={this.handleChange} placeholder="Nombre completo" value={this.state.nombre} required/></td>
                </tr>
                <tr>
                  <td>Correo:</td>
                  <td><input type="text" name="correo" onChange={this.handleChange} placeholder="Correo electrónico" value={this.state.correo} required disabled/></td>
                </tr>
                <tr>
                  <td>Contraseña:&nbsp;&nbsp;&nbsp;</td>
                  <td>
                    <input type="password" name="pass" onChange={this.handleChange} placeholder="Contraseña" value={this.state.pass}/>
                    <input type="password" name="pass2" onChange={this.handleChange} placeholder="Confirmar contraseña" value={this.state.pass2}/>
                  </td>
                </tr>
                <tr>
                  <td>CURP:</td>
                  <td><input type="text" name="curp" placeholder="Clave Única de Registro de Población" onChange={this.handleChange} value={this.state.curp} required/></td>
                </tr>
                <tr>
                  <td>Teléfono:</td>
                  <td><input type="text" name="telefono" onChange={this.handleChange} placeholder="Teléfono" value={this.state.telefono} required/></td>
                </tr>
                <tr>
                  <td>Fecha de nacimiento:</td>
                  <td><input type="date" name="fecha_nac" onChange={this.handleChange} placeholder="Día/Mes/Año" value={this.state.fecha_nac} required/></td>
                </tr>
                <tr>
                  <td>Estado:</td>
                  <td>
                    <select name="estado" className="select" onChange={this.handleChange} required>
                      <option value="" selected={this.state.estado === "" ? true : false}>- Selecciona -</option>
                      {
                        window._my_estado.map((item, i) => 
                          <option value={item.estado} key={i} selected={this.state.estado === item.estado ? true : false}>{item.estado}</option>
                        )
                      }
                    </select>
                  </td>
                </tr>
                <tr>
                  <td>Municipio<br/>Delegación:</td>
                  <td>
                    <select name="municipio" className="select" onChange={this.handleChange} required>
                      <option value="" selected={this.state.municipio === "" ? true : false}>- Selecciona -</option>
                      {
                        window._my_estado.filter(p => p.estado === this.state.estado).map(esta => 
                            esta.municipios.map((item, i) =>
                              <option value={item} key={i} selected={this.state.municipio === item ? true : false}>{item}</option>
                            )
                        )
                      }
                    </select>
                  </td>
                </tr>
              </table>
              <div id="cl"><br/></div>
              <center><div onClick={this.handleClick.bind(this)} className="button puntero">Editar</div></center>
            </form>
          </div>
          <Pie />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    ver_smenu: state.ver_smenu
  }
};

const Liga = withRouter(MiCuenta);
export default connect(mapStateToProps)(Liga);