import React, { Component } from 'react';
import { connect } from 'react-redux';
import Axios from 'axios'
import Swal from 'sweetalert2'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { Element, Link as Ir } from 'react-scroll';

import Menus from './Menus'
import Pie from './Pie'

const alerta = (tipo,titulo) =>{
  Swal.fire({
  type: tipo,
  title: titulo
  });
}

class Expediente extends Component {
  constructor(props) {
    super(props);
    this.state = {
      w_usuarios: [],

      c_usuario: '',
      c_id: '',
      c_rfc: '',
      c_fecha_1: '',
      c_fecha_2: '',

      inicial: 0,
      mostrar: 25,
      cantidad: 25,

      lista_expediente: [],
    };
    this.botonEnviar = this.botonEnviar.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.mostrarChange = this.mostrarChange.bind(this);
  }

  componentWillMount () {
    window.H5_loading.show();
    this.listaUsuarios();
    window.scrollTo(0, 0)
  }
  
  componentDidMount () {
    window.H5_loading.hide();
  }

  handleChange(e) {
    const {name, value} = e.target;
    this.setState({[name]:value})
  }

  mostrarChange(e) {
    const {name, value} = e.target;
    this.setState({
      [name]:value,
      inicial: 0,
      mostrar: parseInt(value),
      cantidad: parseInt(value)
    })
  }

  verSiguiente(e){
    if(e===2){
      this.setState({
        mostrar: this.state.mostrar+this.state.cantidad,
        inicial: this.state.inicial+this.state.cantidad, 
      });
    }else{
      this.setState({
        mostrar: this.state.mostrar-this.state.cantidad,
        inicial: this.state.inicial-this.state.cantidad, 
      });
    }
  };

  botonEnviar () {
    this.listaExpediente();
  }

  listaExpediente() {
    window.H5_loading.show();
    var params = new URLSearchParams();
    params.append('_USUARIO', sessionStorage.usuario);
    params.append('_ROL', sessionStorage._ROL);
    params.append('_Activa', sessionStorage._Activa);
    params.append('c_usuario', this.state.c_usuario);
    params.append('c_id', this.state.c_id);
    params.append('c_rfc', this.state.c_rfc);
    params.append('c_fecha_1', this.state.c_fecha_1);
    params.append('c_fecha_2', this.state.c_fecha_2);

    this.setState({
      inicial: 0,
      mostrar: 25,
      cantidad: 25,
    })

    Axios.post(`${window.ws}Expediente.php`, params)
        .then(response => {
          if(response.data.elError===2){
            alerta('error',''+response.data.mensaje);
          }else{
            this.setState({lista_expediente: response.data})
            if(response.data.elError===1){
              alerta('warning',''+response.data.mensaje);
            }
          }
        })
        .catch(error => {
          console.log(error)
          alerta('error',''+error);
        })
        .then(function() {
            window.H5_loading.hide();
        })
  }

  listaUsuarios() {
    window.H5_loading.show();
    Axios.get(window.ws+'listas.php')
      .then(response => {
        //window.alerta('success','LOLERO'+response.data);
        this.setState({w_usuarios: response.data})
      })
      .catch(error => {
        //window.alerta('error','LOLERO'+error);
        console.log(error)
      })
      .then(function() {
        window.H5_loading.hide();
      })
  }

  render () {
    return (
      <div className="flex_smenu">
        <Menus />
        <div id={this.props.ver_smenu}>
          <div className="max_width padding_width">
            <h10>Expediente</h10>
            <div className="linea"></div>
            <div id="cl"><br/></div>
            <form id="formulario2">
              <div className="pa_flex_form">
                <div className="de_flex_form">
                  Usuario:<br/>
                  <select name="c_usuario" className="select" onChange={this.handleChange}>
                    <option value="">- Selecciona -</option>
                    {
                      this.state.w_usuarios.map((item, i) =>
                        <option value={item[0]} key={i}>{item[1]}</option>
                      )
                    }
                  </select>
                  <div id="cl"></div>
                  Póliza:<br/>
                  <input type="text" placeholder="Número de póliza" name="c_id" onChange={this.handleChange}/>
                  <div id="cl"></div>
                  Registro Federal de Contribuyentes (RFC):<br/>
                  <input type="text" name="c_rfc" onChange={this.handleChange}/>
                  <div id="cl"></div>
                </div>
                <div className="de_flex_form">
                  Periodo o Fecha:<br/>
                  <input type="date" name="c_fecha_1" placeholder="Fecha inicial (Día/Mes/Año)" onChange={this.handleChange}/>
                  <div id="cl"></div>
                  <input type="date" name="c_fecha_2" placeholder="Fecha final (Día/Mes/Año)" onChange={this.handleChange}/>
                  <div id="cl"></div>
                </div>
              </div>
              <div id="cl"><br/></div>
              <Ir to="recorrer" activeClass="active" spy={true} smooth={true} duration={1000}><div onClick={this.botonEnviar} className="button puntero">Consultar</div></Ir>
            </form>
            <div id="cl"></div>
            <Element name="recorrer"></Element>
            {
              this.state.lista_expediente.length > 0 ?
                <div>
                  <div id="cl"><br/></div>
                  <div className="linea"></div>
                  <div id="cl"><br/></div>
                  <ReactHTMLTableToExcel
                    className="derecha sin_submit"
                    table="table-to-xls"
                    filename="Expediente"
                    buttonText={<img src="img/ico_excel.svg" alt="Excel" className="derecha sin_submit" title="Descargar Excel"/>}
                  />
                  <div id="cl"><br/></div>
                  {/*{this.state.inicial} de {this.state.mostrar} suma {this.state.cantidad}*/}
                  <div className="pa_flex_siguiente">
                    Resultados encontrados: {this.state.lista_expediente.length}
                    {
                      this.state.lista_expediente.length>25 ? 
                        <select name="mostrar" className="select" onChange={this.mostrarChange} style={{width:90,float:'right'}}>
                          <option value={25} selected={this.state.cantidad === 25 ? true : false}>25</option>
                          {this.state.lista_expediente.length>=50 ? <option value={50} selected={this.state.cantidad === 50 ? true : false}>50</option> : null}
                          {this.state.lista_expediente.length>=100 ? <option value={100} selected={this.state.cantidad === 100 ? true : false}>100</option> : null}
                          {this.state.lista_expediente.length>=500 ? <option value={500} selected={this.state.cantidad === 500 ? true : false}>500</option> : null}
                          {this.state.lista_expediente.length>=1000 ? <option value={1000} selected={this.state.cantidad === 1000 ? true : false}>1000</option> : null}
                          <option value={99999999999999999999} selected={this.state.cantidad === 99999999999999999999 ? true : false}>Todos</option>
                        </select>
                      :
                        null
                    }
                  </div>
                  <div className="tabla_responsiva">
                    <table className="carrillo" cellspacing="3" id="table-to-xls">
                      <tr>
                        <th>#</th>
                        <th>Usuario</th>
                        <th>Póliza(s)</th>
                        <th>Cliente (RFC)</th>
                        <th>Acción</th>
                        <th>Usuario 2</th>
                        <th>Fecha</th>
                        <th>Hora</th>
                        <th>IP</th>
                      </tr>
                      {
                        this.state.lista_expediente.slice(this.state.inicial, this.state.mostrar).map((item, i) => {
                          var miFecha = new Date(`${item[5].split(" ")[0]}T00:00`)
                          return(
                            <tr key={i}>
                              <td>{item[0]}</td>
                              <td>{this.state.w_usuarios.filter(p => p[0]===item[1]).map(nombre => nombre[1])}</td>
                              <td>{item[2]}</td>
                              <td>{item[7]}</td>
                              <td>{item[3]}</td>
                              <td>{item[4]>0 ? this.state.w_usuarios.filter(p => p[0]===item[4]).map(nombre => nombre[1]) : null}</td>
                              <td>{miFecha.getDate()}/{window.meses[miFecha.getMonth()]}/{miFecha.getFullYear()}</td>
                              <td>{item[5].split(" ")[1]}</td>
                              <td>{item[6]}</td>
                            </tr>
                          )
                        })
                      }
                    </table>
                    <div id="cl"><br/></div>
                  </div>
                  <div className="pa_flex_siguiente">
                    <div>{this.state.inicial === 0 ? null : <button onClick={this.verSiguiente.bind(this,1)} className="siguiente">Anterior</button>}</div>
                    <div>{this.state.mostrar >= this.state.lista_expediente.length ? null : <button onClick={this.verSiguiente.bind(this,2)} className="siguiente">Siguiente</button>}</div>
                  </div>
                </div> 
              :
                null
            }
          </div>
          <Pie />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    ver_smenu: state.ver_smenu
  }
};

const Liga = Expediente;
export default connect(mapStateToProps)(Liga);