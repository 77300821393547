import React, { Component } from 'react';
import { connect } from 'react-redux';
import Axios from 'axios'
import Swal from 'sweetalert2'
import Modal from 'react-bootstrap/Modal';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Element, Link as Ir, scroller } from 'react-scroll';

import Menus from './Menus'
import Pie from './Pie'

const alerta = (tipo,titulo) =>{
  Swal.fire({
  type: tipo,
  title: titulo
  });
}

class Actividades extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCitas: false,
      c_mi_accion: '',

      w_usuarios: [],
      consulta: 1,
      consultaDos: 2,
      tipo_enviado: 1,
      reasignar: '',

      actualizar: 1,

      c_actividad: '',

      c_supervisores: '',
      c_telefonistas: '',
      c_vendedores: '',
      c_recepcionistas: '',

      c_c_tel: '',

      c_c_tel_si: '',
      c_c_rec: '',
      c_c_ven: '',

      c_fecha: `${window.hoy.getFullYear()}-${(window.hoy.getMonth()+1)<10 ? '0' : ''}${window.hoy.getMonth()+1}-${(window.hoy.getDate())<10 ? '0' : ''}${window.hoy.getDate()}`,
      c_hora: `${(window.hoy.getHours())<10 ? '0' : ''}${window.hoy.getHours()}:${(window.hoy.getMinutes())<10 ? '0' : ''}${window.hoy.getMinutes()}`,
      c_accion: '',
      c_complemento: '',
      c_atencion: '',
      c_nota: '',
      c_ubicacion: '',

      semaforo: 'Semaforo',

      inicial: 0,
      mostrar: 25,
      cantidad: 25,

      arrayPolizas: '',

      c_id: '',
      c_nombre: '',
      c_rfc: '',
      c_fecha_1: '',
      c_fecha_2: '',
      c_conflictivo: '',

      di_1: 'di_1',
      di_2: 'di_2',

      show: false,
      ver: 0,
      ver_id: 0,
      ver_status_editar: 0,

      lista_polizas: [],
      lista_actividades: [],
      lista_polizas_tel: [],
      
      p_id: '',
      p_curp: '',
      p_nombres: '',
      p_apellido_p: '',
      p_apellido_m: '',
      p_correo_1: '',
      p_correo_2: '',
      p_tel_1: '',
      p_tel_2: '',
      p_tel_3: '',

      SN_tel_1: '',
      SN_tel_2: '',
      SN_tel_3: '',

      p_estado: '',
      p_municipio: '',
      p_cp: '',
      p_colonia: '',
      p_calle: '',
      p_numero: '',

      p_jubilado: '',
      p_ubicacion: '',
      p_ufecha_div: '',
      p_registro: '',
      p_cuidado: '',

      p_fecha: '',
      p_polizas_actuales: '',
      p_status: '',


      p_c_fecha: '',
      p_c_status: 0,
      p_c_supervisores: 0,
      p_c_telefonistas: 0,
      c_rec_telefonistas: 0,
      p_c_recepcionistas: 0,
      p_c_vendedores: 0,
      p_c_procesos: '',
      p_c_accion: '',
      p_c_ventas: 0,
      p_c_nel: 0,
      p_c_fecha_procesos: 0,
      p_c_cita: '',
      p_c_ubicacion: '',
      p_c_atencion: '',
      p_c_llamadores: '',
      
      documento: '',
      fecha_tipo: 1,
      //arreglo_ventas: [],

      cuantos_regresar: 0,


      lista_telefonistas_aleatorio: [],
      w_aleatorio: [],
      sql: "",
    };
    this.botonEnviar = this.botonEnviar.bind(this);
    this.asignarAleatorio = this.asignarAleatorio.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.changeVentas = this.changeVentas.bind(this);
    this.mostrarChange = this.mostrarChange.bind(this);
    this.asignarChange = this.asignarChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.actividadClick = this.actividadClick.bind(this);
    this.onSubir = this.onSubir.bind(this);
  }

  componentWillMount () {
    window.H5_loading.show();
    
    window.scrollTo(0, 0)
    this.listaUsuarios();
    this.listaUsuariosAleatorio();

    if(this.props.location.state){
      this.listaPolizas('msj');
      if(this.props.location.state!=='sin_actividad_polizas'){
        this.setState({c_rfc: this.props.location.state})
      }
    }else if(sessionStorage._ROL==='TEL' || sessionStorage._ROL==='REC' || sessionStorage._ROL==='VEN'){
      this.listaPolizas('msj');
    }

    if(sessionStorage._ROL==='ADMIN'){
      this.listaRegresar()
    }
    if(sessionStorage._ROL==='ADMIN' || sessionStorage._ROL==='REC'){
      this.listaSinAsistencia()
    }
  }
  
  componentDidMount () {
    window.H5_loading.hide();
    if(this.props.location.state){
      scroller.scrollTo('recorrer', {
        duration: 1000,
        smooth: true,
        spy:true,
      })
    }
  }

  cambiaConsulta(e) {
    const {value} = e.target;
    this.setState({consulta:value})
    this.listaPolizas(value);

    this.setState({
      inicial: 0,
      mostrar: 25,
      cantidad: 25,
    })

    scroller.scrollTo('recorrer', {
      duration: 1000,
      smooth: true,
      spy: true,
    })
  }

  cualConsultaDos(e) {
    if(e==='RR'){
      if(this.state.reasignar==='RR'){
        this.setState({reasignar:''})
      }else{
        this.setState({reasignar:e})
      }
    }else if(e==='RRR'){
      if(this.state.reasignar==='RRR'){
        this.setState({reasignar:''})
      }else{
        this.setState({reasignar:e})
      }
    }else{
      this.setState({consultaDos:e})
      this.listaPolizas(this.state.consulta,e);
      this.setState({
        inicial: 0,
        mostrar: 25,
        cantidad: 25,
      })
    }
  }

  asignarChange(e) {
    const {value} = e.target;

    var cadena = this.state.arrayPolizas;
    var incluida = cadena.includes(value+'|');
    if(incluida===false){
      this.setState({arrayPolizas: this.state.arrayPolizas+value+'|'})
    }else{
      var replace = this.state.arrayPolizas.replace(value+'|', '')
      this.setState({arrayPolizas: replace})
    }
  }
  marcarTodos () {
    if(this.state.arrayPolizas.length>0){
      this.setState({arrayPolizas: []})
    }else{
      var marcar = ''
      this.state.lista_polizas.slice(this.state.inicial, this.state.mostrar).map(item => { //eslint-disable-line
        if(!item[24] || this.state.consulta==='10'){
          marcar = marcar+item.p_id+'|'
        }
      })
      this.setState({arrayPolizas: marcar})
    }
  }
  botonAsignar (actividad) {
    if(this.state.arrayPolizas.length===0){
      alerta('error','Debes seleccionar al menos un cliente');
    }else{
      if(/*!this.state.c_supervisores && */!this.state.c_telefonistas && (actividad===3 || actividad===14)){
        alerta('error','Debes seleccionar un telefonista');
      }else{
        Swal.fire({
          title: actividad===3 ? '¿Deseas asignar la selección?' : actividad===14 ? '¿Deseas reasignar la selección?' : '¿Deseas regresar la selección para nueva asignación?',
          text: `Cientes seleccionados ${this.state.arrayPolizas.split('|').length-1}`,
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: actividad===3 ? 'Sí, asignar!' : actividad===14 ? 'Sí, reasignar!' : 'Sí, regresar'
        }).then((result) => {
          if (result.value) {
                window.H5_loading.show();

                var params = new URLSearchParams();
                params.append('_USUARIO', sessionStorage.usuario);
                params.append('_ROL', sessionStorage._ROL);
                params.append('_Activa', sessionStorage._Activa);

                params.append('arrayPolizas', this.state.arrayPolizas);
                if(actividad===3){
                  params.append('c_c_tel', this.state.c_c_tel);
                  params.append('c_supervisores', this.state.c_supervisores);
                  params.append('c_telefonistas', this.state.c_telefonistas);
                }else if(actividad===14){
                  params.append('reasignar', 'RR');
                  params.append('c_telefonistas', this.state.c_telefonistas);
                }

                Axios.post(`${window.ws}wsPolizas.php?que=${actividad}`, params)
                    .then(response => {
                      if(response.data.elError===1){
                        alerta('success',''+response.data.mensaje);
                        this.listaPolizas();
                      }else{
                        alerta('error',''+response.data.mensaje);
                      }
                    })
                    .catch(error => {
                      console.log(error)
                      //alerta('error',''+error);
                    })
                    .then(function() {
                        window.H5_loading.hide();
                    })
          }
        })
      }
    }
  }

  changeVentas(e) {
    const {name} = e.target;
    var cadena = this.state.c_accion;
    if(name==='Sin movimientos'){
      if(cadena==='Sin movimientos'){
        this.setState({c_accion: ''})
      }else{
        this.setState({c_accion: name})
      }
    }else{
      var incluida = cadena.includes(name+', ');
      if(incluida===false){
        if(cadena==='Sin movimientos'){
          this.setState({c_accion: name+', '})
        }else{
          this.setState({c_accion: this.state.c_accion+name+', '})
        }
      }else{
        var replace = this.state.c_accion.replace(name+', ', '')
        this.setState({c_accion: replace})
      }
    }
  }

  handleChange(e) {
    const {name, value} = e.target;
    if(name==='actualizar'){
      if(this.state.actualizar===0){
        this.setState({actualizar:1});
      }else{
        this.setState({actualizar:0});  
      }
    }else{
      if(name==='c_telefonistas' && sessionStorage._ROL!=='REC' && this.state.c_actividad!=='REC'){
        this.setState({
          [name]:value.split("|")[0],
          c_c_tel: value.split("|")[1],
        })
        if(sessionStorage._ROL==='ADMIN' && this.state.tipo_enviado==='3' && this.state.consulta==='3' && this.state.reasignar==='RR'){

        }else{
          this.listaPolizas(value.split("|")[0], value.split("|")[1], 'tele')
        }
      }else{
        this.setState({[name]:value}) 
      }
    }
  }


  mostrarChange(e) {
    const {name, value} = e.target;
    this.setState({
      [name]:value,
      inicial: 0,
      mostrar: parseInt(value),
      cantidad: parseInt(value)
    })
  }

  verSiguiente(e){
    if(e===2){
      this.setState({
        mostrar: this.state.mostrar+this.state.cantidad,
        inicial: this.state.inicial+this.state.cantidad, 
      });
    }else{
      this.setState({
        mostrar: this.state.mostrar-this.state.cantidad,
        inicial: this.state.inicial-this.state.cantidad, 
      });
    }
  };

  tipoConsulta(id){
    if(id===1){
      this.setState({ di_1: 'di_1', di_2: 'di_2' });
    }else{
      this.setState({ di_1: 'di_2', di_2: 'di_1' });
    }
  }

  tipoAleatorio(){
    if(this.state.sql==="aleatorio"){
      this.setState({ sql: '' });
    }else{
      this.setState({ sql: 'aleatorio' });
    }
  }

  handleShow(ver, semaforo, reagendar){
    window.H5_loading.show();
    this.setState({show: true, ver: ver, semaforo: semaforo})
    if(reagendar==='Reagendar'){
      this.setState({
        c_accion: 'Contesta',
        c_complemento: 'Agendar cita',
        c_nota: 'Reagendar cita',
      })
    }
    if(semaforo==='Información' || semaforo==='Nueva' || 'Actividades'){
      this.state.lista_polizas.filter(p => p.p_id === ver).map(item => { //eslint-disable-line
        this.setState({

          p_id: item.p_id,
          p_curp: item.p_curp,
          p_nombres: item.p_nombres,
          p_apellido_p: item.p_apellido_p,
          p_apellido_m: item.p_apellido_m,
          p_correo_1: item.p_correo_1,
          p_correo_2: item.p_correo_2,
          p_tel_1: item.p_tel_1,
          p_tel_2: item.p_tel_2,
          p_tel_3: item.p_tel_3,

          SN_tel_1: item.p_tel_1,
          SN_tel_2: item.p_tel_2,
          SN_tel_3: item.p_tel_3,

          p_estado: item.p_estado,
          p_municipio: item.p_municipio,
          p_cp: item.p_cp,
          p_colonia: item.p_colonia,
          p_calle: item.p_calle,
          p_numero: item.p_numero,

          p_jubilado: item.p_jubilado,
          p_ubicacion: item.p_ubicacion,
          p_ufecha_div: item.p_ufecha_div,
          p_registro: item.p_registro,
          p_cuidado: item.p_cuidado,

          p_fecha: item.p_fecha,
          p_polizas_actuales: item.p_polizas,
          p_status: item.p_status,

          p_c_fecha: item.p_c_fecha,
          p_c_status: item.p_c_status,
          p_c_supervisores: item.p_c_supervisores,
          p_c_telefonistas: item.p_c_telefonistas,
          p_c_recepcionistas: item.p_c_recepcionistas,
          p_c_vendedores: item.p_c_vendedores,
          p_c_procesos: item.p_c_procesos,
          p_c_accion: item.p_c_accion,
          p_c_ventas: item.p_c_ventas,
          p_c_nel: item.p_c_nel,
          p_c_fecha_procesos: item.p_c_fecha_procesos,
          p_c_cita: item.p_c_cita,
          p_c_ubicacion: item.p_c_ubicacion,
          p_c_atencion: item.p_c_atencion,
          p_c_llamadores: item.p_c_llamadores,
          
          documento: item.p_ine,

          /*
          p_id: item[1],
          p_polizas_actuales: item[8],
          p_curp: item[9],
          p_nombres: item[2],
          p_apellido_p: item[3],
          p_apellido_m: item[4],
          p_correo_1: item[6],
          p_correo_2: item[10],
          p_tel_1: item[5],
          p_tel_2: item[11],
          p_tel_3: item[12],
          p_estado: item[13],
          p_municipio: item[14],
          p_cp: item[15],
          p_colonia: item[16],
          p_calle: item[17],
          p_numero: item[18],
          p_fecha: item[19],
          p_status: item[7],
          p_registro: item[21],
          documento: item[36],
          */
        })
      })
    }
    if(semaforo==='Actividades'){
      this.listaActividad(ver);
    }
    window.H5_loading.hide();
  }; 
  handleShowDos(semaforo, id, status){
    window.H5_loading.show();
    this.setState({semaforo: semaforo, ver_id: id, ver_status_editar: status})
    this.state.lista_actividades.filter(p => p.ac_id === id).map(item => { //eslint-disable-line

      //let arreglo_accion = item.ac_accion
      //arreglo_accion = arreglo_accion.split(',');
      //arreglo_accion = JSON.parse(`[${arreglo_accion.toString}]`)

      this.setState({
        c_actividad: item.ac_actividad,

        c_fecha: `${new Date(item.ac_cita).getFullYear()}-${(new Date(item.ac_cita).getMonth()+1)<10 ? '0' : ''}${new Date(item.ac_cita).getMonth()+1}-${(new Date(item.ac_cita).getDate())<10 ? '0' : ''}${new Date(item.ac_cita).getDate()}`,
        c_hora: `${(new Date(item.ac_cita).getHours())<10 ? '0' : ''}${new Date(item.ac_cita).getHours()}:${(new Date(item.ac_cita).getMinutes())<10 ? '0' : ''}${new Date(item.ac_cita).getMinutes()}`,
        

        c_accion: item.ac_accion,
        c_atencion: item.ac_atencion,
        c_complemento: item.ac_complemento,
        c_nota: item.ac_nota,
        c_ubicacion: item.ac_ubicacion,
        //arreglo_ventas: arreglo_accion,

        c_recepcionistas: item.ac_usuario_1,
        c_vendedores: item.ac_usuario_2,
      })
    })
    window.H5_loading.hide();
  }
  handleShowTres(semaforo){
    window.H5_loading.show();
    this.setState({semaforo: semaforo})

    if(sessionStorage._ROL==='SUP'){
      this.setState({c_actividad: 'TEL'})
    }
    window.H5_loading.hide();
  };

  verCitas(){
    this.setState({showCitas: true})
  }
  hideCitas(){
    this.setState({showCitas: false})
  }

  handleHide(e){
    this.setState({
      c_actividad: '',

      c_fecha: `${window.hoy.getFullYear()}-${(window.hoy.getMonth()+1)<10 ? '0' : ''}${window.hoy.getMonth()+1}-${(window.hoy.getDate())<10 ? '0' : ''}${window.hoy.getDate()}`,
      c_hora: `${(window.hoy.getHours())<10 ? '0' : ''}${window.hoy.getHours()}:${(window.hoy.getMinutes())<10 ? '0' : ''}${window.hoy.getMinutes()}`,
      c_accion: '',
      c_atencion: '',
      c_complemento: '',
      c_nota: '',
      c_ubicacion: '',

      c_supervisores: '',
      c_telefonistas: '',
      c_vendedores: '',
      c_recepcionistas: '',

      actualizar: 1,

      ver_status_editar: 0,
      ver_id: 0,

      //arreglo_ventas: [],
    });
    if(e===1){
      this.setState({semaforo:'Actividades'})
      this.listaActividad(this.state.ver);
    }else{
      this.setState({
        show: false,

        ver: 0,

        p_id: '',
        p_curp: '',
        p_nombres: '',
        p_apellido_p: '',
        p_apellido_m: '',
        p_correo_1: '',
        p_correo_2: '',
        p_tel_1: '',
        p_tel_2: '',
        p_tel_3: '',

        SN_tel_1: '',
        SN_tel_2: '',
        SN_tel_3: '',
      
        p_estado: '',
        p_municipio: '',
        p_cp: '',
        p_colonia: '',
        p_calle: '',
        p_numero: '',

        p_jubilado: '',
        p_ubicacion: '',
        p_ufecha_div: '',
        p_registro: '',
        p_cuidado: '',

        p_fecha: '',
        p_polizas_actuales: '',
        p_status: '',

        p_c_fecha: '',
        p_c_status: 0,
        p_c_supervisores: 0,
        p_c_telefonistas: 0,
        c_rec_telefonistas: 0,
        p_c_recepcionistas: 0,
        p_c_vendedores: 0,
        p_c_procesos: '',
        p_c_accion: '',
        p_c_ventas: 0,
        p_c_nel: 0,
        p_c_fecha_procesos: 0,
        p_c_cita: '',
        p_c_ubicacion: '',
        p_c_atencion: '',
        p_c_llamadores: '',
        
        documento: '',
      });
    }
  };

  asignarAleatorio(){
    if((sessionStorage._ROL==='ADMIN' || sessionStorage._ROL==='SUP') && this.state.sql==='aleatorio'){
      if(this.state.lista_polizas.length < this.state.lista_telefonistas_aleatorio.length){
        alerta('error','No pueden dividirse el numero de clientes entre la cantidad de telefonistas');
      }else if(this.state.lista_polizas.length<1 || this.state.lista_telefonistas_aleatorio.length<1 || !this.state.sql){
        alerta('error','Un campo requerido está vacio');
      }else{
        Swal.fire({
          title: '¿Deseas asignar los clientes?',
          text: `Se asignaran ${this.state.lista_polizas.length} CLIENTES a ${this.state.lista_telefonistas_aleatorio.length} TELEFONISTAS de forma aleatoria, también se maracaran como regresadas`,
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sí, asignar!'
        }).then((result) => {
          if (result.value) {
            const objeto = []
            //console.log(this.state.lista_polizas)
            //tecnologias.push('GraphQL')
            let miContador = 0
            this.state.lista_polizas.map((item, i) => { //eslint-disable-line
              let miAleatorio = 0
              if(this.state.lista_polizas.length>1 && this.state.w_aleatorio.length>1){
                miContador = miContador+1
                if(this.state.lista_telefonistas_aleatorio.filter((p, n) => (n+1)===miContador).length>0){
                  //this.state.w_usuarios.filter(p => p[0]===item.p_c_telefonistas)
                  if(this.state.lista_telefonistas_aleatorio.filter((p, n) => (n+1)===miContador && p.us_id===item.p_c_telefonistas).length>0){
                    if(this.state.lista_telefonistas_aleatorio.filter((p, n) => (n+1)>miContador && p.us_id!==item.p_c_telefonistas).length>0){
                      miAleatorio = this.state.lista_telefonistas_aleatorio.filter((p, n) => (n+1)>miContador && p.us_id!==item.p_c_telefonistas)[0].us_id
                      miContador = miContador+1
                    }else if(this.state.lista_telefonistas_aleatorio.filter((p, n) => (n+1)<miContador && p.us_id!==item.p_c_telefonistas).length>0){
                      miAleatorio = this.state.lista_telefonistas_aleatorio.filter((p, n) => (n+1)<miContador && p.us_id!==item.p_c_telefonistas)[0].us_id
                      //miContador = miContador-1
                    }else{
                      miAleatorio = 0
                    }
                  }else{
                    miAleatorio = this.state.lista_telefonistas_aleatorio.filter((p, n) => (n+1)===miContador)[0].us_id
                  }
                }else{
                  miContador = 1
                  if(this.state.lista_telefonistas_aleatorio.filter((p, n) => (n+1)===miContador && p.us_id===item.p_c_telefonistas).length>0){
                    if(this.state.lista_telefonistas_aleatorio.filter((p, n) => (n+1)>miContador && p.us_id!==item.p_c_telefonistas).length>0){
                      miAleatorio = this.state.lista_telefonistas_aleatorio.filter((p, n) => (n+1)>miContador && p.us_id!==item.p_c_telefonistas)[0].us_id
                      miContador = miContador+1
                    }else if(this.state.lista_telefonistas_aleatorio.filter((p, n) => (n+1)<miContador && p.us_id!==item.p_c_telefonistas).length>0){
                      miAleatorio = this.state.lista_telefonistas_aleatorio.filter((p, n) => (n+1)<miContador && p.us_id!==item.p_c_telefonistas)[0].us_id
                      //miContador = miContador-1
                    }else{
                      miAleatorio = 0
                    }
                  }else{
                    miAleatorio = this.state.lista_telefonistas_aleatorio.filter((p, n) => (n+1)===miContador)[0].us_id
                  }
                }
              }
              let aleatorio_curp = ""
              if(item.p_c_telefonistas>0 && this.state.w_usuarios.filter(p => p[0]===item.p_c_telefonistas).length>0){
                aleatorio_curp = this.state.w_usuarios.filter(p => p[0]===item.p_c_telefonistas)[0][7]
              }
              objeto.push({
                cliente: item.p_id,
                aleatorio: miAleatorio,
                actual: aleatorio_curp
              })
            })
            //console.log(objeto)

            window.H5_loading.show();

            var params = new URLSearchParams();
            params.append('_USUARIO', sessionStorage.usuario);
            params.append('_ROL', sessionStorage._ROL);
            params.append('_Activa', sessionStorage._Activa);

            /*
            params.append('el_sql', this.state.sql);
            params.append('que', 'aleatorio');
            params.append('lista_aleatorio', JSON.stringify(this.state.lista_telefonistas_aleatorio));
            */
            params.append('que', 'aleatorio_sin');
            params.append('lista_aleatorio', JSON.stringify(objeto));

            Axios.post(`${window.ws}wsAleatorio.php`, params)
                .then(response => {
                  if(response.data.elError===1){
                    alerta('success',''+response.data.mensaje);
                    this.setState({sql: ""})
                    this.listaPolizas();
                  }else{
                    if(response.data.mandarLogout){
                      sessionStorage.removeItem('usuario')
                      sessionStorage.removeItem('_ROL')
                      sessionStorage.removeItem('_Nombre')
                      window.location.href = window.rutas;
                    }
                    alerta('error',''+response.data.mensaje);
                  }
                })
                .catch(error => {
                  console.log(error)
                  //alerta('error',''+error);
                })
                .then(function() {
                    window.H5_loading.hide();
                })
          }
        })
      }
    }
  }

  actividadClick(){
    if(sessionStorage._ROL==='VEN' || sessionStorage._ROL==='KAR' || (sessionStorage._ROL==='ADMIN' && this.state.c_actividad==='VEN')){
      //VENTAS
      let vende_puertas = this.state.p_c_vendedores
      if(this.state.c_vendedores){
        vende_puertas = this.state.c_vendedores
      }
      if(!this.state.c_accion /*|| !this.state.c_atencion*/ || ((sessionStorage._ROL==='ADMIN' || sessionStorage._ROL==='KAR') && !vende_puertas)){
        alerta('error','Un campo requerido está vacio');
      }else{
        window.H5_loading.show();

        var params = new URLSearchParams();
        params.append('_USUARIO', sessionStorage.usuario);
        params.append('_ROL', sessionStorage._ROL);
        params.append('_Activa', sessionStorage._Activa);

        params.append('c_poliza', this.state.ver);
        params.append('c_accion', this.state.c_accion);
        params.append('c_atencion', this.state.c_atencion);
        params.append('c_nota', this.state.c_nota);
        params.append('c_vendedores', vende_puertas);
        params.append('c_telefonista', this.state.p_c_telefonistas);

        params.append('ver_id', this.state.ver_id);
        params.append('actualizar', this.state.actualizar);

        Axios.post(`${window.ws}wsPolizas.php?que=8`, params)
            .then(response => {
              if(response.data.elError===1){
                alerta('success',''+response.data.mensaje);
                this.listaPolizas();
                if(this.state.semaforo==='Editar'){
                  this.handleHide(1);
                }else if(this.state.semaforo==='NuevaA'){
                  this.handleHide(1);
                  this.setState({
                    //ver_status: response.data.status,
                    p_c_status: response.data.status,
                  })
                }else{
                  this.handleHide();
                }
              }else{
                alerta('error',''+response.data.mensaje);
              }
            })
            .catch(error => {
              console.log(error)
              //alerta('error',''+error);
            })
            .then(function() {
                window.H5_loading.hide();
            })
      }
      //FIN VENTAS
    }else if(sessionStorage._ROL==='REC' || (sessionStorage._ROL==='ADMIN' && this.state.c_actividad==='REC')){
      //RECEPCIÓN
      if(!this.state.c_accion || /*(this.state.c_accion==='Asistió' && !this.state.c_atencion) ||*/ (this.state.c_accion==='Asistió' && !this.state.c_vendedores)){
        alerta('error','Un campo requerido está vacio');
      }else{
        window.H5_loading.show();

        let params = new URLSearchParams();
        params.append('_USUARIO', sessionStorage.usuario);
        params.append('_ROL', sessionStorage._ROL);
        params.append('_Activa', sessionStorage._Activa);

        params.append('c_poliza', this.state.ver);
        params.append('c_accion', this.state.c_accion);
        params.append('c_atencion', this.state.c_atencion);
        params.append('c_nota', this.state.c_nota);
        params.append('c_recepcionistas', this.state.c_recepcionistas);
        params.append('c_vendedores', this.state.c_vendedores);
        params.append('c_telefonista', this.state.p_c_telefonistas);
        params.append('c_rec_telefonistas', this.state.c_rec_telefonistas);

        params.append('ver_id', this.state.ver_id);
        params.append('actualizar', this.state.actualizar);

        Axios.post(`${window.ws}wsPolizas.php?que=7`, params)
            .then(response => {
              if(response.data.elError===1){
                alerta('success',''+response.data.mensaje);
                this.listaPolizas();
                if(this.state.semaforo==='Editar'){
                  this.handleHide(1);
                }else if(this.state.semaforo==='NuevaA'){
                  this.handleHide(1);
                  this.setState({
                    //ver_status: response.data.status,
                    p_c_status: response.data.status,
                    //ver_vendedor: this.state.c_vendedores
                    p_c_vendedores: this.state.c_vendedores
                  })
                }else{
                  this.handleHide();
                }
              }else{
                alerta('error',''+response.data.mensaje);
              }
            })
            .catch(error => {
              console.log(error)
              //alerta('error',''+error);
            })
            .then(function() {
                window.H5_loading.hide();
            })
      }
      //FIN RECEPCIÓN
    }else if(sessionStorage._ROL==='TEL' || (sessionStorage._ROL==='SUP' && (this.state.c_actividad==='TEL' || this.state.p_c_status>0)) || (sessionStorage._ROL==='ADMIN' && this.state.c_actividad==='TEL')){
      //TELEFONISTA
      if(!this.state.c_accion || !this.state.c_complemento || !this.state.c_fecha || !this.state.c_hora /*|| (this.state.c_accion==='Contesta' && !this.state.c_atencion)*/ || (this.state.c_complemento==='Agendar cita' && this.state.c_ubicacion==='')){
        alerta('error','Un campo requerido está vacio');
      }else{
        window.H5_loading.show();

        let params = new URLSearchParams();
        params.append('_USUARIO', sessionStorage.usuario);
        params.append('_ROL', sessionStorage._ROL);
        params.append('_Activa', sessionStorage._Activa);

        params.append('c_poliza', this.state.ver);
        params.append('c_fecha', this.state.c_fecha);
        params.append('c_hora', this.state.c_hora);
        params.append('c_accion', this.state.c_accion);
        params.append('c_complemento', this.state.c_complemento);
        params.append('c_atencion', this.state.c_atencion);
        params.append('c_nota', this.state.c_nota);
        params.append('c_usuario_2', this.state.p_c_telefonistas);
        params.append('c_ubicacion', this.state.c_ubicacion);

        params.append('ver_id', this.state.ver_id);
        params.append('actualizar', this.state.actualizar);

        Axios.post(`${window.ws}wsPolizas.php?que=4`, params)
            .then(response => {
              if(response.data.elError===1){
                alerta('success',''+response.data.mensaje);
                this.listaPolizas();
                if(this.state.semaforo==='Editar'){
                  this.handleHide(1);
                }else if(this.state.semaforo==='NuevaA'){
                  this.handleHide(1);
                  this.setState({
                    //ver_status: response.data.status,
                    p_c_status: response.data.status,
                  })
                }else{
                  this.handleHide();
                }
              }else{
                alerta('error',''+response.data.mensaje);
              }
            })
            .catch(error => {
              console.log(error)
              //alerta('error',''+error);
            })
            .then(function() {
                window.H5_loading.hide();
            })
      }
      //FIN TELEFONISTA
    }else if(this.state.p_c_status===0 && (sessionStorage._ROL==='ADMIN' || sessionStorage._ROL==='SUP')){
      //SUPERVISOR
      if(!this.state.c_telefonistas){
        alerta('error','Debes seleccionar un telefonista');
      }else{
        window.H5_loading.show();

        let params = new URLSearchParams();
        params.append('_USUARIO', sessionStorage.usuario);
        params.append('_ROL', sessionStorage._ROL);
        params.append('_Activa', sessionStorage._Activa);

        params.append('c_poliza', this.state.ver);
        params.append('c_supervisores', this.state.c_supervisores);
        params.append('c_telefonistas', this.state.c_telefonistas);

        Axios.post(`${window.ws}wsPolizas.php?que=3`, params)
            .then(response => {
              if(response.data.elError===1){
                alerta('success',''+response.data.mensaje);
                this.listaPolizas();
                this.handleHide();
              }else{
                alerta('error',''+response.data.mensaje);
              }
            })
            .catch(error => {
              console.log(error)
              //alerta('error',''+error);
            })
            .then(function() {
                window.H5_loading.hide();
            })
      }
      //FIN SUPERVISOR
    }else{
      if(!this.state.c_actividad){
        alerta('error','Debes seleccionar un tipo de actividad');
      }
    }
  }

  handleClick(e) {
    const {ver, p_id, p_polizas_actuales, p_curp, p_nombres, p_apellido_p, p_apellido_m, p_correo_1, p_correo_2, p_tel_1, p_tel_2, p_tel_3, p_estado, p_municipio, p_cp, p_colonia, p_calle, p_numero, p_fecha, p_status, p_registro, p_cuidado, p_jubilado, p_ubicacion, p_ufecha_div} = this.state;
    if(!p_id || !p_ufecha_div || !p_nombres || !p_apellido_p || !p_apellido_m || !p_tel_1){
      alerta('error','Un campo requerido está vacio');
    }else{
      if((p_correo_1 && window.validarMail(p_correo_1)===false) || (p_correo_2 && window.validarMail(p_correo_2)===false)){
        alerta('error','Ingrese un correo electrónico valido');
      }else{
        if(window.validarRfc(p_id.toUpperCase())===false){
          alerta('error','Ingrese un RFC valido');
        }else{
          //if(!p_curp && p_curp/*!p_curp && window.validarRfc(p_curp.toUpperCase())===false*/){
            //alerta('error','Ingrese un CURP valido');
          //}else{
            window.H5_loading.show();
            var params = new URLSearchParams();
            params.append('_USUARIO', sessionStorage.usuario);
            params.append('_ROL', sessionStorage._ROL);
            params.append('_Activa', sessionStorage._Activa);
            params.append('id', ver);
            
            params.append('semaforo', this.state.semaforo);
            params.append('p_id', p_id);
            params.append('p_polizas_actuales', p_polizas_actuales);
            params.append('p_curp', p_curp);
            params.append('p_nombres', p_nombres);
            params.append('p_apellido_p', p_apellido_p);
            params.append('p_apellido_m', p_apellido_m);
            params.append('p_correo_1', p_correo_1);
            params.append('p_correo_2', p_correo_2);
            params.append('p_tel_1', p_tel_1);
            params.append('p_tel_2', p_tel_2);
            params.append('p_tel_3', p_tel_3);
            params.append('p_estado', p_estado);
            params.append('p_municipio', p_municipio);
            params.append('p_cp', p_cp);
            params.append('p_colonia', p_colonia);
            params.append('p_calle', p_calle);
            params.append('p_numero', p_numero);
            params.append('p_fecha', p_fecha);
            params.append('p_jubilado', p_jubilado);
            params.append('p_ubicacion', p_ubicacion);
            
            let la_fecha_div = p_ufecha_div+"-01"
            params.append('p_ufecha_div', la_fecha_div);
            params.append('p_status', p_status);
            params.append('p_registro', p_registro);
            params.append('p_cuidado', p_cuidado);

            Axios.post(`${window.ws}wsPolizas.php`, params)
              .then(response => {
                if(response.data.elError===1){
                  alerta('success',''+response.data.mensaje);
                  if(ver!==0){
                    this.listaPolizas();
                    this.handleHide();
                  }
                }else{
                  alerta('error',''+response.data.mensaje);
                }
              })
              .catch(function (error) {
                alerta('error',''+error);
                console.log(error)
              })
              .then(function(response) {
                window.H5_loading.hide();
              });
          //}
        }
      }
    }
  }

  botonEnviar () {
    this.listaPolizas();
    this.setState({
      inicial: 0,
      mostrar: 25,
      cantidad: 25,
    })
  }

  listaPolizas(e, orden, tele) {
    window.H5_loading.show();
    var params = new URLSearchParams();
    params.append('_USUARIO', sessionStorage.usuario);
    params.append('_ROL', sessionStorage._ROL);
    params.append('_Activa', sessionStorage._Activa);

    let mi_rfc_envia = this.state.c_rfc
    if(this.props.location.state!=='sin_actividad_polizas' && e==='msj'){
      mi_rfc_envia = this.props.location.state
      params.append('sin_actividad_polizas', 2);
    }
    params.append('c_id', this.state.c_id);
    params.append('c_nombre', this.state.c_nombre);
    params.append('c_rfc', mi_rfc_envia);
    params.append('c_fecha_1', this.state.c_fecha_1);
    params.append('c_fecha_2', this.state.c_fecha_2);
    params.append('c_mi_accion', this.state.c_mi_accion);
    params.append('c_conflictivo', this.state.c_conflictivo);

    params.append('c_telefonistas', this.state.c_telefonistas);
    params.append('c_c_tel', this.state.c_c_tel);
    params.append('c_c_tel_si', this.state.c_c_tel_si);
    params.append('c_c_rec', this.state.c_c_rec);
    params.append('c_c_ven', this.state.c_c_ven);

    params.append('url', 1);

    if(this.props.location.state==='sin_actividad_polizas' && e==='msj'){
      params.append('sin_actividad_polizas', 1);
    }

    if(tele){
      params.append('c_telefonistas', e);
      params.append('c_c_tel', orden);
      params.append('consulta', this.state.consulta);
      params.append('consultaDos', this.state.consultaDos);

      this.setState({
        tipo_enviado: this.state.consulta
      })
    }else if(e!=='msj'){
      if(e>0){
        params.append('consulta', e);
        this.setState({
          tipo_enviado: e
        })
      }else{
        params.append('consulta', this.state.consulta);
        this.setState({
          tipo_enviado: this.state.consulta
        })
      }
      if(orden>0){
        params.append('consultaDos', orden);
      }else{
        params.append('consultaDos', this.state.consultaDos);
      }
    }

    params.append('fecha_tipo', this.state.fecha_tipo);

    Axios.post(`${window.ws}wsPolizas.php?que=1`, params)
        .then(response => {
          if(response.data.elError===2){
            if(response.data.mandarLogout){
              sessionStorage.removeItem('usuario')
              sessionStorage.removeItem('_ROL')
              sessionStorage.removeItem('_Nombre')
              window.location.href = window.rutas;
            }else{
              alerta('error',''+response.data.mensaje);
            }
          }else{
            this.setState({lista_polizas: response.data.lista_array, arrayPolizas: []})
            if(sessionStorage._ROL==='TEL' && e!=='msj'){
              this.setState({lista_polizas_tel: response.data.lista_tel})
              //alert(response.data.lista_tel.length)
            }
            if(response.data.elError===1){
              if(e==='msj'){
                if(this.props.location.state==='sin_actividad_polizas'){
                  alerta('warning','Ya no hay clientes sin actividad');
                  this.props.history.push('inicio');
                }else{
                  if(sessionStorage._ROL==='TEL' || sessionStorage._ROL==='VEN'){
                    //alerta('warning','No tienes polizas asignadas');
                  }else{
                    //alerta('warning','No hay polizas con citas pendientes');
                  }
                }
              }else{
                alerta('warning',''+response.data.mensaje);
                if(tele){
                  this.setState({
                    c_telefonistas: '',
                    c_c_tel: '',
                  })
                }
              }
            }
          }
        })
        .catch(error => {
          console.log(error)
          alerta('error',''+error);
        })
        .then(function() {
          window.H5_loading.hide();
        })
  }

  onSubir(e) {
    if (!e.target.files[0]) {
      alerta('error','Un campo requerido está vacio');
    }else{
      if(e.target.files[0].type === "image/png" || 
        e.target.files[0].type === "image/jpeg" || 
        e.target.files[0].type === "image/jpg" || 
        e.target.files[0].type === "application/pdf"
      ){
        window.H5_loading.show();

        const config = {
          headers: {
              'content-type': 'multipart/form-data'
          }
        }

        const data = new FormData();
        data.append('file', e.target.files[0]);
        data.append('_USUARIO', sessionStorage.usuario);
        data.append('_ROL', sessionStorage._ROL);
        data.append('_Activa', sessionStorage._Activa);
        data.append('id', this.state.ver);

        Axios.post(window.ws+'wsDocumento.php', data, config)
        .then(response => {
          if(response.data.elError===1){
            alerta('success',response.data.mensaje)
            this.setState({
              documento: response.data.documento
            })
          }else{
            alerta('error',response.data.mensaje)
          }
        })
        .catch(error => {
          //alerta('error',''+error);
          console.log(error)
        })
        .then(function() {
          window.H5_loading.hide();
        })
      }else{
        alerta('error','El archivo debe estar en formato PNG, JPG o PDF');
      }
    }
  }

  delSo (e, status) {
    Swal.fire({
      title: '¿Deseas eliminar esta actividad?',
      //text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminar!',

      input: status===1 ? '' : 'checkbox',
      inputValue: 1,
      inputPlaceholder: status===1 ? '' : 'Actualizar estatus',
    }).then((result) => {
      if(result.value===1 || result.value===0 || (status===1 && result.value)){
        window.H5_loading.show();

        var params = new URLSearchParams();
        params.append('_USUARIO', sessionStorage.usuario);
        params.append('_ROL', sessionStorage._ROL);
        params.append('_Activa', sessionStorage._Activa);

        params.append('id', e);
        params.append('c_poliza', this.state.ver);
        params.append('c_status', status);

        if(result.value===1 || result.value===0){
          params.append('actualizar', result.value);
        }

        Axios.post(`${window.ws}wsPolizas.php?que=6`, params)
            .then(response => {
              if(response.data.elError===1){
                alerta('success',''+response.data.mensaje);
                this.setState({
                  //ver_status: response.data.status,
                  p_c_status: response.data.status,
                })
                this.listaActividad(this.state.ver);
                this.listaPolizas();
              }else{
                alerta('error',''+response.data.mensaje);
              }
            })
            .catch(error => {
              console.log(error)
              //alerta('error',''+error);
            })
            .then(function() {
                window.H5_loading.hide();
            })
      }
    })
  }

  listaUsuarios() {
    window.H5_loading.show();
    Axios.get(window.ws+'listas.php')
      .then(response => {
        //window.alerta('success','LOLERO'+response.data);
        this.setState({w_usuarios: response.data})
      })
      .catch(error => {
        //window.alerta('error','LOLERO'+error);
        console.log(error)
      })
      .then(function() {
        window.H5_loading.hide();
      })
  }

  listaUsuariosAleatorio() {
    window.H5_loading.show();

    var params = new URLSearchParams();
    params.append('_USUARIO', sessionStorage.usuario);
    params.append('_ROL', sessionStorage._ROL);
    params.append('_Activa', sessionStorage._Activa);
    params.append('micuenta', 'aleatorio')
    Axios.post(window.ws+'listas.php', params)
      .then(response => {
        //window.alerta('success','LOLERO'+response.data);
        this.setState({
          w_aleatorio: response.data.lista_array,
          lista_telefonistas_aleatorio: response.data.lista_array,
        })
      })
      .catch(error => {
        //window.alerta('error','LOLERO'+error);
        console.log(error)
      })
      .then(function() {
        window.H5_loading.hide();
      })
  }



  listaActividad(ver) {
    window.H5_loading.show();

    var params = new URLSearchParams();
    params.append('_USUARIO', sessionStorage.usuario);
    params.append('_ROL', sessionStorage._ROL);
    params.append('_Activa', sessionStorage._Activa);

    params.append('c_poliza', ver);

    Axios.post(`${window.ws}wsPolizas.php?que=5`, params)
        .then(response => {
          if(response.data.elError===2){
            alerta('error',''+response.data.mensaje);
          }else{
            this.setState({lista_actividades: response.data.lista_array})
            if(response.data.elError===1){
              //alerta('warning',''+response.data.mensaje);
              this.handleHide();
            }
          }
        })
        .catch(error => {
          console.log(error)
          //alerta('error',''+error);
        })
        .then(function() {
            window.H5_loading.hide();
        })
  }

  listaRegresar(envia) {
    if(envia>0){
      Swal.fire({
        title: '¿Deseas regresar las pólizas para nueva asignación?',
        //text: "You won't be able to revert this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, regresar!',
      }).then((result) => {
        if(result.value){
          window.H5_loading.show();

          var params = new URLSearchParams();
          params.append('_USUARIO', sessionStorage.usuario);
          params.append('_ROL', sessionStorage._ROL);
          params.append('_Activa', sessionStorage._Activa);

          params.append('si_regresa', 'regresame');

          Axios.post(`${window.ws}wsPolizas.php?que=REGRESAR`, params)
              .then(response => {
                if(response.data.elError===2){
                  alerta('error',''+response.data.mensaje);
                }else{
                  alerta('success',''+response.data.mensaje);
                  //console.log(response.data.lista_array)
                }
              })
              .catch(error => {
                console.log(error)
                alerta('error',''+error);
              })
              .then(function() {
                  window.H5_loading.hide();
              })
        }
      })
    }else{
      window.H5_loading.show();

      var params = new URLSearchParams();
      params.append('_USUARIO', sessionStorage.usuario);
      params.append('_ROL', sessionStorage._ROL);
      params.append('_Activa', sessionStorage._Activa);

      Axios.post(`${window.ws}wsPolizas.php?que=REGRESAR`, params)
          .then(response => {
            this.setState({
              cuantos_regresar: response.data.cuantos_regresa
            })
          })
          .catch(error => {
            console.log(error)
            alerta('error',''+error);
          })
          .then(function() {
              window.H5_loading.hide();
          })
      }  
  }

  listaSinAsistencia() {
    window.H5_loading.show();

    var params = new URLSearchParams();
    params.append('_USUARIO', sessionStorage.usuario);
    params.append('_ROL', sessionStorage._ROL);
    params.append('_Activa', sessionStorage._Activa);

    Axios.post(`${window.ws}wsPolizas.php?que=SINASISTENCIA`, params)
        .then(response => {
          /*
          this.setState({
            cuantos_regresar: response.data.cuantos_regresa
          })
          */
          if(response.data.elNumero>0){
            alerta('success','Personas con cita sin asistencia: '+response.data.elNumero);
          }
        })
        .catch(error => {
          console.log(error)
          alerta('error',''+error);
        })
        .then(function() {
            window.H5_loading.hide();
        })  
  }


  render () {
    const _my_jubilados = [{n:0, d:'Activo ISSSTE'}, {n:1, d:'Jubilado ISSSTE'}, {n:2, d:'Activo IMSS'}, {n:3, d:'Jubilado IMSS'}, {n:4, d:'Otro'}];

    var inac = 0;
    var _elCorreo = '';
    var _elRol = '';

    let miID = 0
    if(this.state.w_usuarios.length>0){
      miID = this.state.w_usuarios.filter(p => p[5]===sessionStorage.usuario)[0][0]
    }

    let miContador = 0

    return (
      <div className="flex_smenu">
        <Menus />
        <div id={this.props.ver_smenu}>
          <div className="max_width padding_width">
            <h10>Actividades</h10>
            {
              this.state.cuantos_regresar>0 ?
                <div>
                  <div className="linea"></div>
                  <div id="cl"><br/></div>
                  <div className="consulta" onClick={this.listaRegresar.bind(this, 1)}>Regresar polizas sin actividad reciente ({this.state.cuantos_regresar})</div>
                </div>
              :
                null
            }
            <div className="linea"></div>
            <div id="cl"><br/></div>
            {
              sessionStorage._ROL==='TEL' ?
                null
              :
                <div>
                  <div className="pa_flex_form">
                    <div className="de_flex_form">
                      <div className="consulta" id={this.state.di_1} onClick={this.tipoConsulta.bind(this, 1)}>Consulta Rápida</div>
                    </div>
                    <div className="de_flex_form">
                      <div className="consulta" id={this.state.di_2} onClick={this.tipoConsulta.bind(this, 2)}>Consulta Avanzada</div>
                    </div>
                  </div>
                  <div id="cl"><br/></div>
                </div>
            }
            <form id="formulario2">
              <div className="pa_flex_form">
                <div className="de_flex_form">
                  Póliza:<br/>
                  <input type="text" placeholder="Número de póliza" name="c_id" onChange={this.handleChange}/>
                  <div id="cl"></div>
                  Registro Federal de Contribuyentes (RFC):<br/>
                  <input type="text" name="c_rfc" value={this.state.c_rfc} onChange={this.handleChange}/>
                  <div id="cl"></div>
                  {
                    sessionStorage._ROL==='ADMIN' || sessionStorage._ROL==='KAR' ?
                      <div>
                        {/*ANTES
                        Tipo de póliza:<br/>
                        <select name="consulta" className="select" onChange={this.cambiaConsulta.bind(this)}>
                          {
                            window._my_consulta.map((item, i) =>
                              <option value={item.status} key={i}>{i+1}.- {item.titulo}</option>
                            )
                          }
                        </select>
                        <div id="cl"></div>
                        */}
                        Tipo de póliza:<br/>
                        <select name="consulta" className="select" onChange={this.handleChange}>
                          {
                            window._my_consulta.map((item, i) =>
                              <option value={item.status} key={i}>{i+1}.- {item.titulo}</option>
                            )
                          }
                        </select>
                        <div id="cl"></div>
                        {
                          this.state.tipo_enviado==='3' && this.state.consulta==='3' && (sessionStorage._ROL==='KAR' || sessionStorage._ROL==='ADMIN' || sessionStorage._ROL==='SUP') ?
                            <div className="flex_consulta">
                              <div className="consultaDos" id={this.state.reasignar==='RR' ? 'dic_1' : 'dic_2'} onClick={this.cualConsultaDos.bind(this, 'RR')}>Reasignar</div>
                            </div>
                          :
                            null
                        }
                        {
                          this.state.tipo_enviado>3 && this.state.consulta>3 && (sessionStorage._ROL==='KAR' || sessionStorage._ROL==='ADMIN' || sessionStorage._ROL==='SUP') ?
                            <div className="flex_consulta">
                              <div className="consultaDos" id={this.state.reasignar==='RRR' ? 'dic_1' : 'dic_2'} onClick={this.cualConsultaDos.bind(this, 'RRR')}>Regresar</div>
                            </div>
                          :
                            null
                        }
                        {
                          sessionStorage._ROL==='KAR' || sessionStorage._ROL==='ADMIN' || sessionStorage._ROL==='SUP' ?
                            <div>
                              Acción:<br/>
                              <select name="c_mi_accion" className="select" onChange={this.handleChange}>
                                <option value="">- Selecciona -</option>
                                {
                                  window._my_accion.map((item, i) =>
                                    <option value={item.nombre} key={i}>{item.nombre}</option>
                                  )
                                }
                              </select>
                              <div id="cl"></div>
                            </div>
                          :
                            null
                        }
                        {
                          this.state.di_1 === 'di_2' ?
                            <div>
                              {
                                sessionStorage._ROL==='KAR' || sessionStorage._ROL==='ADMIN' ?
                                  <div>
                                    Telefonista:<br/>
                                    <select name="c_c_tel_si" className="select" onChange={this.handleChange}>
                                      <option value="">- Selecciona -</option>
                                      {
                                        this.state.w_usuarios.filter(p => p[3]==='TEL').map((item, i) =>
                                          <option value={item[0]} key={i}>{item[1]}</option>
                                        )
                                      }
                                    </select>
                                    <div id="cl"></div>
                                  </div>
                                :
                                  null
                              }
                              Recepcionista:<br/>
                              <select name="c_c_rec" className="select" onChange={this.handleChange}>
                                <option value="">- Selecciona -</option>
                                {
                                  this.state.w_usuarios.filter(p => p[3]==='REC').map((item, i) =>
                                    <option value={item[0]} key={i}>{item[1]}</option>
                                  )
                                }
                              </select>
                              <div id="cl"></div>
                              Vendedor:<br/>
                              <select name="c_c_ven" className="select" onChange={this.handleChange}>
                                <option value="">- Selecciona -</option>
                                {
                                  this.state.w_usuarios.filter(p => p[3]==='VEN').map((item, i) =>
                                    <option value={item[0]} key={i}>{item[1]}</option>
                                  )
                                }
                              </select>
                              <div id="cl"></div>
                            </div>
                          :
                            null
                        }
                      </div>
                    : sessionStorage._ROL==='SUP' ?
                      <div>
                        Tipo de póliza:<br/>
                        <select name="consulta" className="select" onChange={this.cambiaConsulta.bind(this)}>
                          {
                            window._my_consulta.filter(p => p.status!==10).map((item, i) =>
                              <option value={item.status} key={i}>{i+1}.- {item.titulo}</option>
                            )
                          }
                        </select>
                        <div id="cl"></div>
                        {/*
                          this.state.di_1 === 'di_2' ?
                            <div>
                              Telefonista:<br/>
                              <select name="c_c_tel_si" className="select" onChange={this.handleChange}>
                                <option value="">- Selecciona -</option>
                                {
                                  this.state.w_usuarios.filter(p => p[3]==='TEL').map((item, i) =>
                                    <option value={item[0]} key={i}>{item[1]}</option>
                                  )
                                }
                              </select>
                              <div id="cl"></div>
                            </div>
                          :
                            null
                        */}
                      </div>
                    :
                      null
                  }
                  <div id="cl"><br/></div>
                  {
                    this.state.lista_polizas.filter(p => sessionStorage._ROL==='TEL' ? p.p_c_status<4 : p.p_id).length>0 ?
                      <div className="flex_consulta">
                        <Ir to="recorrer" activeClass="active" spy={true} smooth={true} duration={1000} className="consultaDos" id={this.state.consultaDos===1 ? 'dic_1' : 'dic_2'} onClick={this.cualConsultaDos.bind(this, 1)} title="Ascendente">↑</Ir>
                        <Ir to="recorrer" activeClass="active" spy={true} smooth={true} duration={1000} className="consultaDos" id={this.state.consultaDos===2 ? 'dic_1' : 'dic_2'} onClick={this.cualConsultaDos.bind(this, 2)} title="Descendente">↓</Ir>
                      </div>
                    :
                      null
                  }
                </div>
                <div className="de_flex_form">
                  {
                    this.state.di_1 === 'di_2' ?
                      <div>
                        Nombre/Apellidos:<br/>
                        <input type="text" name="c_nombre" onChange={this.handleChange}/>
                        <div id="cl"></div>
                        {/*
                        Amabilidad:<br/>
                        <select name="c_conflictivo" className="select" onChange={this.handleChange}>
                          <option value="">- Selecciona -</option>
                          <option value="1">Buena</option>
                          <option value="2">Regular</option>
                          <option value="3">Mala</option>
                          <option value="4" className="red">Cliente conflictivo</option>
                        </select>
                        */}
                        Periodo o Fecha:<br/>
                        <input type="date" name="c_fecha_1" placeholder="Fecha inicial (Día/Mes/Año)" onChange={this.handleChange}/>
                        <div id="cl"></div>
                        <input type="date" name="c_fecha_2" placeholder="Fecha final (Día/Mes/Año)" onChange={this.handleChange}/>
                        <div id="cl"></div>
                        <input type="radio" value="1" name="fecha_tipo" checked={this.state.fecha_tipo===1 ? true : null} onChange={this.handleChange}/>&nbsp;Polizas&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <input type="radio" value="2" name="fecha_tipo" checked={this.state.fecha_tipo===2 ? true : null} onChange={this.handleChange}/>&nbsp;Dividendos&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <input type="radio" value="3" name="fecha_tipo" checked={this.state.fecha_tipo===3 ? true : null} onChange={this.handleChange}/>&nbsp;Cita
                        <div id="cl"></div>
                      </div>
                    :
                      null
                  }
                </div>
              </div>
              <div id="cl"><br/></div>
              <Ir to="recorrer" activeClass="active" spy={true} smooth={true} duration={1000}><div onClick={this.botonEnviar} className="button puntero">Consultar</div></Ir>
            </form>
            <div id="cl"></div>
            <Element name="recorrer"></Element>
            {
              this.state.lista_polizas.filter(p => sessionStorage._ROL==='TEL' ? p.p_c_status<4 : p.p_id).length > 0 ?
                <div>
                  <div id="cl"><br/></div>
                  <div className="linea"></div>
                  <div id="cl"><br/></div>
                  {
                    sessionStorage._ROL==='TEL' || sessionStorage._ROL==='REC' || sessionStorage._ROL==='VEN' || sessionStorage._ROL==='KAR' /*|| this.state.lista_polizas.filter(p => p.p_c_status>0).length===this.state.lista_polizas.length*/ ?
                      null
                    :
                      <div>
                        {
                          this.state.lista_polizas.length>1 && this.state.w_aleatorio.length>1 ?
                            <div>
                              <div className="de_flex_form">
                                <div className="consulta" onClick={this.tipoAleatorio.bind(this)}>{this.state.sql==='aleatorio' ? 'Cambiar a manual' : 'Cambiar a aleatorio'}</div>
                              </div>
                              <div id="cl"><br/></div>
                              {
                                this.state.sql==='aleatorio' ?
                                  <div>
                                    <h10>Asignar clientes a multiples telefonistas aleatoriamente</h10>
                                    <div id="cl"><br/></div>
                                    <Typeahead
                                      id="basic-typeahead-multiple"
                                      multiple
                                      labelKey="us_nombre"
                                      onChange={(event) => {
                                        /*
                                        if(typeof event==='object' && event.length>0){
                                          alert('no'+event[0][1])
                                          //set_per_c_jefe(event[0].per_id)
                                        }else{
                                          //set_per_c_jefe("")
                                        }
                                        //set_c_jefe(event)
                                        */
                                        this.setState({lista_telefonistas_aleatorio: event})
                                      }}
                                      options={this.state.w_aleatorio}
                                      selected={this.state.lista_telefonistas_aleatorio}
                                      emptyLabel="No hay resultados."
                                      placeholder="Selecciona uno o multiples."
                                    />
                                    <div id="cl"><br/></div>
                                    <div onClick={this.asignarAleatorio} className="button puntero">Asignar {this.state.lista_polizas.length} CLIENTES a {this.state.lista_telefonistas_aleatorio.length} TELEFONISTAS de forma aleatoria</div>
                                    <div id="cl"><br/></div>
                                  </div>
                                :
                                  null
                              }
                            </div>
                          :
                            null
                        }
                        {
                          this.state.sql==='aleatorio' ?
                            null
                          :
                            <div>
                              <h10>Asignar individual/multiples clientes individualmente a cada telefonista</h10>
                              <div id="cl"><br/></div>
                              <div className="pa_flex_form">
                                <div className="de_flex_form">
                                  *Telefonista:<br/>
                                  <select name="c_telefonistas" className="select" onChange={this.handleChange} required>
                                    <option value="" selected={this.state.c_telefonistas === "" ? true : false}>- Selecciona -</option>
                                    {
                                      this.state.w_usuarios.filter(p => p[3]==='TEL' && p[2]===3).map((item, i) =>
                                        <option value={item[0]+"|"+item[7]} key={i} selected={this.state.c_telefonistas === item[0] ? true : false}>{item[1]}</option>
                                      )
                                    }
                                  </select>
                                </div>
                                {
                                  sessionStorage._ROL==='ADMIN' ?
                                    <div className="de_flex_form">
                                      Supervisor:<br/>
                                      <select name="c_supervisores" className="select" onChange={this.handleChange}>
                                        <option value="" selected={this.state.c_supervisores === "" ? true : false}>- Selecciona -</option>
                                        {
                                          this.state.w_usuarios.filter(p => p[3]==='SUP' && p[2]===3).map((item, i) =>
                                            <option value={item[0]} key={i} selected={this.state.c_supervisores === item[0] ? true : false}>{item[1]}</option>
                                          )
                                        }
                                      </select>
                                    </div>
                                  :
                                    <div className="de_flex_form">
                                    </div>
                                }
                              </div>
                              <div id="cl"><br/></div>
                            </div>
                        }
                      </div>
                  }

                  {
                    sessionStorage._ROL==='ADMIN' ?
                      <ReactHTMLTableToExcel
                        className="derecha sin_submit"
                        table="table-to-xls"
                        filename="Actividades"
                        buttonText={<img src="img/ico_excel.svg" alt="Excel" className="derecha sin_submit" title="Descargar Excel"/>}
                      />
                    :
                      null
                  }
                  
                  <div className="pa_flex_siguiente">
                    <div>
                      {sessionStorage._ROL==='ADMIN' || sessionStorage._ROL==='SUP' || sessionStorage._ROL==='TEL' ? <button className="ver_semaforo" onClick={this.handleShow.bind(this, 0, 'Semaforo')}>Semaforo</button> : null}
                      {
                        sessionStorage._ROL==='TEL' && this.state.lista_polizas.filter(p => sessionStorage._ROL==='TEL' ? (p.p_c_status===4 || p.p_c_status===5) : p.p_c_status).length>0 && this.state.lista_polizas_tel.length>0 ? 
                          <div>
                            <button className="ver_semaforo" onClick={this.verCitas.bind(this)}>Mis citas</button>
                            <Modal
                              show={this.state.showCitas}
                              onHide={this.hideCitas.bind(this)}
                              dialogClassName="modal-90w"
                              className="modal_fer"
                              aria-labelledby="example-custom-modal-styling-title">

                              <Modal.Body className="modal_cuadro_dos" data-aos="fade-down">
                                <div className="jh-close" onClick={this.hideCitas.bind(this)}>X</div>
                                Resultados encontrados: {this.state.lista_polizas_tel.length}
                                <div className="tabla_responsiva">
                                  <table className="carrillo" cellspacing="3">
                                    <tr>
                                      <th>#</th>
                                      <th>Póliza(s)</th>
                                      <th>RFC</th>
                                      <th>Nombre</th>
                                      <th>Fecha de cita</th>
                                      <th>Fecha de actividad</th>
                                    </tr>
                                    {
                                      this.state.lista_polizas_tel.map((item, i) => {
                                        /*
                                        let diasDeDiferencia = 0
                                        if(item.p_c_fecha){
                                          let fecha1 = new Date(item.p_c_fecha);
                                          let fecha2 = window.hoy;
                                          let diferencia = fecha2.getTime() - fecha1.getTime();
                                          diasDeDiferencia = Math.round(diferencia / 1000 / 60 / 60 / 24);
                                        }
                                        */
                                        return(
                                          <tr key={i} className={
                                            item.p_c_status===1 || (item.p_c_status===2 && item.p_c_accion!=='Llamar más tarde') ?
                                              'semaforo_morado'
                                            : item.p_c_status===2 && item.p_c_accion==='Llamar más tarde' ?
                                              'semaforo_naranja2'
                                            : item.p_c_status===3 && item.p_c_accion!=='No asistió' ?
                                              'semaforo_amarillo'
                                            : item.p_c_status===3 && item.p_c_accion==='No asistió' ?
                                              'semaforo_naranja'
                                            : item.p_c_status===10 ?
                                              'semaforo_rosa'
                                            : item.p_c_status===4 ?
                                              'semaforo_verde'
                                            : item.p_c_status>4 && item.p_c_status<10 ?
                                              'semaforo_verde2'
                                            : item.p_c_status===0 && item.p_c_ventas>0 ?
                                              'semaforo_azul'
                                            :
                                              null
                                          }>
                                            <td>{i+1}</td>
                                            <td>{item.p_polizas}{(sessionStorage._ROL==='ADMIN' || sessionStorage._ROL==='SUP') && item.p_c_nel>0 && item.p_c_procesos>0 ? <div style={{color:'red'}}>No localizado anteriormente / Sin interes</div> : null}</td>
                                            <td>{item.p_id}</td>
                                            <td>{item.p_apellido_p}&nbsp;{item.p_apellido_m}&nbsp;{item.p_nombres}</td>
                                            <td>
                                              {item.p_c_cita ? item.p_c_cita : 'Sin cita'}
                                            </td>
                                            <td>
                                              {item.p_c_fecha ? item.p_c_fecha : 'Sin actividad'}
                                            </td>
                                          </tr>
                                        )
                                      })
                                    }
                                  </table>
                                  <div id="cl"><br/></div>
                                </div>
                              </Modal.Body>
                            </Modal>
                          </div>
                        : 
                          null
                      }
                      <div id="cl"></div>
                      Resultados encontrados: {this.state.lista_polizas.filter(p => sessionStorage._ROL==='TEL' ? p.p_c_status<4 : p.p_id).length}
                      {
                        this.state.arrayPolizas.length>0 && this.state.sql==="" ?
                          <div>Clientes seleccionados: {this.state.arrayPolizas.split("|").length-1}</div>
                        :
                          null
                      }          
                    </div>
                    {
                      this.state.lista_polizas.filter(p => sessionStorage._ROL==='TEL' ? p.p_c_status<4 : p.p_id).length>25 ? 
                        <select name="mostrar" className="select" onChange={this.mostrarChange} style={{width:90,float:'right'}}>
                          <option value={25} selected={this.state.cantidad === 25 ? true : false}>25</option>
                          {this.state.lista_polizas.filter(p => sessionStorage._ROL==='TEL' ? p.p_c_status<4 : p.p_id).length>=50 ? <option value={50} selected={this.state.cantidad === 50 ? true : false}>50</option> : null}
                          {this.state.lista_polizas.filter(p => sessionStorage._ROL==='TEL' ? p.p_c_status<4 : p.p_id).length>=100 ? <option value={100} selected={this.state.cantidad === 100 ? true : false}>100</option> : null}
                          {this.state.lista_polizas.filter(p => sessionStorage._ROL==='TEL' ? p.p_c_status<4 : p.p_id).length>=500 ? <option value={500} selected={this.state.cantidad === 500 ? true : false}>500</option> : null}
                          {this.state.lista_polizas.filter(p => sessionStorage._ROL==='TEL' ? p.p_c_status<4 : p.p_id).length>=1000 ? <option value={1000} selected={this.state.cantidad === 1000 ? true : false}>1000</option> : null}
                          <option value={99999999999999999999} selected={this.state.cantidad === 99999999999999999999 ? true : false}>Todos</option>
                        </select>
                      :
                        null
                    }
                  </div>
                  <div className="tabla_responsiva">
                    <table className="carrillo" cellspacing="3">
                      <tr>
                        <th>#</th>
                        <th>Póliza(s)</th>
                        <th>RFC</th>
                        <th>Nombre</th>
                        {
                          sessionStorage._ROL==='ADMIN' ?
                            <>
                              <th>Edad</th>
                              <th>Teléfono</th>
                              <th>Correo</th>
                              <th>Fecha actividad</th>
                            </>
                          :
                            null
                        }
                        {
                          sessionStorage._ROL==='REC' ?
                            <>
                              <th>Cita</th>
                              <th>Ubicación</th>
                            </>
                          :
                            null
                        }
                        {
                          sessionStorage._ROL==='ADMIN' || sessionStorage._ROL==='SUP' ?
                            this.state.lista_polizas.length>1 && this.state.w_aleatorio.length>1 && this.state.sql==='aleatorio' ?
                              <>
                                <th>Asignación aleatoria</th>
                                <th>Actual</th>
                              </>
                            :
                              <th onClick={this.marcarTodos.bind(this)} className="marcar">{this.state.consulta==='10' || (this.state.reasignar==='RRR' && this.state.tipo_enviado>3) ? 'Regresar' : this.state.reasignar==='RR' && this.state.tipo_enviado==='3' ? 'Reasignar' : 'Asignar'}</th>
                        
                          :
                            null
                        }
                        {
                          sessionStorage._ROL==='TEL' ?
                            <th>Fecha de actividad</th>
                          :
                            null
                        }
                        <th></th>
                      </tr>
                      {
                        this.state.lista_polizas.filter(p => sessionStorage._ROL==='TEL' ? p.p_c_status<4 : p.p_id).slice(this.state.inicial, this.state.mostrar).map((item, i) => {
                          let diasDeDiferencia = 0
                          if(item.p_c_fecha){
                            let fecha1 = new Date(item.p_c_fecha);
                            let fecha2 = window.hoy;
                            let diferencia = fecha2.getTime() - fecha1.getTime();
                            diasDeDiferencia = Math.round(diferencia / 1000 / 60 / 60 / 24);
                          }

                          
                          let miAleatorio = ""
                          let hace = "" //eslint-disable-line
                          if(this.state.lista_polizas.length>1 && this.state.w_aleatorio.length>1 && this.state.sql==='aleatorio'){
                            miContador = miContador+1
                            if(this.state.lista_telefonistas_aleatorio.filter((p, n) => (n+1)===miContador).length>0){
                              //this.state.w_usuarios.filter(p => p[0]===item.p_c_telefonistas)
                              if(this.state.lista_telefonistas_aleatorio.filter((p, n) => (n+1)===miContador && p.us_id===item.p_c_telefonistas).length>0){
                                if(this.state.lista_telefonistas_aleatorio.filter((p, n) => (n+1)>miContador && p.us_id!==item.p_c_telefonistas).length>0){
                                  miAleatorio = this.state.lista_telefonistas_aleatorio.filter((p, n) => (n+1)>miContador && p.us_id!==item.p_c_telefonistas)[0].us_nombre
                                  miContador = miContador+1
                                  hace = "UNO"
                                }else if(this.state.lista_telefonistas_aleatorio.filter((p, n) => (n+1)<miContador && p.us_id!==item.p_c_telefonistas).length>0){
                                  miAleatorio = this.state.lista_telefonistas_aleatorio.filter((p, n) => (n+1)<miContador && p.us_id!==item.p_c_telefonistas)[0].us_nombre
                                  //miContador = miContador-1
                                  hace = "DOS"
                                }else{
                                  miAleatorio = "Se repite"
                                  hace = "TRES"
                                }
                              }else{
                                miAleatorio = this.state.lista_telefonistas_aleatorio.filter((p, n) => (n+1)===miContador)[0].us_nombre
                                hace = "CUATRO"
                              }
                            }else{
                              miContador = 1
                              if(this.state.lista_telefonistas_aleatorio.filter((p, n) => (n+1)===miContador && p.us_id===item.p_c_telefonistas).length>0){
                                if(this.state.lista_telefonistas_aleatorio.filter((p, n) => (n+1)>miContador && p.us_id!==item.p_c_telefonistas).length>0){
                                  miAleatorio = this.state.lista_telefonistas_aleatorio.filter((p, n) => (n+1)>miContador && p.us_id!==item.p_c_telefonistas)[0].us_nombre
                                  miContador = miContador+1
                                  hace = "CINCO"
                                }else if(this.state.lista_telefonistas_aleatorio.filter((p, n) => (n+1)<miContador && p.us_id!==item.p_c_telefonistas).length>0){
                                  miAleatorio = this.state.lista_telefonistas_aleatorio.filter((p, n) => (n+1)<miContador && p.us_id!==item.p_c_telefonistas)[0].us_nombre
                                  //miContador = miContador-1
                                  hace = "SEIS"
                                }else{
                                  miAleatorio = "Se repite"
                                  hace = "SIETE"
                                }
                              }else{
                                miAleatorio = this.state.lista_telefonistas_aleatorio.filter((p, n) => (n+1)===miContador)[0].us_nombre
                                hace = "OCHO"
                              }
                            }
                          }

                          return(
                            <tr key={i} className={
                              item.p_c_status===1 || (item.p_c_status===2 && item.p_c_accion!=='Llamar más tarde') ?
                                'semaforo_morado'
                              : item.p_c_status===2 && item.p_c_accion==='Llamar más tarde' ?
                                'semaforo_naranja2'
                              : item.p_c_status===3 && item.p_c_accion!=='No asistió' ?
                                'semaforo_amarillo'
                              : item.p_c_status===3 && item.p_c_accion==='No asistió' ?
                                'semaforo_naranja'
                              : item.p_c_status===10 ?
                                'semaforo_rosa'
                              : item.p_c_status===4 ?
                                'semaforo_verde'
                              : item.p_c_status>4 && item.p_c_status<10 ?
                                'semaforo_verde2'
                              : item.p_c_status===0 && item.p_c_ventas>0 ?
                                'semaforo_azul'
                              :
                                null
                            }>
                              <td>{this.state.inicial+i+1}</td>
                              <td>{item.p_polizas}{(sessionStorage._ROL==='ADMIN' || sessionStorage._ROL==='SUP') && item.p_c_nel>0 && item.p_c_procesos>0 ? <div style={{color:'red'}}>No localizado anteriormente / Sin interes</div> : null}</td>
                              <td>{item.p_id} </td>
                              {/*<td className={item.p_c_atencion===4 ? 'red' : 'auto'} title={item.p_c_atencion===4 ? 'Cliente conflictivo' : item.p_c_atencion===3 ? 'Mala' : item.p_c_atencion===2 ? 'Regular' : item.p_c_atencion===1 ? 'Buena' : null}>{item.p_apellido_p}&nbsp;{item.p_apellido_m}&nbsp;{item.p_nombres}</td>*/}
                              <td>{item.p_apellido_p}&nbsp;{item.p_apellido_m}&nbsp;{item.p_nombres}</td>
                              {
                                sessionStorage._ROL==='ADMIN' ?
                                  <>
                                    <td>{item.p_id ? <div>{window._my_nacimiento(item.p_id)}<br/>{window._my_edad(item.p_id)} años</div> : ''}</td>
                                    <td>
                                      {
                                        item.p_tel_1.substr(-1)==="W" || item.p_tel_1.substr(-1)==="w" ?
                                          <a href={`https://wa.me/521${item.p_tel_1.slice(0, -1)}?text=Hola%20${item.p_nombres}`} target="new" className="green">{item.p_tel_1.slice(0, -1)}</a>
                                        :
                                          null
                                      }
                                      <a href={`tel:${item.p_tel_1}`} target="new" className="blue">{item.p_tel_1}</a>
                                    </td>
                                    <td>{item.p_correo_1}</td>
                                  </>
                                :
                                  null
                              }
                              {
                                sessionStorage._ROL==='ADMIN' || sessionStorage._ROL==='TEL' ?
                                  <td>
                                    {item.p_c_fecha ? item.p_c_fecha : 'Sin actividad'}
                                    {diasDeDiferencia>0 ? <div className={diasDeDiferencia>15 ? 'red' : 'auto'}>Días sin actividad {diasDeDiferencia}</div> : null}
                                  </td>
                                :
                                  null
                              }
                              {
                                sessionStorage._ROL==='REC' ?
                                  <>
                                    <td>{item.p_c_cita ? <div>{window.dia[new Date(item.p_c_cita).getDay()]} {new Date(item.p_c_cita).getDate()} de {window.meses[new Date(item.p_c_cita).getMonth()]} de {new Date(item.p_c_cita).getFullYear()}<br/>{new Date(item.p_c_cita).getHours()}:{(new Date(item.p_c_cita).getMinutes())<10 ? '0' : ''}{new Date(item.p_c_cita).getMinutes()}</div> : <div>Sin cita</div>}</td>
                                    <td>{item.p_c_ubicacion===1 ? 'Francia' : item.p_c_ubicacion===0 ? 'Chilpancingo' : 'Otra'}</td>
                                  </>
                                :
                                  null
                              }
                              {
                                sessionStorage._ROL==='ADMIN' || sessionStorage._ROL==='SUP' ?
                                  <>
                                    {
                                      this.state.lista_polizas.length>1 && this.state.w_aleatorio.length>1 && this.state.sql==='aleatorio' ?
                                        <td>{miAleatorio}{/*<br/>{hace}<br/>{miContador}*/}</td>
                                      :
                                        null
                                    }
                                    <td>
                                      {
                                        item.p_c_status===0 ? 
                                          <div>
                                            {this.state.sql==='aleatorio' ? null : <input type="checkbox" value={item.p_id} onChange={this.asignarChange} checked={this.state.arrayPolizas.includes(item.p_id+'|')}/>}
                                            {
                                              sessionStorage._ROL==='ADMIN' && item.p_c_llamadores ?
                                                <div>
                                                  Asignado antes:
                                                  {
                                                    item.p_c_llamadores.split("|").map(llama =>
                                                      <div>{this.state.w_usuarios.filter(p => p[7]===llama).map(nom => nom[1])}</div>
                                                    )
                                                  }
                                                </div>
                                              :
                                                null
                                            }
                                          </div>
                                        : this.state.consulta==='10' || (this.state.reasignar==='RR' && this.state.tipo_enviado==='3') || (this.state.reasignar==='RRR' && this.state.tipo_enviado>3) ? 
                                          <div>
                                            {
                                              this.state.w_usuarios.filter(p => p[0]===item.p_c_telefonistas).map(nombre => {
                                                inac = nombre[2]
                                                return(<div style={{color:nombre[2]===2 ? 'red' : null}} title={nombre[2]===2 ? 'Usuario inactivo' : null}>{nombre[1]} (Telefonista)</div>)
                                              })
                                            }
                                            {
                                              item.p_c_status>3 && item.p_c_status!=10 ? //eslint-disable-line
                                                this.state.w_usuarios.filter(p => p[0]===item.p_c_vendedores).map(nombre => 
                                                  <div style={{color:nombre[2]===2 ? 'red' : null}} title={nombre[2]===2 ? 'Usuario inactivo' : null}>{nombre[1]} (Vendedor)</div>
                                                )
                                              :
                                                null
                                            }
                                            {this.state.sql==='aleatorio' ? null : <input type="checkbox" value={item.p_id} onChange={this.asignarChange} checked={this.state.arrayPolizas.includes(item.p_id+'|')}/>}
                                          </div>
                                        :
                                          <div>
                                            {
                                              this.state.w_usuarios.filter(p => p[0]===item.p_c_telefonistas).map(nombre => {
                                                inac = nombre[2]
                                                return(<div style={{color:nombre[2]===2 ? 'red' : null}} title={nombre[2]===2 ? 'Usuario inactivo' : null}>{nombre[1]} (Telefonista)</div>)
                                              })
                                            }
                                            {
                                              item.p_c_status>3 && item.p_c_status!=10 ? //eslint-disable-line
                                                this.state.w_usuarios.filter(p => p[0]===item.p_c_vendedores).map(nombre => 
                                                  <div style={{color:nombre[2]===2 ? 'red' : null}} title={nombre[2]===2 ? 'Usuario inactivo' : null}>{nombre[1]} (Vendedor)</div>
                                                )
                                              :
                                                null
                                            }
                                          </div>
                                      }
                                    </td>
                                  </>
                                :
                                  null
                              }
                              <td>
                                <div className="flex_acciones">
                                  {sessionStorage._ROL==='SUP' || (sessionStorage._ROL==='TEL' && item.p_c_status===10) ? null : <div className="circulo_acciones" id={item.p_cuidado==='Sí' ? 'color_acciones_red' : null} title={item.p_cuidado==='Sí' ? 'CLIENTE CUIDADO - Información' : "Información"} onClick={this.handleShow.bind(this, item.p_id, 'Información')}>i</div>}
                                  {item.p_c_status>0 || (item.p_c_procesos>0 && (sessionStorage._ROL==='ADMIN' || sessionStorage._ROL==='SUP')) ? <div className="circulo_acciones" title="Actividades" onClick={this.handleShow.bind(this, item.p_id, 'Actividades')}>A</div> : null}
                                  {item.p_c_status===3 && (item.p_c_accion==='Agendar cita' || item.p_c_accion==='No asistió') && sessionStorage._ROL==='TEL'  ? <div className="circulo_acciones" id={this.props.location.state===item.p_id ? 'parpadea' : null} title="Reagendar cita" onClick={this.handleShow.bind(this, item.p_id, 'Nueva', 'Reagendar')}>R</div> : null}
                                  {(inac===2 && item.p_c_telefonistas>0) || (item.p_c_status>3 && (sessionStorage._ROL==='TEL' || sessionStorage._ROL==='SUP')) ? null : <div className="circulo_acciones" id={this.props.location.state===item.p_id ? 'parpadea' : null} title="Nueva actividad" onClick={this.handleShow.bind(this, item.p_id, 'Nueva')}>+</div>}
                                </div>
                              </td>
                            </tr>
                          )
                        })
                      }
                    </table>
                    <div id="cl"><br/></div>
                  </div>
                  <div className="pa_flex_siguiente">
                    <div>{this.state.inicial === 0 ? null : <button onClick={this.verSiguiente.bind(this,1)} className="siguiente">Anterior</button>}</div>
                    <div>{this.state.mostrar >= this.state.lista_polizas.filter(p => sessionStorage._ROL==='TEL' ? p.p_c_status<4 : p.p_id).length ? null : <button onClick={this.verSiguiente.bind(this,2)} className="siguiente">Siguiente</button>}</div>
                  </div>
                  {
                    sessionStorage._ROL==='ADMIN' ?
                      <table id="table-to-xls" style={{display:"none"}}>
                        <tr>
                          <th>#</th>
                          <th>Póliza(s)</th>
                          <th>Nombre</th>
                          <th>Apellido paterno</th>
                          <th>Apellido materno</th>
                          <th>RFC</th>
                          {/*<th>CURP</th>*/}
                          <th>Edad</th>
                          <th>Fecha de nacimiento</th>
                          <th colspan="2">Correos electrónicos</th>
                          <th colspan="6">Teléfonos</th>
                          <th>Estado</th>
                          <th>Municipio/Delegación</th>
                          <th>Código postal</th>
                          <th>Colonia/Fraccionamiento</th>
                          <th>Calle</th>
                          <th>Número</th>
                          <th>Estatus</th>
                          {/*
                          <th>Fecha registro</th>
                          <th>Hora registro</th>
                          <th>IP registro</th>
                          <th>ID usuario registro</th>
                          */}

                          <th>Fecha (registro/asistió)</th>
                          <th>Jubilado</th>
                          <th>Ubicación</th>
                          <th>Fecha de retiro de dividendos</th>
                          <th>Cuidado</th>
                          
                          <th></th>

                          <th>Tipo de actividad</th>
                          <th>Fecha actividad</th>
                          <th>Actividad</th>
                          <th>Supervisor</th>
                          <th>Telefonista</th>
                          <th>Recepcionista</th>
                          <th>Vendedor</th>
                          <th>Fecha cita</th>
                          <th>Ubicación</th>
                          <th>No. Procesos</th>
                          <th>No. Ventas</th>
                          <th>No. Veces(sin localizar/sin interes)</th>
                        </tr>
                        {
                          this.state.lista_polizas.map((item, i) =>
                            <tr key={i}>
                              <td>{item.contador}</td>
                              <td>{item.p_polizas}</td>
                              <td>{item.p_nombres}</td>
                              <td>{item.p_apellido_p}</td>
                              <td>{item.p_apellido_m}</td>
                              <td>{item.p_id}</td>
                              {/*<td>{item.p_curp}</td>*/}

                              <td>{item.p_id ? window._my_edad(item.p_id) : null}</td>
                              <td>{item.p_id ? window._my_nacimiento(item.p_id) : null}</td>

                              <td>{item.p_correo_1}</td>
                              <td>{item.p_correo_2}</td>

                              {
                                item.p_tel_1.substr(-1)==="W" || item.p_tel_1.substr(-1)==="w" ?
                                  <>
                                    <td>{item.p_tel_1.slice(0, -1)}</td>
                                    <td>WhatsApp</td>
                                  </>
                                :
                                  <>
                                    <td>{item.p_tel_1}</td>
                                    <td>Normal</td>
                                  </>
                              }
                              {
                                item.p_tel_2.substr(-1)==="W" || item.p_tel_2.substr(-1)==="w" ?
                                  <>
                                    <td>{item.p_tel_2.slice(0, -1)}</td>
                                    <td>WhatsApp</td>
                                  </>
                                :
                                  <>
                                    <td>{item.p_tel_2}</td>
                                    <td>{item.p_tel_2 ? "Normal" : ""}</td>
                                  </>
                              }
                              {
                                item.p_tel_3.substr(-1)==="W" || item.p_tel_3.substr(-1)==="w" ?
                                  <>
                                    <td>{item.p_tel_3.slice(0, -1)}</td>
                                    <td>WhatsApp</td>
                                  </>
                                :
                                  <>
                                    <td>{item.p_tel_3}</td>
                                    <td>{item.p_tel_3 ? "Normal" : ""}</td>
                                  </>
                              }

                              <td>{item.p_estado}</td>
                              <td>{item.p_municipio}</td>
                              <td>{item.p_cp}</td>
                              <td>{item.p_colonia}</td>
                              <td>{item.p_calle}</td>
                              <td>{item.p_numero}</td>

                              <td>
                                {
                                  item.p_status > 0 ?
                                    'Vigente'
                                  : 
                                    'Cancelada'
                                }
                              </td>
                              <td>{item.p_fecha}</td>
                              <td>{item.p_jubilado}</td>
                              <td>{item.p_ubicacion}</td>
                              <td>{item.p_ufecha_div}</td>
                              <td>{item.p_cuidado}</td>
                              
                              <td></td>

                              <td>
                                {
                                  item.p_c_status===0 ? 'Pólizas sin actividad (nuevas y regresadas)'
                                  : item.p_c_status===1 || item.p_c_status===2 ? 'Polizas asignadas al telefonista'
                                  : item.p_c_status===3 && item.p_c_accion!=='No asistió' ? 'Polizas con cita'
                                  : item.p_c_status===3 && item.p_c_accion==='No asistió' ? 'Polizas con cita, sin asistencia'
                                  : item.p_c_status===10 ? 'No localizadas (3 intentos de llamada / Sin interes)'
                                  : item.p_c_status===4 ? 'Pólizas en proceso de venta'
                                  : item.p_c_status>4 && item.p_c_status<10 ? 'Polizas con venta'
                                  : item.p_c_status===0 && item.p_c_ventas>0 ? 'Polizas con venta, libres'
                                  : item.p_c_status>3 || (item.p_c_status===3 && item.p_c_accion==='No asistió') ? 'Pólizas para regresar a nueva asignación'
                                  : null
                                }
                              </td>
                              <td>{item.p_c_fecha}</td>
                              <td>{item.p_c_accion}</td>
                              <td>{this.state.w_usuarios.filter(p => p[0]===item.p_c_supervisores).map(nom => nom[1])}</td>
                              <td>{this.state.w_usuarios.filter(p => p[0]===item.p_c_telefonistas).map(nom => nom[1])}</td>
                              <td>{this.state.w_usuarios.filter(p => p[0]===item.p_c_recepcionistas).map(nom => nom[1])}</td>
                              <td>{this.state.w_usuarios.filter(p => p[0]===item.p_c_vendedores).map(nom => nom[1])}</td>
                              <td>{item.p_c_cita}</td>
                              <td>{window._my_ubicacion.filter((p, n) => n===item.p_ubicacion).map(ubi => ubi)}</td>
                              <td>{item.p_c_procesos+1}</td>
                              <td>{item.p_c_ventas}</td>
                              <td>{item.p_c_nel}</td>
                            </tr>
                          )
                        }
                      </table>
                    :
                      null
                  }
                  <div id="cl"><br/></div>
                  {
                    this.state.sql==='aleatorio' || sessionStorage._ROL==='TEL' || sessionStorage._ROL==='REC' || sessionStorage._ROL==='VEN' || sessionStorage._ROL==='KAR' || this.state.lista_polizas.filter(p => p.p_c_status>0).length===this.state.lista_polizas.length ?
                      null
                    :
                      <center><div onClick={this.botonAsignar.bind(this, 3)} className="button">Asignar</div></center>
                  }
                  {
                    this.state.sql==='' && sessionStorage._ROL==='ADMIN' && (this.state.consulta==='10' || (this.state.reasignar==='RRR' && this.state.tipo_enviado>3)) ?
                      <center><div onClick={this.botonAsignar.bind(this, 9)} className="button">Regresar</div></center>
                    :
                      null
                  }
                  {
                    this.state.sql==='' && sessionStorage._ROL==='ADMIN' && this.state.tipo_enviado==='3' && this.state.consulta==='3' && this.state.reasignar==='RR' ?
                      <center><div onClick={this.botonAsignar.bind(this, 14)} className="button">Reasignar</div></center>
                    :
                      null
                  }
                </div> 
              :
                null
            }
          </div>
          <Pie />
        </div>
        <Modal
          show={this.state.show}
          onHide={this.state.semaforo==='NuevaA' || this.state.semaforo==='Editar' ? this.handleHide.bind(this, 1) : this.handleHide.bind(this)}
          dialogClassName="modal-90w"
          className="modal_fer"
          aria-labelledby="example-custom-modal-styling-title">

          <Modal.Body className="modal_cuadro_dos" data-aos="fade-down">
            <div className="jh-close" onClick={this.state.semaforo==='NuevaA' || this.state.semaforo==='Editar' ? this.handleHide.bind(this, 1) : this.handleHide.bind(this)}>X</div>
            
            {
              this.state.semaforo==='Semaforo' ?
                <div>
                  Estado del cliente:
                  <div id="cl"><br/></div>
                  {
                    sessionStorage._ROL==='TEL' ?
                      window._my_consulta.filter(p => p.tel>0).map((item, i) => 
                        <div className="flex_colores">
                          <div className={`circulo_semaforo ${item.color}`}></div>
                          {item.titulo}
                        </div>
                      )
                    :
                      window._my_consulta.filter(p => p.status>1).map((item, i) => 
                        <div className="flex_colores">
                          <div className={`circulo_semaforo ${item.color}`}></div>
                          {item.titulo}
                        </div>
                      )
                  }
                </div>
              : this.state.semaforo==='Información' ? 
                <form id="formulario">
                  <center><h10>Información</h10><br/>(Póliza(s): {this.state.p_polizas_actuales})</center>
                  <div id="cl"><br/></div>
                  <div className="pa_flex_reg">
                    <div className="de_flex_reg">
                      *Registro Federal de Contribuyentes (RFC):
                      <br/>
                      <input type="text" name="p_id" value={this.state.p_id} maxlength="13" minlength="13" onChange={this.handleChange} required disabled={sessionStorage._ROL==='TEL' ? true : false}/>
                      <div id="cl"></div>
                      *Nombres:
                      <br/>
                      <input type="text" name="p_nombres" placeholder="Nombre o nombres" value={this.state.p_nombres} onChange={this.handleChange} required disabled={sessionStorage._ROL==='TEL' ? true : false}/>
                      <div id="cl"></div>
                      *Apellido paterno:
                      <br/>
                      <input type="text" name="p_apellido_p" value={this.state.p_apellido_p} onChange={this.handleChange} required disabled={sessionStorage._ROL==='TEL' ? true : false}/>
                      <div id="cl"></div>
                      *Apellido materno:
                      <br/>
                      <input type="text" name="p_apellido_m" value={this.state.p_apellido_m} onChange={this.handleChange} required disabled={sessionStorage._ROL==='TEL' ? true : false}/>
                      <div id="cl"></div>
                      {/*
                      Clave Única de Registro de Población (CURP):
                      <br/>
                      <input type="text" name="p_curp" maxlength="18" value={this.state.p_curp} onChange={this.handleChange} disabled={sessionStorage._ROL==='TEL' ? true : false}/>
                      <div id="cl"></div>
                      */}
                      *Fecha que asistió:<br/>
                      <input type="date" name="p_fecha" placeholder="Fecha (Día/Mes/Año)" value={this.state.p_fecha} onChange={this.handleChange} required disabled={sessionStorage._ROL==='TEL' ? true : false}/>
                      <div id="cl"></div>
                      *Jubilado:
                      <br/>
                      <select name="p_jubilado" className="select" onChange={this.handleChange} required disabled={sessionStorage._ROL==='TEL' ? true : false}>
                        <option value="" selected={this.state.p_jubilado === "" ? true : false}></option>
                        {
                          _my_jubilados.map((item, i) =>
                            <option value={item.n} selected={this.state.p_jubilado === item.n ? true : false}>{item.d}</option>
                          )
                        }
                      </select>
                      <div id="cl"></div>
                      *Ultima fecha de dividendos:<br/>
                      <input type="month" name="p_ufecha_div" value={this.state.p_ufecha_div ? this.state.p_ufecha_div.slice(0, 7) : ""} onChange={this.handleChange}/>
                      <div id="cl"></div>
                      {
                        sessionStorage._ROL==='TEL' ?
                          <div>
                            <div onClick={this.handleClick} className="button puntero">Actualizar</div>
                            <div id="cl"></div>
                          </div>
                        :
                          null
                      }
                      *Ubicación:
                      <br/>
                      <select name="p_ubicacion" className="select" onChange={this.handleChange} required disabled={sessionStorage._ROL==='TEL' ? true : false}>
                        <option value="" selected={this.state.p_ubicacion === "" ? true : false}></option>
                        {
                          window._my_ubicacion.map((item, i) =>
                            <option value={i} key={i} selected={this.state.p_ubicacion === i ? true : false}>{item}</option>
                          )
                        }
                      </select>
                      <div id="cl"></div>
                      Cuidado:
                        <br/>
                        <select name="p_cuidado" className="select" onChange={this.handleChange} disabled={sessionStorage._ROL==='TEL' ? true : false}>
                          <option value="" selected={this.state.p_cuidado === "" ? true : false}></option>
                          <option value="Sí" selected={this.state.p_cuidado === "Sí" ? true : false}>Sí</option>
                        </select>
                        <div id="cl"></div>
                      
                      {
                        sessionStorage._ROL==='ADMIN' ?
                          <div>
                            Estatus:<br/>
                            <select name="p_status" className="select" onChange={this.handleChange}>
                              <option value="" selected={this.state.p_status === "" ? true : false}></option>
                              <option value="1" selected={this.state.p_status === 1 ? true : false}>Activo</option>
                              <option value="0" selected={this.state.p_status === 0 ? true : false}>Cancelado</option>
                            </select>
                            Fecha registro: <h11>{this.state.p_fecha}</h11>
                            <div id="cl"></div>
                          </div>
                        :
                          null
                      } 
                    </div>
                    <div className="de_flex_reg">
                      Teléfonos:
                      <br/>
                      {this.state.p_cuidado==='Sí' ? <b>NO MARCAR<br/></b> : null}
                      <div className="flex_telefonos">
                        <input type="text" name="p_tel_1" placeholder="*Telefóno 1" value={this.state.p_tel_1} onChange={this.handleChange} required disabled={sessionStorage._ROL==='TEL' ? true : false}/>
                        {
                          this.state.p_tel_1 ?
                            <div>
                              {
                                this.state.p_tel_1.substr(-1)==="W" || this.state.p_tel_1.substr(-1)==="w" ?
                                  <a href={`https://wa.me/521${this.state.p_tel_1.slice(0, -1)}?text=Hola%20${this.state.p_nombres}`} target="new"><img src="img/r_whats-01.svg" title="WhatsApp" alt="WhatsApp"/></a>
                                :
                                  null
                              }
                              <a href={`tel:${this.state.p_tel_1}`} target="new"><img src="img/con_1.svg" title="Teléfono" alt="Teléfono"/></a>
                            </div>
                          :
                            null
                        }
                      </div>
                      <div className="flex_telefonos">
                        <input type="text" name="p_tel_2" placeholder="Telefóno 2" value={this.state.p_tel_2} onChange={this.handleChange} disabled={sessionStorage._ROL==='TEL' && this.state.SN_tel_2 ? true : false}/>
                        {
                          this.state.p_tel_2 ?
                            <div>
                              {
                                this.state.p_tel_2.substr(-1)==="W" || this.state.p_tel_2.substr(-1)==="w" ?
                                  <a href={`https://wa.me/521${this.state.p_tel_2.slice(0, -1)}?text=Hola%20${this.state.p_nombres}`} target="new"><img src="img/r_whats-01.svg" title="WhatsApp" alt="WhatsApp"/></a>
                                :
                                  null
                              }
                              <a href={`tel:${this.state.p_tel_2}`} target="new"><img src="img/con_1.svg" title="Teléfono" alt="Teléfono"/></a>
                            </div>
                          :
                            null
                        }
                      </div>
                      <div className="flex_telefonos">
                        <input type="text" name="p_tel_3" placeholder="Telefóno 3" value={this.state.p_tel_3} onChange={this.handleChange} disabled={sessionStorage._ROL==='TEL' && this.state.SN_tel_3 ? true : false}/>
                        {
                          this.state.p_tel_3 ?
                            <div>
                              {
                                this.state.p_tel_3.substr(-1)==="W" || this.state.p_tel_3.substr(-1)==="w" ?
                                  <a href={`https://wa.me/521${this.state.p_tel_3.slice(0, -1)}?text=Hola%20${this.state.p_nombres}`} target="new"><img src="img/r_whats-01.svg" title="WhatsApp" alt="WhatsApp"/></a>
                                :
                                  null
                              }
                              <a href={`tel:${this.state.p_tel_3}`} target="new"><img src="img/con_1.svg" title="Teléfono" alt="Teléfono"/></a>
                            </div>
                          :
                            null
                        }
                      </div>
                      <div id="cl"></div>
                      <h12><b>Nota: Añadir la letra "W" al final del número si el teléfono tiene WhatsApp.</b></h12>
                      <div id="cl"><br/></div>
                      {
                        sessionStorage._ROL==='TEL' ?
                          <div>
                            <div onClick={this.handleClick} className="button puntero">Actualizar</div>
                            <div id="cl"><br/></div>
                          </div>
                        :
                          null
                      }
                      Correos electrónicos:
                      <br/>
                      <input type="text" name="p_correo_1" placeholder="e-mail 1" value={this.state.p_correo_1} onChange={this.handleChange} disabled={sessionStorage._ROL==='TEL' ? true : false}/>
                      <div id="cl"></div>
                      <input type="text" name="p_correo_2" placeholder="e-mail 2" value={this.state.p_correo_2} onChange={this.handleChange} disabled={sessionStorage._ROL==='TEL' ? true : false}/>
                      <div id="cl"></div>
                      Estado:
                      <br/>
                      <select name="p_estado" className="select" onChange={this.handleChange} disabled={sessionStorage._ROL==='TEL' ? true : false}>
                        <option value="" selected={this.state.p_estado === "" ? true : false}>- Selecciona -</option>
                        {
                          window._my_estado.map((item, i) => 
                            <option value={item.estado} key={i} selected={this.state.p_estado === item.estado ? true : false}>{item.estado}</option>
                          )
                        }
                      </select>
                      <div id="cl"></div>
                      Municipio/Delegación:
                      <br/>
                      <select name="p_municipio" className="select" onChange={this.handleChange} disabled={sessionStorage._ROL==='TEL' ? true : false}>
                        <option value="" selected={this.state.p_municipio === "" ? true : false}>- Selecciona -</option>
                        {
                          window._my_estado.filter(p => p.estado === this.state.p_estado).map(esta => 
                              esta.municipios.map((item, i) =>
                                <option value={item} key={i} selected={this.state.p_municipio === item ? true : false}>{item}</option>
                              )
                          )
                        }
                      </select>
                      <div id="cl"></div>
                      *Código postal:
                      <br/>
                      <input type="text" name="p_cp" value={this.state.p_cp} onChange={this.handleChange} required disabled={sessionStorage._ROL==='TEL' ? true : false}/>
                      <div id="cl"></div>
                      Colonia/Fraccionamiento:
                      <br/>
                      <input type="text" name="p_colonia" value={this.state.p_colonia} onChange={this.handleChange} disabled={sessionStorage._ROL==='TEL' ? true : false}/>
                      <div id="cl"></div>
                      Calle:
                      <br/>
                      <input type="text" name="p_calle" value={this.state.p_calle} onChange={this.handleChange} disabled={sessionStorage._ROL==='TEL' ? true : false}/>
                      <div id="cl"></div>
                      Número:
                      <br/>
                      <input type="text" name="p_numero" value={this.state.p_numero} onChange={this.handleChange} disabled={sessionStorage._ROL==='TEL' ? true : false}/>
                      <div id="cl"></div>
                      {
                        sessionStorage._ROL==='ADMIN' || sessionStorage._ROL==='VEN' ? 
                          <div>
                            <div id="cl"><br/></div>
                            Identificación:
                            <br/>
                            {
                              this.state.documento ?
                                <a href={`${window.ws}documentos/DOC_${this.state.p_id}.${this.state.documento}`} target="_new">
                                  <img src="img/documento.svg" title="Ver archivo" alt="Ver archivo" className="icono_archivo"/>
                                </a>
                              :
                                null
                            }
                            <input onChange={this.onSubir} id="file-upload" type="file" accept=".png, .jpg, .pdf"/>
                          </div>
                        :
                          null
                      }
                    </div>
                  </div>
                  {
                    sessionStorage._ROL==='TEL' ? 
                      null 
                    : 
                      <div>
                        <div id="cl"></div>
                        <h12>Campos obligatorios (*)</h12>
                        <div id="cl"><br/></div>
                        <center><div onClick={this.handleClick} className="button puntero">Actualizar</div></center>
                        <div id="cl"></div>
                      </div>
                  }
                </form>
              : this.state.semaforo==='Actividades' ?
                <div>
                  <center><h10>Actividades</h10><br/>(Cliente {this.state.ver})</center>
                  <div id="cl"><br/></div>
                  <div className="flex_act_nueva">
                    Total de actividades: {this.state.lista_actividades.filter(p => sessionStorage._ROL==='TEL' ? (/*p.ac_accion!=='Asignó cliente' && p.ac_accion!=='Reasignó cliente' && p.ac_nota!=='EL SISTEMA REGISTRO INASISTENCIA AUTOMÁTICAMENTE' &&*/p.ac_status!==11) : sessionStorage._ROL==='TELQUITA' ? p.ac_usuario_1===miID : p.ac_id>0).length}
                    {
                     this.state.lista_polizas.filter(p => sessionStorage._ROL==='TEL' ? p.p_c_status<4 : p.p_id).length==='0' || (this.state.p_c_status>3 && (sessionStorage._ROL==='TEL' || sessionStorage._ROL==='SUP')) ? 
                        null 
                      : 
                        <div className="flex_nueva">
                          <div className="nueva" title="Nueva actividad" onClick={this.handleShowTres.bind(this, 'NuevaA')}>+</div>
                          <div className="l_nueva" title="Nueva actividad" onClick={this.handleShowTres.bind(this, 'NuevaA')}>Nueva</div>
                        </div>
                    }
                  </div>
                  <div id="cl"></div>
                  {/*PONER PARA VER SOLO LAS SUYASthis.state.lista_actividades.filter(p => sessionStorage._ROL==='TEL' ? p.ac_usuario_1===miID : p.ac_id>0).length>0 ?*/}
                  {
                    this.state.lista_actividades.filter(p => sessionStorage._ROL==='TEL' ? (/*p.ac_accion!=='Asignó cliente' && p.ac_accion!=='Reasignó cliente' && p.ac_nota!=='EL SISTEMA REGISTRO INASISTENCIA AUTOMÁTICAMENTE' &&*/p.ac_status!==11) : p.ac_id>0).length>0 ?
                      <div className="tabla_responsiva">
                        <table className="carrillo" cellspacing="3">
                          <tr>
                            <th>#</th>
                            {sessionStorage._ROL==='ADMIN' ? <th>Quién</th> : null}
                            <th>Acción</th>
                            <th>Fecha registro</th>
                            <th>Nota</th>
                            <th></th>
                          </tr>
                          {
                            this.state.lista_actividades.filter(p => sessionStorage._ROL==='TEL' ? (/*p.ac_accion!=='Asignó cliente' && p.ac_accion!=='Reasignó cliente' && p.ac_nota!=='EL SISTEMA REGISTRO INASISTENCIA AUTOMÁTICAMENTE' &&*/p.ac_status!==11) : p.ac_id>0).map((item, i) => {
                              let ven_accion = item.ac_accion
                              if(ven_accion.slice(-2)===", "){
                                ven_accion = ven_accion.slice(0, -2);
                              }
                              return(
                                <tr key={i} className={item.ac_status===11 ? 'semaforo_amarillo' : null}>
                                  <td>{i+1}</td>
                                  {sessionStorage._ROL==='ADMIN' ? <td> {this.state.w_usuarios.filter(p => p[0]===item.ac_quien).map(nombre => <div style={{color:nombre[2]===2 ? 'red' : null}} title={nombre[2]===2 ? 'Usuario inactivo' : null}>{nombre[1]}</div>)}</td> : null}
                                  <td>
                                    {this.state.w_usuarios.filter(p => p[0]===item.ac_usuario_1).map(nombre => { _elCorreo = nombre[5]; _elRol = nombre[3]; return(nombre[1])})}&nbsp;
                                    {
                                      item.ac_status===11 ?
                                        `El cliente se regresó para nueva asignación`
                                      : item.ac_accion==='Asignó cliente' ?
                                        `asignó la póliza a ${this.state.w_usuarios.filter(p => p[0]===item.ac_usuario_2).map(nombre => nombre[1])}`
                                      : item.ac_accion==='Reasignó cliente' ?
                                        `reasignó la póliza a ${this.state.w_usuarios.filter(p => p[0]===item.ac_usuario_2).map(nombre => nombre[1])}`
                                      : item.ac_accion==='Contesta' && item.ac_complemento==='Agendar cita' ?
                                        `agendó una cita el día ${window.dia[new Date(item.ac_cita).getDay()]} ${new Date(item.ac_cita).getDate()} de ${window.meses[new Date(item.ac_cita).getMonth()]} de ${new Date(item.ac_cita).getFullYear()} a las ${new Date(item.ac_cita).getHours()}:${(new Date(item.ac_cita).getMinutes())<10 ? '0' : ''}${new Date(item.ac_cita).getMinutes()} en la ubicación ${window._my_ubicacion.filter((p, n) => n===item.ac_ubicacion).map(ubi => ubi)}`
                                      : item.ac_accion==='Contesta' && item.ac_complemento!=='Agendar cita' ?
                                        `realizó una llamada telefónica (${item.ac_complemento}) el día ${window.dia[new Date(item.ac_cita).getDay()]} ${new Date(item.ac_cita).getDate()} de ${window.meses[new Date(item.ac_cita).getMonth()]} de ${new Date(item.ac_cita).getFullYear()} a las ${new Date(item.ac_cita).getHours()}:${(new Date(item.ac_cita).getMinutes())<10 ? '0' : ''}${new Date(item.ac_cita).getMinutes()}`
                                      : item.ac_accion==='No contesta' ?
                                        `realizó una llamada telefónica (${item.ac_complemento}) el día ${window.dia[new Date(item.ac_cita).getDay()]} ${new Date(item.ac_cita).getDate()} de ${window.meses[new Date(item.ac_cita).getMonth()]} de ${new Date(item.ac_cita).getFullYear()} a las ${new Date(item.ac_cita).getHours()}:${(new Date(item.ac_cita).getMinutes())<10 ? '0' : ''}${new Date(item.ac_cita).getMinutes()}`
                                      : item.ac_accion==='Asistió' ?
                                        `registró asistencia de la cita agendada y envió al cliente al área de ventas con ${this.state.w_usuarios.filter(p => p[0]===item.ac_usuario_2).map(nombre => nombre[1])}`
                                      : item.ac_accion==='No asistió' ?
                                        `registró inasistencia de la cita agendada`
                                      : item.ac_accion==='Sin movimientos' ?
                                        `registró actividad sin movimientos`
                                      : 
                                        `realizó trámite de ${ven_accion}`
                                    }
                                  </td>
                                  <td>{new Date(item.ac_fecha).getDate()}/{window.meses[new Date(item.ac_fecha).getMonth()]}/{new Date(item.ac_fecha).getFullYear()}<br/>{new Date(item.ac_fecha).getHours()}:{(new Date(item.ac_fecha).getMinutes())<10 ? '0' : null}{new Date(item.ac_fecha).getMinutes()}</td>
                                  <td>
                                    {
                                      item.ac_nota==='EL SISTEMA REGISTRO INASISTENCIA AUTOMÁTICAMENTE' && sessionStorage._ROL==='TEL' ?
                                        ''
                                      :
                                        item.ac_nota
                                    }
                                  </td>
                                  <td>
                                    {item.ac_status<11 && (item.ac_accion!=='Asignó cliente' && item.ac_accion!=='Reasignó cliente') && (_elCorreo==sessionStorage.usuario /*eslint-disable-line*/ || sessionStorage._ROL==='ADMIN' || (sessionStorage._ROL==='KAR' && item.ac_actividad==='VEN') || (_elCorreo!==sessionStorage.usuario && sessionStorage._ROL==='SUP' && _elRol==='TEL')) ? <img src="img/editar.png" alt="Editar" title="Editar" height="20px" onClick={this.handleShowDos.bind(this, 'Editar', item.ac_id, item.ac_status)} className="grises puntero"/> : null}
                                    {item.ac_status<11 && ((item.ac_status===this.state.p_c_status && item.ac_status===1) || item.ac_status>1) && (sessionStorage._ROL==='ADMIN' || ((item.ac_status===1 || item.ac_status===2 || item.ac_status===3) && sessionStorage._ROL==='SUP')) ? <img src="img/eliminar.png" alt="Eliminar" title="Eliminar" height="20px" onClick={this.delSo.bind(this, item.ac_id, item.ac_status)} className="grises puntero"/> : null}
                                  </td>
                                </tr>
                              )
                            })
                          }
                        </table>
                        <div id="cl"><br/></div>                  
                      </div>
                    :
                      'No hay actividades registrardas'
                  }
                </div>
              :
                <form id="formulario">
                  <center><h10>{this.state.semaforo==='Editar' ? 'Editar' : 'Nueva'} actividad</h10><br/>(Cliente {this.state.ver})</center>
                  <div id="cl"><br/></div>
                  {
                    this.state.semaforo==='Editar' || sessionStorage._ROL!=='ADMIN' ?
                      null
                    :
                      <div>
                        *Tipo de actividad:<br/>
                        <select name="c_actividad" className="select" onChange={this.handleChange} required>
                          <option value="" selected={this.state.c_actividad === ""  ? true : false}>- Selecciona -</option>
                          {this.state.p_c_status===0 ? <option value="SUP" selected={this.state.c_actividad === "SUP"  ? true : false}>Supervisor</option> : null}
                          <option value="TEL" selected={this.state.c_actividad === "TEL"  ? true : false}>Telefonista</option> 
                          <option value="REC" selected={this.state.c_actividad === "REC"  ? true : false}>Recepcionista</option>
                          <option value="VEN" selected={this.state.c_actividad === "VEN"  ? true : false}>Vendedor</option>
                        </select>
                      </div>
                  }
                  {
                    sessionStorage._ROL==='VEN' || sessionStorage._ROL==='KAR' || (sessionStorage._ROL==='ADMIN' && this.state.c_actividad==='VEN') ?
                      //VENTAS
                      <div>
                        {
                          sessionStorage._ROL!=='TEL' ?
                            this.state.w_usuarios.filter(p => p[0]===this.state.p_c_telefonistas).map(item =>
                              <div style={{color:item[2]===2 ? 'red' : null}} title={item[2]===2 ? 'Usuario inactivo' : null}>Telefonista asignado: {item[1]}<br/><br/></div>
                            )
                          :
                            null
                        }
                        <h11>Actividad vendedor</h11>
                        {
                          this.state.ver_id>2 ?
                            <div>
                              {
                                this.state.w_usuarios.filter(p => p[0]===this.state.p_c_telefonistas).map(item =>
                                  <div style={{color:item[2]===2 ? 'red' : null}} title={item[2]===2 ? 'Usuario inactivo' : null}>Citado por: {item[1]}<br/><br/></div>
                                )
                              }
                            </div>
                          :
                            null
                        }
                        {
                          this.state.c_vendedores || this.state.p_c_vendedores ?
                            <div>
                              {
                                this.state.w_usuarios.filter(p => (p[0]===this.state.c_vendedores || p[0]===this.state.p_c_vendedores)).map(item =>
                                  <div style={{color:item[2]===2 ? 'red' : null}} title={item[2]===2 ? 'Usuario inactivo' : null}>Vendedor asignado: {item[1]}<br/><br/></div>
                                )
                              }
                            </div>
                          :
                            null
                        }
                        {
                          sessionStorage._ROL==='ADMIN' || sessionStorage._ROL==='KAR' ?
                            <div>
                              Vendedor:<br/>
                              <select name="c_vendedores" className="select" onChange={this.handleChange}>
                                <option value="" selected={this.state.c_vendedores === "" && this.state.p_c_vendedores === ""  ? true : false}>- Selecciona -</option> 
                                {
                                  this.state.w_usuarios.filter(p => p[3]==='VEN' && p[2]===3).map((item, i) =>
                                    <option value={item[0]} key={i} selected={this.state.c_vendedores === item[0] || this.state.p_c_vendedores === item[0] ? true : false}>{item[1]} - {item[4]===1 ? 'Francia' : 'Chilpancingo'}</option>
                                  )
                                }
                              </select>
                            </div>
                          :
                            null
                        }
                        *Trámite:<br/>
                        {/*
                        <select name="c_accion" className="select" onChange={this.handleChange} required>
                          <option value="" selected={this.state.c_accion === "" ? true : false}>- Selecciona -</option>
                          <option value="Ventas" selected={this.state.c_accion === "Ventas" ? true : false}>Ventas</option>
                          <option value="Servicios" selected={this.state.c_accion === "Servicios" ? true : false}>Servicios</option>
                          <option value="Dividendos" selected={this.state.c_accion === "Dividendos" ? true : false}>Dividendos</option>
                          <option value="Sin movimientos" selected={this.state.c_accion === "Sin movimientos" ? true : false}>Sin movimientos</option>
                        </select>
                        <div id="cl"></div>
                        {this.state.c_accion*/}
                        <div>
                          <input type="checkbox" name="Ventas" onChange={this.changeVentas} checked={this.state.c_accion.split(", ").filter(p => p==='Ventas').length>0 ? true : false}/>&nbsp;<h11>Ventas</h11>&nbsp;&nbsp;&nbsp;
                          <input type="checkbox" name="Servicios" onChange={this.changeVentas} checked={this.state.c_accion.split(", ").filter(p => p==='Servicios').length>0 ? true : false}/>&nbsp;<h11>Servicios</h11>&nbsp;&nbsp;&nbsp;
                          <input type="checkbox" name="Dividendos" onChange={this.changeVentas} checked={this.state.c_accion.split(", ").filter(p => p==='Dividendos').length>0 ? true : false}/>&nbsp;<h11>Dividendos</h11>&nbsp;&nbsp;&nbsp;
                          <input type="checkbox" name="Sin movimientos" onChange={this.changeVentas} checked={this.state.c_accion.split(", ").filter(p => p==='Sin movimientos').length>0 ? true : false}/>&nbsp;<h11>Sin movimientos</h11>
                          <div id="cl"><br/></div>
                        </div>

                        {/*
                        *Amabilidad del cliente:<br/>
                        <select name="c_atencion" className="select" onChange={this.handleChange} required>
                          <option value="" selected={this.state.c_atencion === "" || this.state.c_atencion === 0 ? true : false}>- Selecciona -</option>
                          <option value="1" selected={this.state.c_atencion === 1 ? true : false}>Buena</option>
                          <option value="2" selected={this.state.c_atencion === 2 ? true : false}>Regular</option>
                          <option value="3" selected={this.state.c_atencion === 3 ? true : false}>Mala</option>
                          <option value="4" selected={this.state.c_atencion === 4 ? true : false}>Cliente conflictivo</option>
                        </select>
                        <div id="cl"></div>
                        */}
                        Nota:<br/>
                        <textarea name="c_nota" id="area" onChange={this.handleChange} value={this.state.c_nota} placeholder="Nota:"/> 
                        <div id="cl"></div>
                        {this.state.semaforo==='Editar' ? null : <div><input type="checkbox" name="actualizar" onChange={this.handleChange} checked={this.state.actualizar===1 ? true : false}/>&nbsp;<h11>Actualizar estatus</h11></div>}
                      </div>
                      //FIN VENTAS
                    : sessionStorage._ROL==='REC' || (sessionStorage._ROL==='ADMIN' && this.state.c_actividad==='REC') ?
                      //RECEPCIÓN
                      <div>
                        {
                          sessionStorage._ROL!=='TEL' ?
                            this.state.w_usuarios.filter(p => p[0]===this.state.p_c_telefonistas).map(item =>
                              <div style={{color:item[2]===2 ? 'red' : null}} title={item[2]===2 ? 'Usuario inactivo' : null}>Telefonista asignado: {item[1]}<br/><br/></div>
                            )
                          :
                            null
                        }
                        <h11>Actividad recepcionista</h11>
                        {
                          this.state.ver_id>2 ?
                            <div>
                              {
                                this.state.w_usuarios.filter(p => p[0]===this.state.p_c_telefonistas).map(item =>
                                  <div style={{color:item[2]===2 ? 'red' : null}} title={item[2]===2 ? 'Usuario inactivo' : null}>Citado por: {item[1]}<br/><br/></div>
                                )
                              }
                            </div>
                          : 
                            null
                        }
                        {
                          this.state.semaforo!=='Editar' && this.state.p_c_telefonistas===0 ?
                            <div>
                              Citado por:<br/>
                              <select name="c_rec_telefonistas" className="select" onChange={this.handleChange}>
                                <option value="" selected={this.state.c_rec_telefonistas === "" ? true : false}>- Selecciona -</option>
                                {
                                  this.state.w_usuarios.filter(p => p[3]==='TEL' && p[2]===3).map((item, i) =>
                                    <option value={item[0]} key={i} selected={this.state.c_rec_telefonistas === item[0] ? true : false}>{item[1]}</option>
                                  )
                                }
                              </select>
                            </div>
                          :
                            null
                        }
                        {
                          this.state.semaforo==='Editar' ?
                            <div>
                              Asistencia: {this.state.c_accion}<br/>
                              <div id="cl"><br/></div>
                              {/*
                                this.state.c_atencion && this.state.c_atencion>0 ?
                                  <div>
                                    Amabilidad del cliente: {this.state.c_atencion===1 ? 'Buena' : this.state.c_atencion===2 ? 'Regular' : this.state.c_atencion===3 ? 'Mala' : 'Cliente conflictivo'}<br/>
                                    <div id="cl"><br/></div>
                                  </div>
                                :
                                  null
                              */}
                              {/*APENAS LO PUSE
                                this.state.c_vendedores ?
                                  <div>
                                    {
                                      this.state.w_usuarios.filter(p => p[0]===this.state.c_vendedores).map(item =>
                                        <div style={{color:item[2]===2 ? 'red' : null}} title={item[2]===2 ? 'Usuario inactivo' : null}>Vendedor asignado: {item[1]}<br/><br/></div>
                                      )
                                    }
                                  </div>
                                :
                                  null
                              */}
                            </div>
                          :
                            <div>
                              {
                                sessionStorage._ROL==='ADMIN' ?
                                  <div>
                                    Recepcionista:<br/>
                                    <select name="c_recepcionistas" className="select" onChange={this.handleChange}>
                                      <option value="" selected={this.state.c_recepcionistas === "" ? true : false}>- Selecciona -</option> 
                                      {
                                        this.state.w_usuarios.filter(p => p[3]==='REC' && p[2]===3).map((item, i) =>
                                          <option value={item[0]} key={i} selected={this.state.c_recepcionistas === item[0] ? true : false}>{item[1]}</option>
                                        )
                                      }
                                    </select>
                                  </div>
                                :
                                  null
                              }
                              <div>
                                *Asistencia:<br/>
                                <select name="c_accion" className="select" onChange={this.handleChange} required>
                                  <option value="" selected={this.state.c_accion === "" ? true : false}>- Selecciona -</option>
                                  <option value="Asistió" selected={this.state.c_accion === "Asistió" ? true : false}>Asistió</option>
                                  <option value="No asistió" selected={this.state.c_accion === "No asistió" ? true : false}>No asistió</option>
                                </select>
                                <div id="cl"></div>
                              </div>
                              {/*
                                this.state.c_accion==='Asistió' ?
                                  <div>
                                    *Amabilidad del cliente:<br/>
                                    <select name="c_atencion" className="select" onChange={this.handleChange} required>
                                      <option value="" selected={this.state.c_atencion === "" || this.state.c_atencion === 0 ? true : false}>- Selecciona -</option>
                                      <option value="1" selected={this.state.c_atencion === 1 ? true : false}>Buena</option>
                                      <option value="2" selected={this.state.c_atencion === 2 ? true : false}>Regular</option>
                                      <option value="3" selected={this.state.c_atencion === 3 ? true : false}>Mala</option>
                                      <option value="4" selected={this.state.c_atencion === 4 ? true : false}>Cliente conflictivo</option>
                                    </select>
                                    <div id="cl"></div>
                                  </div>
                                :
                                  null
                              */}
                            </div>
                        }
                        {
                          this.state.c_accion==='Asistió' ?  
                            <div>
                              *Asignar a un vendedor:<br/>
                              <select name="c_vendedores" className="select" onChange={this.handleChange} required>
                                <option value="" selected={this.state.c_vendedores === "" ? true : false}>- Selecciona -</option>
                                {
                                  this.state.w_usuarios.filter(p => p[3]==='VEN' && p[2]===3).map((item, i) =>
                                    <option value={item[0]} key={i} selected={this.state.c_vendedores === item[0] ? true : false}>{item[1]} - {item[4]===1 ? 'Francia' : 'Chilpancingo'}</option>
                                  )
                                }
                              </select>
                            </div>
                          :
                            null
                        }
                        Nota:<br/>
                        <textarea name="c_nota" id="area" onChange={this.handleChange} value={this.state.c_nota} placeholder="Nota:"/> 
                        <div id="cl"></div>
                        {this.state.semaforo==='Editar' ? null : <div><input type="checkbox" name="actualizar" onChange={this.handleChange} checked={this.state.actualizar===1 ? true : false}/>&nbsp;<h11>Actualizar estatus</h11></div>}
                      </div>
                      //FIN RECEPCIÓN
                    : sessionStorage._ROL==='TEL' || (sessionStorage._ROL==='SUP' && this.state.p_c_status>0) || (sessionStorage._ROL==='ADMIN' && this.state.c_actividad==='TEL') ?
                      //TELEFONISTA
                      <div>
                        {
                          sessionStorage._ROL!=='TEL' ?
                            this.state.w_usuarios.filter(p => p[0]===this.state.p_c_telefonistas).map(item =>
                              <div style={{color:item[2]===2 ? 'red' : null}} title={item[2]===2 ? 'Usuario inactivo' : null}>Telefonista asignado: {item[1]}<br/><br/></div>
                            )
                          :
                            null
                        }
                        <h11>Actividad telefonista</h11>
                        {
                          this.state.semaforo==='Editar' ?
                            <div>
                              Acción: Realizar llamada ({this.state.c_complemento})<br/>
                              <div id="cl"><br/></div>
                              {/*
                                this.state.c_atencion && this.state.c_atencion>0 ?
                                  <div>
                                    Amabilidad del cliente: {this.state.c_atencion===1 ? 'Buena' : this.state.c_atencion===2 ? 'Regular' : this.state.c_atencion===3 ? 'Mala' : 'Cliente conflictivo'}<br/>
                                    <div id="cl"><br/></div>
                                  </div>
                                :
                                  null
                              */}
                            </div>
                          :
                            <div>
                              *Realizar llamada:<br/>
                              <select name="c_accion" className="select" onChange={this.handleChange} required>
                                <option value="" selected={this.state.c_accion === "" ? true : false}>- Selecciona -</option>
                                <option value="Contesta" selected={this.state.c_accion === "Contesta" ? true : false}>Contesta</option>
                                <option value="No contesta" selected={this.state.c_accion === "No contesta" ? true : false}>No contesta</option>
                              </select>
                              <div id="cl"></div>
                              {
                                this.state.c_accion ?
                                  <div>
                                    *Acción:<br/>
                                    <select name="c_complemento" className="select" onChange={this.handleChange} required>
                                      <option value="" selected={this.state.c_complemento === "" ? true : false}>- Selecciona -</option>
                                      {
                                        this.state.c_accion==='Contesta' ?
                                          <>
                                            <option value="Llamar más tarde" selected={this.state.c_complemento === "Llamar más tarde" ? true : false}>Llamar más tarde</option>
                                            <option value="No le interesa" selected={this.state.c_complemento === "No le interesa" ? true : false}>No le interesa</option>
                                            <option value="Tiene asesor" selected={this.state.c_complemento === "Tiene asesor" ? true : false}>Tiene asesor</option>
                                            <option value="Asiste a otra oficina" selected={this.state.c_complemento === "Asiste a otra oficina" ? true : false}>Asiste a otra oficina</option>
                                            <option value="Agendar cita" selected={this.state.c_complemento === "Agendar cita" ? true : false}>Agendar cita</option>
                                            {/*<option value="Cita abierta" selected={this.state.c_complemento === "Cita abierta" ? true : false}>Cita abierta</option>*/}
                                          </>
                                        :
                                          <>
                                            <option value="No contesta" selected={this.state.c_complemento === "No contesta" ? true : false}>No contesta</option>
                                            <option value="No entra llamada" selected={this.state.c_complemento === "No entra llamada" ? true : false}>No entra llamada</option>
                                            <option value="Número ocupado" selected={this.state.c_complemento === "Número ocupado" ? true : false}>Número ocupado</option>
                                            <option value="Fuera de servicio" selected={this.state.c_complemento === "Fuera de servicio" ? true : false}>Fuera de servicio</option>
                                            <option value="Número equivocado" selected={this.state.c_complemento === "Número equivocado" ? true : false}>Número equivocado</option>
                                            <option value="Buzón de voz" selected={this.state.c_complemento === "Buzón de voz" ? true : false}>Buzón de voz</option>
                                          </>
                                      }
                                    </select>
                                    <div id="cl"></div>
                                  </div>
                                :
                                  null
                              }
                              {/*
                                this.state.c_accion==='Contesta' ?
                                  <div>
                                    *Amabilidad del cliente:<br/>
                                    <select name="c_atencion" className="select" onChange={this.handleChange} required>
                                      <option value="" selected={this.state.c_atencion === "" || this.state.c_atencion === 0 ? true : false}>- Selecciona -</option>
                                      <option value="1" selected={this.state.c_atencion === 1 ? true : false}>Buena</option>
                                      <option value="2" selected={this.state.c_atencion === 2 ? true : false}>Regular</option>
                                      <option value="3" selected={this.state.c_atencion === 3 ? true : false}>Mala</option>
                                      <option value="4" selected={this.state.c_atencion === 4 ? true : false}>Cliente conflictivo</option>
                                    </select>
                                    <div id="cl"></div>
                                  </div>
                                :
                                  null
                              */}
                            </div>
                        }
                        {
                          this.state.c_complemento==='Agendar cita' ?
                            <div>
                              *Ubicación:<br/>
                              <select name="c_ubicacion" className="select" onChange={this.handleChange} required>
                                <option value="" selected={this.state.c_ubicacion === "" ? true : false}></option>
                                <option value="0" selected={this.state.c_ubicacion === 0 ? true : false}>Chilpancingo</option>
                                <option value="1" selected={this.state.c_ubicacion === 1 ? true : false}>Francia</option>
                                <option value="2" selected={this.state.c_ubicacion === 2 ? true : false}>Otra</option>
                              </select>
                              <div id="cl"></div>
                            </div>
                          :
                            null
                        }
                        *{this.state.c_accion==='Agendar cita' ? 'Fecha de la cita' : 'Fecha'}:<br/>
                        <input type="date" name="c_fecha" placeholder="(Día/Mes/Año)" value={this.state.c_fecha} onChange={this.handleChange} required/>
                        <div id="cl"></div>
                        *{this.state.c_accion==='Agendar cita' ? 'Hora de la cita' : 'Hora'}:<br/>
                        <input type="time" name="c_hora" placeholder="HH:MM" value={this.state.c_hora} onChange={this.handleChange} required/>
                        <div id="cl"></div>
                        Nota:<br/>
                        <textarea name="c_nota" id="area" onChange={this.handleChange} value={this.state.c_nota} placeholder="Nota:"/> 
                        <div id="cl"></div>
                        {this.state.semaforo==='Editar' ? null : <div><input type="checkbox" name="actualizar" onChange={this.handleChange} checked={this.state.actualizar===1 ? true : false}/>&nbsp;<h11>Actualizar estatus</h11></div>}
                      </div>
                      //FIN TELEFONISTA
                    : sessionStorage._ROL==='SUP' || (sessionStorage._ROL==='ADMIN' && this.state.c_actividad==='SUP') ?
                      //SUPERVISOR
                      <div>
                        <h11>Asignar cliente</h11>
                        <div id="cl"><br/></div>
                        {
                          sessionStorage._ROL==='ADMIN' ?
                            <div>
                              Supervisor:<br/>
                              <select name="c_supervisores" className="select" onChange={this.handleChange}>
                                <option value="" selected={this.state.c_supervisores === "" ? true : false}>- Selecciona -</option> 
                                {
                                  this.state.w_usuarios.filter(p => p[3]==='SUP' && p[2]===3).map((item, i) =>
                                    <option value={item[0]} key={i} selected={this.state.c_supervisores === item[0] ? true : false}>{item[1]}</option>
                                  )
                                }
                              </select>
                            </div>
                          :
                            null
                        }
                        *Telefonista:<br/>
                        <select name="c_telefonistas" className="select" onChange={this.handleChange} required>
                          <option value="" selected={this.state.c_telefonistas === "" ? true : false}>- Selecciona -</option>
                          {
                            this.state.w_usuarios.filter(p => p[3]==='TEL' && p[2]===3).map((item, i) =>
                              <option value={item[0]} key={i} selected={this.state.c_telefonistas === item[0] ? true : false}>{item[1]}</option>
                            )
                          }
                        </select>
                      </div>
                      //FIN SUPERVISOR
                    :
                      null
                  }
                  <div id="cl"></div>
                  <h12>Campos obligatorios (*)</h12>
                  <div id="cl"><br/></div>
                  <center><div onClick={this.actividadClick} className="button puntero">{this.state.semaforo==='Editar' ? 'Editar' : 'Registrar'}</div></center>
                  <div id="cl"></div>
                </form>
            }

            <div className="espaciado_final"></div>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    ver_smenu: state.ver_smenu
  }
};

const Liga = Actividades;
export default connect(mapStateToProps)(Liga);