import React, { Component } from 'react';
import { connect } from 'react-redux';
import Axios from 'axios'
import Swal from 'sweetalert2'
import Modal from 'react-bootstrap/Modal';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { Element, Link as Ir } from 'react-scroll';
import { Chart } from "react-google-charts";

import Menus from './Menus'
import Pie from './Pie'

const alerta = (tipo,titulo) =>{
  Swal.fire({
  type: tipo,
  title: titulo
  });
}

class Actividades extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,

      w_usuarios: [],
      consultaDos: 2,

      c_supervisores: '',
      c_telefonistas: '',
      c_vendedores: '',
      c_recepcionistas: '',

      inicial: 0,
      mostrar: 25,
      cantidad: 25,

      c_id: '',
      c_nombre: '',
      c_rfc: '',
      c_fecha_1: '',
      c_fecha_2: '',
      c_conflictivo: '',
      c_actividad: '',
      c_ubicacion: '',
      c_accion: '',
      c_complemento: '',

      c_usuario_1: 0,
      c_usuario_2: 0,

      di_1: 'di_1',
      di_2: 'di_2',

      lista_actividades: [],

      r_actividad: '',
      r_accion: '',
      r_complemento: '',
      r_usuario_1: '',
      grafica_1: [],
      grafica_2: [],
      grafica_3: [],
      grafica_4: [],
    };
    this.botonEnviar = this.botonEnviar.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.mostrarChange = this.mostrarChange.bind(this);
  }

  componentWillMount () {
    window.H5_loading.show();
    window.scrollTo(0, 0)
    this.listaUsuarios();
  }
  
  componentDidMount () {
    window.H5_loading.hide();
  }

  cualConsultaDos(e) {
    this.setState({consultaDos:e})
    this.listaPolizas(e);
  }

  handleChange(e) {
    const {name, value} = e.target;
    if(name==='c_actividad'){
      this.setState({
        c_usuario_1: 0,
        c_accion: '',
        c_complemento: '',
      })
    }
    this.setState({[name]:value})
  }

  mostrarChange(e) {
    const {name, value} = e.target;
    this.setState({
      [name]:value,
      inicial: 0,
      mostrar: parseInt(value),
      cantidad: parseInt(value)
    })
  }

  verSiguiente(e){
    if(e===2){
      this.setState({
        mostrar: this.state.mostrar+this.state.cantidad,
        inicial: this.state.inicial+this.state.cantidad, 
      });
    }else{
      this.setState({
        mostrar: this.state.mostrar-this.state.cantidad,
        inicial: this.state.inicial-this.state.cantidad, 
      });
    }
  };

  tipoConsulta(id){
    if(id===1){
      this.setState({ di_1: 'di_1', di_2: 'di_2' });
    }else{
      this.setState({ di_1: 'di_2', di_2: 'di_1' });
    }
  }

  botonEnviar () {
    this.listaPolizas();
    this.setState({
      inicial: 0,
      mostrar: 25,
      cantidad: 25,
    })
  }

  handleShow(){
    window.H5_loading.show();
    this.setState({show: true})
    window.H5_loading.hide();
  };
  handleHide(e){
    this.setState({
      show: false,
    });
  };

  listaPolizas(e) {
    window.H5_loading.show();
    var params = new URLSearchParams();
    params.append('_USUARIO', sessionStorage.usuario);
    params.append('_ROL', sessionStorage._ROL);
    params.append('_Activa', sessionStorage._Activa);
    params.append('c_id', this.state.c_id);
    params.append('c_nombre', this.state.c_nombre);
    params.append('c_rfc', this.state.c_rfc);
    params.append('c_fecha_1', this.state.c_fecha_1);
    params.append('c_fecha_2', this.state.c_fecha_2);
    params.append('c_conflictivo', this.state.c_conflictivo);
    params.append('c_ubicacion', this.state.c_ubicacion);
    params.append('c_actividad', this.state.c_actividad);
    params.append('c_usuario_1', this.state.c_usuario_1);
    params.append('c_accion', this.state.c_accion);
    params.append('c_complemento', this.state.c_complemento);
    params.append('consultaDos', e);

    Axios.post(`${window.ws}wsActividades.php`, params)
        .then(response => {
          if(response.data.elError===2){
            alerta('error',''+response.data.mensaje);
          }else{
            this.setState({lista_actividades: response.data.lista_array})
            if(response.data.elError===1){
              alerta('warning',''+response.data.mensaje);
            }else{

             

              /*
              let gra_1 = [
                ["Task", "Hours per Day"],
                ["Work", 11],
                ["Eat", 2],
                ["Commute", 2],
                ["Watch TV", 2],
                ["Sleep", 7],
              ];
              */
              let gra_1 = [["Variable", "Cantidad"]];
              let gra_2 = [["Telefonista", "Contesta", "No contesta"]];
              let gra_3 = [["Variable", "Cantidad"]];
              let gra_4 = [["Telefonista", "No le interesa", "Tiene asesor", "Asiste a otra oficina", "Agendar cita", "No contesta", "No entra llamada", "Número ocupado", "Fuera de servicio", "Número equivocado"]];

              if(response.data.r_actividad==='TEL'){
                let unique_var = [...new Set(response.data.lista_array.map(item => item.ac_accion))];
                unique_var.map(item => 
                  gra_1.push([item, response.data.lista_array.filter(p => p.ac_accion===item).length])
                )

                unique_var = [...new Set(response.data.lista_array.map(item => item.ac_usuario_1))];
                unique_var.map(item => 
                  gra_2.push([
                    ""+this.state.w_usuarios.filter(p => p[0]===item).map(nombre => nombre[1]),
                    response.data.lista_array.filter(p => p.ac_usuario_1===item && p.ac_accion==='Contesta').length,
                    response.data.lista_array.filter(p => p.ac_usuario_1===item && p.ac_accion==='No contesta').length
                  ])
                )

                if(response.data.r_accion==='Contesta'){
                  gra_4 = [["Telefonista", "No le interesa", "Tiene asesor", "Asiste a otra oficina", "Agendar cita"]];
                  unique_var.map(item => 
                    gra_4.push([
                      ""+this.state.w_usuarios.filter(p => p[0]===item).map(nombre => nombre[1]),
                      response.data.lista_array.filter(p => p.ac_usuario_1===item && p.ac_complemento==='No le interesa').length,
                      response.data.lista_array.filter(p => p.ac_usuario_1===item && p.ac_complemento==='Tiene asesor').length,
                      response.data.lista_array.filter(p => p.ac_usuario_1===item && p.ac_complemento==='Asiste a otra oficina').length,
                      response.data.lista_array.filter(p => p.ac_usuario_1===item && p.ac_complemento==='Agendar cita').length
                    ])
                  )
                }else if(response.data.r_accion==='No contesta'){
                  gra_4 = [["No contesta", "No entra llamada", "Número ocupado", "Fuera de servicio", "Número equivocado"]];
                  unique_var.map(item => 
                    gra_4.push([
                      ""+this.state.w_usuarios.filter(p => p[0]===item).map(nombre => nombre[1]),
                      response.data.lista_array.filter(p => p.ac_usuario_1===item && p.ac_complemento==='No contesta').length,
                      response.data.lista_array.filter(p => p.ac_usuario_1===item && p.ac_complemento==='No entra llamada').length,
                      response.data.lista_array.filter(p => p.ac_usuario_1===item && p.ac_complemento==='Número ocupado').length,
                      response.data.lista_array.filter(p => p.ac_usuario_1===item && p.ac_complemento==='Fuera de servicio').length,
                      response.data.lista_array.filter(p => p.ac_usuario_1===item && p.ac_complemento==='Número equivocado').length
                    ])
                  )
                }else{
                  unique_var.map(item => 
                    gra_4.push([
                      ""+this.state.w_usuarios.filter(p => p[0]===item).map(nombre => nombre[1]),
                      response.data.lista_array.filter(p => p.ac_usuario_1===item && p.ac_complemento==='No le interesa').length,
                      response.data.lista_array.filter(p => p.ac_usuario_1===item && p.ac_complemento==='Tiene asesor').length,
                      response.data.lista_array.filter(p => p.ac_usuario_1===item && p.ac_complemento==='Asiste a otra oficina').length,
                      response.data.lista_array.filter(p => p.ac_usuario_1===item && p.ac_complemento==='Agendar cita').length,
                      response.data.lista_array.filter(p => p.ac_usuario_1===item && p.ac_complemento==='No contesta').length,
                      response.data.lista_array.filter(p => p.ac_usuario_1===item && p.ac_complemento==='No entra llamada').length,
                      response.data.lista_array.filter(p => p.ac_usuario_1===item && p.ac_complemento==='Número ocupado').length,
                      response.data.lista_array.filter(p => p.ac_usuario_1===item && p.ac_complemento==='Fuera de servicio').length,
                      response.data.lista_array.filter(p => p.ac_usuario_1===item && p.ac_complemento==='Número equivocado').length
                    ])
                  )
                }

                unique_var = [...new Set(response.data.lista_array.map(item => item.ac_complemento))];
                unique_var.map(item => 
                  gra_3.push([item, response.data.lista_array.filter(p => p.ac_complemento===item).length])
                )
              }else if(response.data.r_actividad==='REC'){
                gra_2 = [["Recepcionista", "Asistió", "No asistió"]];
                let unique_var = [...new Set(response.data.lista_array.map(item => item.ac_accion))];
                unique_var.map(item => 
                  gra_1.push([item, response.data.lista_array.filter(p => p.ac_accion===item).length])
                )

                unique_var = [...new Set(response.data.lista_array.map(item => item.ac_usuario_1))];
                unique_var.map(item =>
                  gra_2.push([
                    ""+this.state.w_usuarios.filter(p => p[0]===item).map(nombre => nombre[1]),
                    response.data.lista_array.filter(p => p.ac_usuario_1===item && p.ac_accion==='Asistió').length,
                    response.data.lista_array.filter(p => p.ac_usuario_1===item && p.ac_accion==='No asistió').length
                  ])
                )
              }else if(response.data.r_actividad==='VEN'){
                gra_2 = [["Vendedor", "Ventas", "Servicios", "Dividendos", "Sin movimientos"]];
                
                /*
                let unique_var = [...new Set(response.data.lista_array.map(item => item.ac_accion))];
                unique_var.map(item => 
                  gra_1.push([item, response.data.lista_array.filter(p => p.ac_accion===item).length])
                )
                */
                gra_1.push(["Ventas", response.data.lista_array.filter(p =>  p.ac_accion.split(", ").filter(r => r==='Ventas').length>0).length])
                gra_1.push(["Servicios", response.data.lista_array.filter(p =>  p.ac_accion.split(", ").filter(r => r==='Servicios').length>0).length])
                gra_1.push(["Dividendos", response.data.lista_array.filter(p =>  p.ac_accion.split(", ").filter(r => r==='Dividendos').length>0).length])
                gra_1.push(["Sin movimientos", response.data.lista_array.filter(p =>  p.ac_accion==='Sin movimientos').length])


                let unique_var = [...new Set(response.data.lista_array.map(item => item.ac_usuario_1))];
                unique_var.map(item => 
                  gra_2.push([
                    ""+this.state.w_usuarios.filter(p => p[0]===item).map(nombre => nombre[1]),

                    response.data.lista_array.filter(p => p.ac_usuario_1===item && p.ac_accion.split(", ").filter(r => r==='Ventas').length>0).length,
                    response.data.lista_array.filter(p => p.ac_usuario_1===item && p.ac_accion.split(", ").filter(r => r==='Servicios').length>0).length,
                    response.data.lista_array.filter(p => p.ac_usuario_1===item && p.ac_accion.split(", ").filter(r => r==='Dividendos').length>0).length,
                    response.data.lista_array.filter(p => p.ac_usuario_1===item && p.ac_accion==='Sin movimientos').length
                  ])
                )
              }else{
                let unique_var = [...new Set(response.data.lista_array.map(item => item.ac_actividad))];
                unique_var.map(item => { //eslint-disable-line
                  let leyenda = "Otros";
                  if(item==="VEN"){
                    leyenda = "Vendedor"
                  }else if(item==="REC"){
                    leyenda = "Recepción"
                  }else if(item==="TEL"){
                    leyenda = "Telefonista"
                  }
                  gra_1.push([leyenda, response.data.lista_array.filter(p => p.ac_actividad===item).length])
                })
              }

              this.setState({
                r_actividad: response.data.r_actividad,
                r_accion: response.data.r_accion,
                r_complemento: response.data.r_complemento,
                r_usuario_1: response.data.r_usuario_1,
                grafica_1: gra_1,
                grafica_2: gra_2,
                grafica_3: gra_3,
                grafica_4: gra_4,
              })
            }
          }
        })
        .catch(error => {
          console.log(error)
          alerta('error',''+error);
        })
        .then(function() {
          window.H5_loading.hide();
        })
  }

  listaUsuarios() {
    window.H5_loading.show();
    Axios.get(window.ws+'listas.php')
      .then(response => {
        //alerta('success','LOLERO'+response.data);
        this.setState({w_usuarios: response.data})
      })
      .catch(error => {
        //alerta('error','LOLERO'+error);
        console.log(error)
      })
      .then(function() {
        window.H5_loading.hide();
      })
  }

  render () {
    return (
      <div className="flex_smenu">
        <Menus />
        <div id={this.props.ver_smenu}>
          <div className="max_width padding_width">
            <h10>Historial de actividades</h10>
            <div className="linea"></div>
            <div id="cl"><br/></div>
            <div className="pa_flex_form">
              <div className="de_flex_form">
                <div className="consulta" id={this.state.di_1} onClick={this.tipoConsulta.bind(this, 1)}>Consulta Rápida</div>
              </div>
              <div className="de_flex_form">
                <div className="consulta" id={this.state.di_2} onClick={this.tipoConsulta.bind(this, 2)}>Consulta Avanzada</div>
              </div>
            </div>
            <div id="cl"><br/></div>
            <form id="formulario2">
              <div className="pa_flex_form">
                <div className="de_flex_form">
                  Tipo de actividad:<br/>
                  <select name="c_actividad" className="select" onChange={this.handleChange}>
                    <option value="">- Selecciona -</option>
                    <option value="SUP">Supervisor</option> 
                    <option value="TEL">Telefonista</option> 
                    <option value="REC">Recepcionista</option>
                    <option value="VEN">Vendedor</option>
                  </select>
                  Póliza:<br/>
                  <input type="text" placeholder="Número de póliza" name="c_id" onChange={this.handleChange}/>
                  <div id="cl"></div>
                  {
                    this.state.di_1 === 'di_2' ?
                      <div>
                        Registro Federal de Contribuyentes (RFC):<br/>
                        <input type="text" name="c_rfc" onChange={this.handleChange}/>
                        <div id="cl"></div>
                      </div>
                    :
                      null
                  }
                  <div id="cl"><br/></div>
                  {
                    this.state.lista_actividades.length>0 ?
                      <div className="flex_consulta">
                        <Ir to="recorrer" activeClass="active" spy={true} smooth={true} duration={1000} className="consultaDos" id={this.state.consultaDos===1 ? 'dic_1' : 'dic_2'} onClick={this.cualConsultaDos.bind(this, 1)} title="Ascendente">↑</Ir>
                        <Ir to="recorrer" activeClass="active" spy={true} smooth={true} duration={1000} className="consultaDos" id={this.state.consultaDos===2 ? 'dic_1' : 'dic_2'} onClick={this.cualConsultaDos.bind(this, 2)} title="Descendente">↓</Ir>
                      </div>
                    :
                      null
                  }
                </div>
                <div className="de_flex_form">
                  {
                    this.state.di_1 === 'di_2' ?
                      <div>
                        {
                          this.state.c_actividad==='TEL' ?
                            <div>
                              Acción:<br/>
                              <select name="c_accion" className="select" onChange={this.handleChange}>
                                <option value="">- Selecciona -</option>
                                <option value="Contesta">Contesta</option> 
                                <option value="No contesta">No contesta</option>
                              </select>
                              {
                                this.state.c_accion ?
                                  <div>
                                    Complemento:<br/>
                                    <select name="c_complemento" className="select" onChange={this.handleChange}>
                                      <option value="">- Selecciona -</option>
                                      {
                                        this.state.c_accion==='Contesta' ?
                                          <>
                                            <option value="Llamar mas tarde">Llamar mas tarde</option>
                                            <option value="No le interesa">No le interesa</option>
                                            <option value="Tiene asesor">Tiene asesor</option>
                                            <option value="Asiste a otra oficina">Asiste a otra oficina</option>
                                            <option value="Agendar cita">Agendar cita</option>
                                          </>
                                        :
                                          <>
                                            <option value="No contesta">No contesta</option>
                                            <option value="No entra llamada">No entra llamada</option>
                                            <option value="Número ocupado">Número ocupado</option>
                                            <option value="Fuera de servicio">Fuera de servicio</option>
                                            <option value="Número equivocado">Número equivocado</option>
                                          </>
                                      }
                                    </select>
                                  </div>
                                : 
                                  null
                              }
                            </div>
                          : this.state.c_actividad==='REC' ?
                            <div>
                              Acción:<br/>
                              <select name="c_accion" className="select" onChange={this.handleChange}>
                                <option value="">- Selecciona -</option>
                                <option value="Asistió">Asistió</option> 
                                <option value="No asistió">No asistió</option>
                              </select>
                            </div>
                          : this.state.c_actividad==='VEN' ?
                            <div>
                              Acción:<br/>
                              <select name="c_accion" className="select" onChange={this.handleChange}>
                                <option value="">- Selecciona -</option>
                                <option value="Ventas">Ventas</option>
                                <option value="Servicios">Servicios</option>
                                <option value="Dividendos">Dividendos</option>
                                <option value="Sin movimientos">Sin movimientos</option>
                              </select>
                            </div>
                          :
                            null
                        }
                        Usuario:<br/>
                        <select name="c_usuario_1" className="select" onChange={this.handleChange}>
                          <option value="">- Selecciona -</option>
                          {
                            this.state.c_actividad!=='' ?
                              this.state.w_usuarios.filter(p => p[3]===this.state.c_actividad).map((item, i) =>
                                <option value={item[0]} key={i}>{item[1]}</option>
                              )
                            :
                              this.state.w_usuarios.map((item, i) =>
                                <option value={item[0]} key={i}>{item[1]}</option>
                              )
                          }
                        </select>
                        <div id="cl"></div>
                        Nombre/Apellidos:<br/>
                        <input type="text" name="c_nombre" onChange={this.handleChange}/>
                        <div id="cl"></div>
                        {/*
                          this.state.c_actividad!=='SUP' && this.state.c_actividad!=='' ?
                            <>
                              Amabilidad:<br/>
                              <select name="c_conflictivo" className="select" onChange={this.handleChange}>
                                <option value="">- Selecciona -</option>
                                <option value="1">Buena</option>
                                <option value="2">Regular</option>
                                <option value="3">Mala</option>
                                <option value="4" className="red">Cliente conflictivo</option>
                              </select>
                              <div id="cl"></div>
                            </>
                          :
                            null
                        */}
                        Periodo o Fecha:<br/>
                        <input type="date" name="c_fecha_1" placeholder="Fecha inicial (Día/Mes/Año)" onChange={this.handleChange}/>
                        <div id="cl"></div>
                        <input type="date" name="c_fecha_2" placeholder="Fecha final (Día/Mes/Año)" onChange={this.handleChange}/>
                        <div id="cl"></div>
                      </div>
                    :
                      null
                  }
                  
                </div>
              </div>
              <div id="cl"><br/></div>
              <Ir to="recorrer" activeClass="active" spy={true} smooth={true} duration={1000}><div onClick={this.botonEnviar} className="button puntero">Consultar</div></Ir>
            </form>
            <div id="cl"></div>
            <Element name="recorrer"></Element>
            {
              this.state.lista_actividades.length > 0 ?
                <div>
                  {this.state.r_actividad==="SUP" ? null : <img src="img/ico_grafica.svg" alt="Graficas" className="graficas_seguir" title="Graficas" onClick={this.handleShow.bind(this)}/>}
                  <div id="cl"><br/></div>
                  <div className="linea"></div>
                  <div id="cl"><br/></div>
                  <ReactHTMLTableToExcel
                    className="derecha sin_submit"
                    table="table-to-xls"
                    filename="Actividades"
                    buttonText={<img src="img/ico_excel.svg" alt="Excel" className="derecha sin_submit" title="Descargar Excel"/>}
                  />
                  <div id="cl"><br/></div>
                  <div className="pa_flex_siguiente">
                    <div>
                      Resultados encontrados: {this.state.lista_actividades.length}           
                    </div>
                    {
                      this.state.lista_actividades.length>25 ? 
                        <select name="mostrar" className="select" onChange={this.mostrarChange} style={{width:90,float:'right'}}>
                          <option value={25} selected={this.state.cantidad === 25 ? true : false}>25</option>
                          {this.state.lista_actividades.length>=50 ? <option value={50} selected={this.state.cantidad === 50 ? true : false}>50</option> : null}
                          {this.state.lista_actividades.length>=100 ? <option value={100} selected={this.state.cantidad === 100 ? true : false}>100</option> : null}
                          {this.state.lista_actividades.length>=500 ? <option value={500} selected={this.state.cantidad === 500 ? true : false}>500</option> : null}
                          {this.state.lista_actividades.length>=1000 ? <option value={1000} selected={this.state.cantidad === 1000 ? true : false}>1000</option> : null}
                          <option value={99999999999999999999} selected={this.state.cantidad === 99999999999999999999 ? true : false}>Todos</option>
                        </select>
                      :
                        null
                    }
                  </div>
                  <div className="tabla_responsiva">
                    <table className="carrillo" cellspacing="3">
                      <tr>
                        <th>#</th>
                        <th>Póliza(s)</th>
                        <th>RFC</th>
                        <th>Nombre</th>
                        <th>Quién</th>
                        <th>Acción</th>
                        <th>Fecha registro</th>
                        <th>Nota</th>
                      </tr>
                      {
                        this.state.lista_actividades.slice(this.state.inicial, this.state.mostrar).map((item, i) => {
                          let ven_accion = item.ac_accion
                          if(ven_accion.slice(-2)===", "){
                            ven_accion = ven_accion.slice(0, -2);
                          }
                          return(
                            <tr key={i}>
                              <td>{item.contador}</td>
                              <td>{item.p_polizas}</td>
                              <td>{item.p_id}</td>
                              <td>{item.p_nombres}&nbsp;{item.p_apellido_p}&nbsp;{item.p_apellido_m}</td>
                              <td>{this.state.w_usuarios.filter(p => p[0]===item.ac_quien).map(nombre => <div style={{color:nombre[2]===2 ? 'red' : null}} title={nombre[2]===2 ? 'Usuario inactivo' : null}>{nombre[1]}</div>)}</td>
                              <td>
                                {this.state.w_usuarios.filter(p => p[0]===item.ac_usuario_1).map(nombre => nombre[1])}&nbsp;
                                {
                                  item.ac_status===11 ?
                                    `El cliente se regresó para nueva asignación`
                                  : item.ac_accion==='Asignó cliente' ?
                                    `asignó la póliza a ${this.state.w_usuarios.filter(p => p[0]===item.ac_usuario_2).map(nombre => nombre[1])}`
                                  : item.ac_accion==='Reasignó cliente' ?
                                    `reasignó la póliza a ${this.state.w_usuarios.filter(p => p[0]===item.ac_usuario_2).map(nombre => nombre[1])}`
                                  : item.ac_accion==='Contesta' && item.ac_complemento==='Agendar cita' ?
                                    `agendó una cita el día ${window.dia[new Date(item.ac_cita).getDay()]} ${new Date(item.ac_cita).getDate()} de ${window.meses[new Date(item.ac_cita).getMonth()]} de ${new Date(item.ac_cita).getFullYear()} a las ${new Date(item.ac_cita).getHours()}:${(new Date(item.ac_cita).getMinutes())<10 ? '0' : ''}${new Date(item.ac_cita).getMinutes()} en la ubicación ${window._my_ubicacion.filter((p, n) => n===item.ac_ubicacion).map(ubi => ubi)}`
                                  : item.ac_accion==='Contesta' && item.ac_complemento!=='Agendar cita' ?
                                    `realizó una llamada telefónica (${item.ac_complemento}) el día ${window.dia[new Date(item.ac_cita).getDay()]} ${new Date(item.ac_cita).getDate()} de ${window.meses[new Date(item.ac_cita).getMonth()]} de ${new Date(item.ac_cita).getFullYear()} a las ${new Date(item.ac_cita).getHours()}:${(new Date(item.ac_cita).getMinutes())<10 ? '0' : ''}${new Date(item.ac_cita).getMinutes()}, Amabilidad del cliente (${item.ac_atencion===1 ? 'Buena' : item.ac_atencion===2 ? 'Regular' : item.ac_atencion===3 ? 'Mala' : item[8]=== 4 ? 'Cliente conflictivo' : 'N/A'})`
                                  : item.ac_accion==='No contesta' ?
                                    `realizó una llamada telefónica (${item.ac_complemento}) el día ${window.dia[new Date(item.ac_cita).getDay()]} ${new Date(item.ac_cita).getDate()} de ${window.meses[new Date(item.ac_cita).getMonth()]} de ${new Date(item.ac_cita).getFullYear()} a las ${new Date(item.ac_cita).getHours()}:${(new Date(item.ac_cita).getMinutes())<10 ? '0' : ''}${new Date(item.ac_cita).getMinutes()}`
                                  : item.ac_accion==='Asistió' ?
                                    `registró asistencia de la cita agendada y envió al cliente al área de ventas con ${this.state.w_usuarios.filter(p => p[0]===item.ac_usuario_2).map(nombre => nombre[1])}`
                                  : item.ac_accion==='No asistió' ?
                                    `registró inasistencia de la cita agendada`
                                  : item.ac_accion==='Sin movimientos' ?
                                    `registró actividad sin movimientos`
                                  : 
                                    `realizó trámite de ${ven_accion}`
                                }
                              </td>
                              <td>{new Date(item.ac_fecha).getDate()}/{window.meses[new Date(item.ac_fecha).getMonth()]}/{new Date(item.ac_fecha).getFullYear()}<br/>{new Date(item.ac_fecha).getHours()}:{(new Date(item.ac_fecha).getMinutes())<10 ? '0' : null}{new Date(item.ac_fecha).getMinutes()}</td>
                              <td>{item.ac_nota}</td>
                            </tr>
                          )
                        })
                      }
                    </table>
                    <div id="cl"><br/></div>
                  </div>
                  <div className="pa_flex_siguiente">
                    <div>{this.state.inicial === 0 ? null : <button onClick={this.verSiguiente.bind(this,1)} className="siguiente">Anterior</button>}</div>
                    <div>{this.state.mostrar >= this.state.lista_actividades.length ? null : <button onClick={this.verSiguiente.bind(this,2)} className="siguiente">Siguiente</button>}</div>
                  </div>
   
                  <table id="table-to-xls" style={{display:"none"}}>
                    <tr>
                      <th style={{backgroundColor:'#659cdf'}}>#</th>
                      <th style={{backgroundColor:'#659cdf'}}>Póliza(s)</th>
                      <th style={{backgroundColor:'#659cdf'}}>Nombre</th>
                      <th style={{backgroundColor:'#659cdf'}}>Apellido paterno</th>
                      <th style={{backgroundColor:'#659cdf'}}>Apellido materno</th>
                      <th style={{backgroundColor:'#659cdf'}}>RFC</th>
                      <th style={{backgroundColor:'#659cdf'}}>Teléfono</th>
                      <th style={{backgroundColor:'#659cdf'}}>Correo</th>
                      <th style={{backgroundColor:'#659cdf'}}>Estatus</th>                      

                      <th style={{backgroundColor:'#ffd606'}}>Tipo de actividad</th>
                      <th style={{backgroundColor:'#ffd606'}}>Fecha</th>
                      <th style={{backgroundColor:'#ffd606'}}>Hora</th>
                      <th style={{backgroundColor:'#ffd606'}}>Acción</th>
                      <th style={{backgroundColor:'#ffd606'}}>Detalles</th>
                      <th style={{backgroundColor:'#ffd606'}}>Complemento</th>
                      <th style={{backgroundColor:'#ffd606'}}>Nota</th>
                      <th style={{backgroundColor:'#ffd606'}}>Fecha cita</th>
                      <th style={{backgroundColor:'#ffd606'}}>Hora cita</th>
                      <th style={{backgroundColor:'#ffd606'}}>Ubicación cita</th>
                      <th style={{backgroundColor:'#ffd606'}}>Quién</th>

                      <th style={{backgroundColor:'#9a74b1'}}>Usuario 1</th>
                      <th style={{backgroundColor:'#9a74b1'}}>Rol</th>
                      <th style={{backgroundColor:'#9a74b1'}}>Ubicación</th>
                      <th style={{backgroundColor:'#bcbcbc'}}>Usuario 2</th>
                      <th style={{backgroundColor:'#bcbcbc'}}>Rol</th>
                      <th style={{backgroundColor:'#bcbcbc'}}>Ubicación</th>

                      <th style={{backgroundColor:'#DE3E17'}}>Telefónista 4</th>
                      <th style={{backgroundColor:'#DE3E17'}}>Ubicación</th>
                    </tr>
                    {
                      this.state.lista_actividades.map((item, i) => {
                        let ven_accion = item.ac_accion
                        if(ven_accion.slice(-2)===", "){
                          ven_accion = ven_accion.slice(0, -2);
                        }
                        return(
                          <tr key={i}>
                            <td>{item.contador}</td>
                            <td>{item.p_polizas}</td>
                            <td>{item.p_nombres}</td>
                            <td>{item.p_apellido_p}</td>
                            <td>{item.p_apellido_m}</td>
                            <td>{item.p_id}</td>
                            {
                              item.p_tel_1.substr(-1)==="W" || item.p_tel_1.substr(-1)==="w" ?
                                <td>{item.p_tel_1.slice(0, -1)} WhatsApp</td>
                              :
                                <td>{item.p_tel_1} Normal</td>
                            }
                            <td>{item.p_correo_1}</td>
                            <td>
                              {
                                item.p_status > 0 ?
                                  'Vigente'
                                : 
                                  'Cancelada'
                              }
                            </td>

                            <td>
                              {
                                item.ac_actividad==='TEL' ?
                                  'Telefonista'
                                : item.ac_actividad==='REC' ?
                                  'Recepcionista'
                                : item.ac_actividad==='VEN' ?
                                  'Vendedor'
                                : 
                                  'Supervisor'
                              }
                            </td>
                            <td>{new Date(item.ac_fecha).getDate()}/{window.meses[new Date(item.ac_fecha).getMonth()]}/{new Date(item.ac_fecha).getFullYear()}</td>
                            <td>{new Date(item.ac_fecha).getHours()}:{(new Date(item.ac_fecha).getMinutes())<10 ? '0' : null}{new Date(item.ac_fecha).getMinutes()}</td>
                            <td>
                              {this.state.w_usuarios.filter(p => p[0]===item.ac_usuario_1).map(nombre => nombre[1])}&nbsp;
                              {
                                item.ac_status===11 ?
                                  `El cliente se regresó para nueva asignación`
                                : item.ac_accion==='Asignó cliente' ?
                                  `asignó la póliza a ${this.state.w_usuarios.filter(p => p[0]===item.ac_usuario_2).map(nombre => nombre[1])}`
                                : item.ac_accion==='Reasignó cliente' ?
                                  `reasignó la póliza a ${this.state.w_usuarios.filter(p => p[0]===item.ac_usuario_2).map(nombre => nombre[1])}`
                                : item.ac_accion==='Contesta' && item.ac_complemento==='Agendar cita' ?
                                  `agendó una cita el día ${window.dia[new Date(item.ac_cita).getDay()]} ${new Date(item.ac_cita).getDate()} de ${window.meses[new Date(item.ac_cita).getMonth()]} de ${new Date(item.ac_cita).getFullYear()} a las ${new Date(item.ac_cita).getHours()}:${(new Date(item.ac_cita).getMinutes())<10 ? '0' : ''}${new Date(item.ac_cita).getMinutes()} en la ubicación ${window._my_ubicacion.filter((p, n) => n===item.ac_ubicacion).map(ubi => ubi)}`
                                : item.ac_accion==='Contesta' && item.ac_complemento!=='Agendar cita' ?
                                  `realizó una llamada telefónica (${item.ac_complemento}) el día ${window.dia[new Date(item.ac_cita).getDay()]} ${new Date(item.ac_cita).getDate()} de ${window.meses[new Date(item.ac_cita).getMonth()]} de ${new Date(item.ac_cita).getFullYear()} a las ${new Date(item.ac_cita).getHours()}:${(new Date(item.ac_cita).getMinutes())<10 ? '0' : ''}${new Date(item.ac_cita).getMinutes()}, Amabilidad del cliente (${item.ac_atencion===1 ? 'Buena' : item.ac_atencion===2 ? 'Regular' : item.ac_atencion===3 ? 'Mala' : item[8]=== 4 ? 'Cliente conflictivo' : 'N/A'})`
                                : item.ac_accion==='No contesta' ?
                                  `realizó una llamada telefónica (${item.ac_complemento}) el día ${window.dia[new Date(item.ac_cita).getDay()]} ${new Date(item.ac_cita).getDate()} de ${window.meses[new Date(item.ac_cita).getMonth()]} de ${new Date(item.ac_cita).getFullYear()} a las ${new Date(item.ac_cita).getHours()}:${(new Date(item.ac_cita).getMinutes())<10 ? '0' : ''}${new Date(item.ac_cita).getMinutes()}`
                                : item.ac_accion==='Asistió' ?
                                  `registró asistencia de la cita agendada y envió al cliente al área de ventas con ${this.state.w_usuarios.filter(p => p[0]===item.ac_usuario_2).map(nombre => nombre[1])}`
                                : item.ac_accion==='No asistió' ?
                                  `registró inasistencia de la cita agendada`
                                : item.ac_accion==='Sin movimientos' ?
                                  `registró actividad sin movimientos`
                                : 
                                  `realizó trámite de ${ven_accion}`
                              }
                            </td>
                            <td>{ven_accion}</td>
                            <td>{item.ac_complemento}</td>
                            <td>{item.ac_nota}</td>
                            <td>{new Date(item.ac_cita).getDate()}/{window.meses[new Date(item.ac_cita).getMonth()]}/{new Date(item.ac_cita).getFullYear()}</td>
                            <td>{new Date(item.ac_cita).getHours()}:{(new Date(item.ac_cita).getMinutes())<10 ? '0' : null}{new Date(item.ac_cita).getMinutes()}</td>
                            <td>{window._my_ubicacion.filter((p, n) => n===item.ac_ubicacion).map(ubi => ubi)}</td>
                            <td>{this.state.w_usuarios.filter(p => p[0]===item.ac_quien).map(usu => usu[1])}</td>

                            <td>{this.state.w_usuarios.filter(p => p[0]===item.ac_usuario_1).map(usu => usu[1])}</td>
                            <td>{this.state.w_usuarios.filter(p => p[0]===item.ac_usuario_1).map(usu => usu[3])}</td>
                            <td>{this.state.w_usuarios.filter(p => p[0]===item.ac_usuario_1).map(usu => window._my_ubicacion.filter((p, n) => n===usu[4]).map(ubi => ubi))}</td>

                            <td>{this.state.w_usuarios.filter(p => p[0]===item.ac_usuario_2).map(usu => usu[1])}</td>
                            <td>{this.state.w_usuarios.filter(p => p[0]===item.ac_usuario_2).map(usu => usu[3])}</td>
                            <td>{this.state.w_usuarios.filter(p => p[0]===item.ac_usuario_2).map(usu => window._my_ubicacion.filter((p, n) => n===usu[4]).map(ubi => ubi))}</td>

                            <td>{this.state.w_usuarios.filter(p => p[0]===item.ac_telefonista).map(usu => usu[1])}</td>
                            <td>{this.state.w_usuarios.filter(p => p[0]===item.ac_telefonista).map(usu => window._my_ubicacion.filter((p, n) => n===usu[4]).map(ubi => ubi))}</td>
                          </tr>
                        )
                      })
                    }
                  </table>
                </div> 
              :
                null
            }
          </div>
          <Pie />
        </div>
       <Modal
          show={this.state.show}
          onHide={this.handleHide.bind(this)}
          dialogClassName="modal-90w"
          className="modal_fer"
          aria-labelledby="example-custom-modal-styling-title">

          <Modal.Body className="modal_cuadro_dos" data-aos="fade-down">
            <div className="jh-close" onClick={this.handleHide.bind(this)}>X</div>
            <center><h10>Graficas {this.state.r_actividad==="TEL" ? "(Telefonista)" : this.state.r_actividad==="VEN" ? "(Vendedor)" : this.state.r_actividad==="REC" ? "(Recepcionista)" : ""}</h10></center>
            <div id="cl"><br/></div>
            <center>
              {
                this.state.r_accion ? 
                  null
                :
                 <Chart
                    width="100%"
                    height="400px"
                    chartType="PieChart"
                    loader="Cargando..."
                    data={this.state.grafica_1}
                    options={{
                      title: this.state.r_actividad==="TEL" ? "Llamadas" : this.state.r_actividad==="VEN" ? "Movimientos" : this.state.r_actividad==="REC" ? "Asistencias" : "Actividad",
                      is3D: true,
                    }}
                  />
              }
              {
                this.state.r_actividad ?
                  <Chart
                    width="100%"
                    height="400px"
                    chartType="Bar"
                    loader="Cargando..."
                    data={this.state.grafica_2}
                    options={{
                      legend: {position: this.state.r_accion ? 'none' : 'right'},
                      chart: {
                        title: this.state.r_actividad==="VEN" ? "Vendedores" : this.state.r_actividad==="REC" ? "Recepcionistas" : "Telefonistas",
                        subtitle: this.state.r_actividad==="VEN" ? "Movimientos" : this.state.r_actividad==="REC" ? "Asistencias" : "Llamadas",
                      },
                    }}
                  />
                :
                  null
              }
              {
                this.state.r_actividad==='TEL' ?
                  <div>
                    {
                      this.state.r_complemento ? 
                        null
                      :
                       <Chart
                        width="100%"
                        height="400px"
                        chartType="PieChart"
                        //loader={window.H5_loading.show()}
                        loader="Cargando..."
                        data={this.state.grafica_3}
                        options={{
                          title: "Movimientos",

                          //pieHole: 0.4,
                          //is3D: false,
                        }}
                      />
                    }
                    <Chart
                      width="100%"
                      height="500px"
                      chartType="Bar"
                      loader="Cargando..."
                      data={this.state.grafica_4}
                      options={{
                        legend: {position: this.state.r_complemento ? 'none' : 'right'},
                        chart: {
                          title: "Telefonistas",
                          subtitle: "Movimientos",
                        },
                      }}
                    />
                  </div>
                :
                  null
              }
            </center>
            <div className="espaciado_final"></div>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    ver_smenu: state.ver_smenu
  }
};

const Liga = Actividades;
export default connect(mapStateToProps)(Liga);