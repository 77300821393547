import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom'
import { Accordion, AccordionItem } from 'react-sanfona';
import { connect } from 'react-redux';
import SMenu from './../redux/actionMenu';

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

class Menus extends Component {
  constructor() {
    super()
    this.state = {
      verMenu: 'ver_grande',
      verCerrar: 'ver_no',
    }
    this.verMenu = this.verMenu.bind(this)
    this.cerrarSesion = this.cerrarSesion.bind(this)
  }

  componentWillMount () {
    if(!sessionStorage.usuario){
      sessionStorage.setItem('_mensaje', 'error_1')
      //alerta('warning','No puedes ingresar a esta sección, primero debes iniciar tu sesión')
      this.props.history.push('login');
    }else{
      if(!sessionStorage._ROL || sessionStorage._ROL===''){
        sessionStorage.setItem('_mensaje', 'error_2')
        this.props.history.push('login');
      }else{
        //alerta('warning','No puedes ingresar a esta sección, primero debes iniciar tu sesión')
      }
    }
    if(window.screen.width<680){
      this.setState({verMenu: 'ver_responsive'})
    }
  }

  componentDidUpdate () {
    /*
    setInterval(() => {
      sessionStorage.removeItem('usuario')
      sessionStorage.removeItem('_ROL')
      sessionStorage.removeItem('_Nombre')
      this.props.history.push('login');
    }, 10000);
    */
    
  }

  activa(e) {
    this.setState({verMenu: 'ver_chico'})
  }

  verMenu(e) {
    if(e===1){
      if(this.state.verMenu==='ver_responsive'){
        this.setState({verMenu: 'ver_grande', verCerrar: 'ver_si_cerrar'})
      }else{
        this.setState({verMenu: 'ver_responsive', verCerrar: 'ver_no'})
      }
    }else{
      window.H5_loading.show();
      if(this.state.verMenu==='ver_chico'){
        this.setState({verMenu: 'ver_grande'})
      }else{
        this.setState({verMenu: 'ver_chico'})
      }
      this.props.cambiaMenu(this.state.verMenu)
      window.H5_loading.hide();
    }
  }

  cerrarSesion (e) {
    if(sessionStorage.usuario){
      sessionStorage.removeItem('usuario')
      sessionStorage.removeItem('_ROL')
      sessionStorage.removeItem('_Nombre')
      sessionStorage.removeItem('_Activa')
    }
    this.props.history.push('login');
  }

  render () {
    return (
      <>
        <img src={`${window.rutas}img/menu.svg`}alt="Menú" title="Menú" className="menu_res" height="30" onClick={this.verMenu.bind(this, 1)}/>
        <div className="smenu" id={this.state.verMenu}>
          <div className="smenu_cerrar" id={this.state.verCerrar} onClick={this.verMenu.bind(this, 1)}></div>
          <div className="overflow" id={this.state.verMenu==='ver_chico' ? 'nel' : 'yes'}>
            {/*<h3>local storage: {sessionStorage.idioma} | state: {this.state.lenguaje} | redux: {this.props.ver_smenu}<br/></h3>*/}
            <Link to="inicio"><img src={`${window.rutas}img/logo.svg`} alt="Invictus" title={`Invictus:  ${sessionStorage._Nombre}, ${sessionStorage.usuario}, ${sessionStorage._ROL}`} className="logo_invictus"/></Link>
            <div id="cl"><br/></div>
            <img src={`${window.rutas}img/menu.svg`} alt="Cambiar tamaño" title="Cambiar tamaño" className="smenu_ico grises" onClick={this.verMenu}/>
            <div id="cl"><br/></div>
            <Link to="inicio">
              <div className="flex_li"><img src={`${window.rutas}img/m_home.svg`} alt="Inicio" title="Inicio" className="grises"/><div className="irDos">Inicio</div></div>
            </Link>
            <Link to="micuenta">
              <div className="flex_li"><img src={`${window.rutas}img/m_usuario.svg`} alt="Mi cuenta" title="Mi cuenta" className="grises"/><div className="irDos">Mi cuenta</div></div>
            </Link>
            {/*
              sessionStorage._ROL === 'ADMIN' || sessionStorage._ROL === 'CAP' ?
                <div className="flex_li">
                  <img src={`${window.rutas}img/documento.svg`} alt="Polizas" title="Polizas" className="grises"/>
                  <Accordion allowMultiple={false} className="sin_acor">
                    <AccordionItem title="Polizas" className="dt">
                        <div>
                          <Link to="polizas" className="dd">Registrar</Link>
                          <div id="cl"></div>
                          <Link to="consultar" className="dd">Consultar</Link>
                          <div id="cl"></div>
                        </div>
                    </AccordionItem>
                  </Accordion>
                </div>
              :
                null
            */}
            {
              sessionStorage._ROL === 'ADMIN' || sessionStorage._ROL === 'CAP' || sessionStorage._ROL === 'REC' || sessionStorage._ROL === 'SUP' || sessionStorage._ROL === 'KAR' ?
                <Link to="polizas">
                  <div className="flex_li"><img src={`${window.rutas}img/m_documento.svg`} alt="Polizas" title="Polizas" className="grises"/><div className="irDos">Pólizas</div></div>
                </Link>
              :
                null
            }
            {
              sessionStorage._ROL === 'SUP' || sessionStorage._ROL === 'TEL' || sessionStorage._ROL === 'REC' || sessionStorage._ROL === 'VEN' || sessionStorage._ROL === 'KAR' ?
                <Link to="actividades">
                  <div className="flex_li"><img src={`${window.rutas}img/m_actividades.svg`} alt="Actividades" title="Actividades" className="grises"/><div className="irDos">Actividades</div></div>
                </Link>
              :
                null
            }         
            {
              sessionStorage._ROL === 'ADMIN' ?
                <div>
                  <div className="flex_li">
                    <img src={`${window.rutas}img/m_actividades.svg`} alt="Actividades" title="Actividades" className="grises"/>
                    <Accordion allowMultiple={false} className="sin_acor">
                      <AccordionItem title="Actividades" className="dt">
                          <div>
                            <Link to="actividades" className="dd">Actividades</Link>
                            <div id="cl"></div>
                            <Link to="historial" className="dd">Historial</Link>
                            <div id="cl"></div>
                          </div>
                      </AccordionItem>
                    </Accordion>
                  </div>
                  <div className="flex_li">
                    <img src={`${window.rutas}img/m_usuario.svg`} alt="Administrador" title="Administrador" className="grises"/>
                    <Accordion allowMultiple={false} className="sin_acor">
                      <AccordionItem title="Administrador" className="dt">
                          <div>
                            <Link to="usuarios" className="dd">Usuarios</Link>
                            <div id="cl"></div>
                            <Link to="expediente" className="dd">Expediente</Link>
                            <div id="cl"></div>
                            <Link to="avisos" className="dd">Avisos</Link>
                            <div id="cl"></div>
                          </div>
                      </AccordionItem>
                    </Accordion>
                  </div>
                </div>
              :
                null
            }
            {
              sessionStorage._ROL === 'ADMIN' || sessionStorage._ROL === 'REC' || sessionStorage._ROL === 'SUP' ?
                <Link to="checador">
                  <div className="flex_li"><img src={`${window.rutas}img/m_reloj.svg`} alt="Checador" title="Checador" className="grises"/><div className="irDos">Checador</div></div>
                </Link>
              :
                null
            }
            {
              sessionStorage._ROL && sessionStorage._ROL !== 'ADMIN' ?
                <Link to="buzon">
                  <div className="flex_li"><img src={`${window.rutas}img/m_buzon.svg`} alt="Buzón" title="Buzón" className="grises"/><div className="irDos">Buzón</div></div>
                </Link>
              :
                null
            }
            <a href="https://svgil23.cloud-mx-ns.net:2096/" target="_new">
              <div className="flex_li"><img src={`${window.rutas}img/m_correo.svg`} alt="Correo electrónico" title="Correo electrónico" className="grises"/><div className="irDos">Correo</div></div>
            </a>
            <div onClick={this.cerrarSesion} className="dd puntero">
              <div className="flex_li"><img src={`${window.rutas}img/m_salir.svg`} alt="Salir" title="Salir" className="grises"/><div className="irDos">Salir</div></div>
            </div>
            <div id="cl"><br/></div>
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    ver_smenu: state.ver_smenu
  }
};
const mapDispatchToProps = (dispatch) => {
  return {
    cambiaMenu: (cambia) => dispatch(SMenu(cambia)),
  }
}

const Liga = withRouter(React.memo(Menus));
export default connect(mapStateToProps,mapDispatchToProps)(Liga);