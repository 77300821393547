import React, { Component } from 'react';
import { connect } from 'react-redux';
import Axios from 'axios'
import Swal from 'sweetalert2'
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom'

import Header from './Header'
import Menus from './Menus'
import Pie from './Pie'

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

const alerta = (tipo,titulo) =>{
  Swal.fire({
  type: tipo,
  title: titulo
  });
}

class Inicio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      w_usuarios: [],
      show: true,
      arrayPolizas: [],
    };
  }

  componentWillMount () {
    window.H5_loading.show();
    window.scrollTo(0, 0)

    this.listaUsuarios();

    if(sessionStorage._mensajeAdmin==='error_1'){
      alerta('error','No tienes permisos para ingresar a esta sección');
      sessionStorage.removeItem('_mensajeAdmin')
    }

    if(sessionStorage._ROL==='VEN' || sessionStorage._ROL==='ADMIN' || sessionStorage._ROL==='KAR'){
      this.sinActividadPolizas();
    }
  }
  
  componentDidMount () {
    window.H5_loading.hide();
  }

  handleHide(){
    this.setState({
      show: false,
    })
  };

  listaUsuarios() {
    window.H5_loading.show();
    Axios.get(window.ws+'listas.php')
      .then(response => {
        //window.alerta('success','LOLERO'+response.data);
        this.setState({w_usuarios: response.data})
      })
      .catch(error => {
        //window.alerta('error','LOLERO'+error);
        console.log(error)
      })
      .then(function() {
        window.H5_loading.hide();
      })
  }

  sinActividadPolizas() {
    window.H5_loading.show();
    var params = new URLSearchParams();
    params.append('_USUARIO', sessionStorage.usuario);
    params.append('_ROL', sessionStorage._ROL);
    params.append('_Activa', sessionStorage._Activa);
    params.append('sin_actividad_polizas', 1);

    Axios.post(`${window.ws}wsPolizas.php?que=1`, params)
        .then(response => {
          this.setState({arrayPolizas: response.data.lista_array})
        })
        .catch(error => {

          console.log(error)
          //alerta('error',''+error);
        })
        .then(function() {
            window.H5_loading.hide();
        })
  }

  render () {
    var fecha_hoy = `${(window.hoy.getMonth())<10 ? '0' : ''}${window.hoy.getMonth()+1}-${(window.hoy.getDate())<10 ? '0' : ''}${window.hoy.getDate()}`
    return (
      <div className="flex_smenu">
        <Menus />
        <div id={this.props.ver_smenu}>
          <Header />
          <div className="max_width padding_width">
            <div className="pa_flex_2">
              <div className="de_flex_2" data-aos="fade-right">
                <h9>BIENVENIDO<br/>{sessionStorage._Nombre}</h9>
                <br/>
                {`${window.dia[window.date.getDay()]} ${window.date.getDate()} de ${window.meses[window.date.getMonth()]} de ${window.ano}`}
                {
                  this.state.arrayPolizas.length>0 ?
                    <div className="sin_act_clientes">
                      {sessionStorage._ROL==='ADMIN' ? 'Hay' : 'Tienes'} {this.state.arrayPolizas.length} {this.state.arrayPolizas.length>1 ? 'clientes' : 'cliente'} sin registrar actvidad.
                      <div><Link to={{pathname:`actividades`, state: 'sin_actividad_polizas'}}>Revisar</Link></div>
                    </div>
                  :
                    null
                }
              </div>
              {
                this.state.w_usuarios.filter(p => p[2]===3 && p[6]===fecha_hoy && p[5]!==sessionStorage.usuario).length ?
                  <div className="de_flex_2 cumpleaneros" data-aos="fade-left">
                    <div className="flex_cumpleaneros">
                      <img src="img/pastel.svg" title="Feliz cumpleaños" alt="Feliz cumpleaños"/>
                      <div>
                        Hoy cumple años:<br/>
                        <ul className="lista_cumple">
                          {
                            this.state.w_usuarios.filter(p => p[2]===3 && p[6]===fecha_hoy && p[5]!==sessionStorage.usuario).map((item, i) => 
                              <li key={i}>{item[1]}</li>
                            )
                          }
                        </ul>
                      </div>
                    </div>
                  </div>
                :
                  null
              }
            </div>
          </div>
          <Pie />
        </div>

        {
          this.state.w_usuarios.filter(p => p[2]===3 && p[6]===fecha_hoy && p[5]===sessionStorage.usuario).length ? 
            <Modal
              show={this.state.show}
              onHide={this.handleHide.bind(this)}
              dialogClassName="modal-90w"
              className="modal_fer"
              aria-labelledby="example-custom-modal-styling-title">

              <Modal.Body className="modal_cumple" data-aos="fade-down">
                <div className="flex_cumpleanos">
                  <img src="img/pastel.svg" title="Feliz cumpleaños" alt="Feliz cumpleaños"/>
                  Feliz cumpleaños te desea Invictus MetLife
                </div>
                <div id="cl"></div>
                <div className="espaciado_final"></div>
              </Modal.Body>
            </Modal>
          :
            null
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    ver_smenu: state.ver_smenu
  }
};

const Liga = Inicio;
export default connect(mapStateToProps)(Liga);