import React, { Component } from 'react';
import { connect } from 'react-redux';
import Axios from 'axios'
import Swal from 'sweetalert2'
import Modal from 'react-bootstrap/Modal';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { Element, Link as Ir } from 'react-scroll';

import Menus from './Menus'
import Pie from './Pie'

const alerta = (tipo,titulo) =>{
  Swal.fire({
  type: tipo,
  title: titulo
  });
}

class Checador extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time: new Date().toLocaleTimeString(),
      clave: '',
      w_usuarios: [],

      show: false,
      ver: '',

      ch_usuario: '',
      ch_fecha: '',
      ch_entrada: '',
      ch_salida: '',
      ch_nota: '',

      c_usuario: '',
      c_fecha_1: '',
      c_fecha_2: '',
      consulta: 0,

      inicial: 0,
      mostrar: 25,
      cantidad: 25,

      lista_asistencia: [],
      el_error: '',
    };
    this.botonChecar = this.botonChecar.bind(this);
    this.botonEnviar = this.botonEnviar.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.mostrarChange = this.mostrarChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentWillMount () {
    window.H5_loading.show();
    this.listaUsuarios();
    this.listaAsistencia();
    window.scrollTo(0, 0)
  }
  
  componentDidMount () {
    window.H5_loading.hide();

    setInterval(() => {
      this.tiempode();
    }, 1000);
  }

  tiempode() {
    this.setState({
      time: new Date().toLocaleTimeString()
    })
  }

  handleChange(e) {
    const {name, value} = e.target;
    this.setState({[name]:value})
  }

  mostrarChange(e) {
    const {name, value} = e.target;
    this.setState({
      [name]:value,
      inicial: 0,
      mostrar: parseInt(value),
      cantidad: parseInt(value)
    })
  }

  verSiguiente(e){
    if(e===2){
      this.setState({
        mostrar: this.state.mostrar+this.state.cantidad,
        inicial: this.state.inicial+this.state.cantidad, 
      });
    }else{
      this.setState({
        mostrar: this.state.mostrar-this.state.cantidad,
        inicial: this.state.inicial-this.state.cantidad, 
      });
    }
  };

  botonEnviar () {
    this.setState({consulta: 1});
    this.listaAsistencia(1);
  }

  botonChecar () {
    if(!this.state.clave){
      alerta('error','Escribe tu clave para poder checar');
    }else{
      window.H5_loading.show();
      var params = new URLSearchParams();
      params.append('_USUARIO', sessionStorage.usuario);
      params.append('_ROL', sessionStorage._ROL);
      params.append('_Activa', sessionStorage._Activa);
      params.append('clave', this.state.clave);

      Axios.post(`${window.ws}wsChecador.php`, params)
        .then(response => {
          if(response.data.elError===1){
            alerta('success',''+response.data.mensaje);
            this.listaAsistencia();
            this.setState({clave: ''});
          }else{
            alerta('error',''+response.data.mensaje);
          }
        })
        .catch(function (error) {
          alerta('error',''+error);
          console.log(error)
        })
        .then(function(response) {
          window.H5_loading.hide();
        });
    }
  }

  handleClick(e) {
    const {ver, ch_usuario, ch_fecha, ch_entrada, ch_salida, ch_nota} = this.state;
    if(!ch_usuario || !ch_fecha || !ch_entrada){
      alerta('error','Un campo requerido está vacio');
    }else{
      window.H5_loading.show();
      var params = new URLSearchParams();
      params.append('_USUARIO', sessionStorage.usuario);
      params.append('_ROL', sessionStorage._ROL);
      params.append('_Activa', sessionStorage._Activa);
      params.append('id', ver);
      params.append('que', 3);
      
      params.append('ch_usuario', ch_usuario);
      params.append('ch_fecha', ch_fecha);
      params.append('ch_entrada', ch_entrada);
      params.append('ch_salida', ch_salida);
      params.append('ch_nota', ch_nota);

      Axios.post(`${window.ws}wsChecador.php`, params)
        .then(response => {
          if(response.data.elError===1){
            alerta('success',''+response.data.mensaje);
            this.listaAsistencia();
            this.handleHide();
          }else{
            alerta('error',''+response.data.mensaje);
          }
        })
        .catch(function (error) {
          alerta('error',''+error);
          console.log(error)
        })
        .then(function(response) {
          window.H5_loading.hide();
        });
    }
  }

  listaAsistencia(e) {
    window.H5_loading.show();
    var params = new URLSearchParams();
    params.append('_USUARIO', sessionStorage.usuario);
    params.append('_ROL', sessionStorage._ROL);
    params.append('_Activa', sessionStorage._Activa);
    params.append('c_usuario', this.state.c_usuario);
    params.append('c_fecha_1', this.state.c_fecha_1);
    params.append('c_fecha_2', this.state.c_fecha_2);
    params.append('que', 1);
    if(e){
      params.append('consulta', e);
    }else{
      params.append('consulta', this.state.consulta);
    }
    

    Axios.post(`${window.ws}wsChecador.php`, params)
        .then(response => {
          this.setState({el_error: response.data.elError})
          if(response.data.elError===2){
            alerta('error',''+response.data.mensaje);
          }else{
            this.setState({lista_asistencia: response.data})
            if(response.data.elError===1 && (e===1 || this.state.consulta===1)){
              alerta('warning',''+response.data.mensaje);
            }
          }
        })
        .catch(error => {
          console.log(error)
          alerta('error',''+error);
        })
        .then(function() {
            window.H5_loading.hide();
        })
  }

  listaUsuarios() {
    window.H5_loading.show();
    Axios.get(window.ws+'listas.php')
      .then(response => {
        //window.alerta('success','LOLERO'+response.data);
        this.setState({w_usuarios: response.data})
      })
      .catch(error => {
        //window.alerta('error','LOLERO'+error);
        console.log(error)
      })
      .then(function() {
        window.H5_loading.hide();
      })
  }

  handleShow(ver){
    window.H5_loading.show();
    this.setState({show: true})
    if(ver!==''){
      this.state.lista_asistencia.filter(p => p[1] === ver).map(item => { //eslint-disable-line
        this.setState({
          ver: ver,
          ch_usuario: item[2],
          ch_fecha: item[4],
          ch_entrada: item[5],
          ch_salida: item[6],
          ch_nota: item[7],
        })
      })
    }
    window.H5_loading.hide();
  };

  handleHide(){
    this.setState({
      show: false,
      ver: '',
      ch_usuario: '',
      ch_fecha: '',
      ch_entrada: '',
      ch_salida: '',
      ch_nota: '',
    })
  };

  delSo (e) {
      Swal.fire({
        title: '¿Deseas eliminar el registro?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, eliminar!'
      }).then((result) => {
        if (result.value) {
              window.H5_loading.show();

              var params = new URLSearchParams();
              params.append('_USUARIO', sessionStorage.usuario);
              params.append('_ROL', sessionStorage._ROL);
              params.append('_Activa', sessionStorage._Activa);
              params.append('id', e);
              params.append('que', 2);

              Axios.post(`${window.ws}wsChecador.php`, params)
                  .then(response => {
                    if(response.data.elError===1){
                      alerta('success',''+response.data.mensaje);
                      this.listaAsistencia();
                    }else{
                      alerta('error',''+response.data.mensaje);
                    }
                  })
                  .catch(error => {
                    console.log(error)
                    //alerta('error',''+error);
                  })
                  .then(function() {
                      window.H5_loading.hide();
                  })
        }
      })
  }

  render () {
    return (
      <div className="flex_smenu">
        <Menus />
        <div id={this.props.ver_smenu}>
          <div className="max_width padding_width">
            <h10>Reloj Checador</h10>
            <div className="linea"></div>
            <div id="cl"><br/></div>

            <div className="pa_flex_2 alinear">
              <div className='box-reloj'>
                <h1>{this.state.time}</h1>
                {`${window.dia[window.date.getDay()]} ${window.date.getDate()} de ${window.meses[window.date.getMonth()]}`}
              </div>
              {
                this.state.el_error===3 ?
                  <div id="formulario2" className="de_flex_2">No es posible checar en esté equipo.</div>
                :
                  <div id="formulario2" className="de_flex_2">
                    Clave para checar:<br/>
                    <input type="password" placeholder="Escribe tu clave para checar tu entrada y/o salida" name="clave" value={this.state.clave} onChange={this.handleChange}/>
                    <div id="cl"></div>
                    <div onClick={this.botonChecar} className="button puntero">Checar</div>
                  </div>
              }
            </div>

            <div id="cl"><br/><br/></div>

            {
              sessionStorage._ROL==='ADMIN' ?
                <div>
                  <form id="formulario2">
                    <h10>Asistencias</h10>
                    <div className="linea"></div>
                    <div id="cl"><br/></div>
                    <div className="flex_nueva">
                      <div className="nueva" onClick={this.handleShow.bind(this, '')}>+</div>
                      <div className="l_nueva" onClick={this.handleShow.bind(this, '')}>Nuevo</div>
                    </div>
                    <div id="cl"><br/></div>
                    <div className="pa_flex_form">
                      <div className="de_flex_form">
                        Usuario:<br/>
                        <select name="c_usuario" className="select" onChange={this.handleChange}>
                          <option value="">- Selecciona -</option>
                          {
                            this.state.w_usuarios.map((item, i) =>
                              <option value={item[0]} key={i}>{item[1]}</option>
                            )
                          }
                        </select>
                        <div id="cl"></div>
                      </div>
                      <div className="de_flex_form">
                        Periodo o Fecha:<br/>
                        <input type="date" name="c_fecha_1" placeholder="Fecha inicial (Día/Mes/Año)" onChange={this.handleChange}/>
                        <div id="cl"></div>
                        <input type="date" name="c_fecha_2" placeholder="Fecha final (Día/Mes/Año)" onChange={this.handleChange}/>
                        <div id="cl"></div>
                      </div>
                    </div>
                    <div id="cl"><br/></div>
                    <Ir to="recorrer" activeClass="active" spy={true} smooth={true} duration={1000}><div onClick={this.botonEnviar} className="button puntero">Consultar</div></Ir>
                  </form>
                  <div id="cl"></div>
                  <Element name="recorrer"></Element>
                </div>
              :
                null
            }
            {
              this.state.lista_asistencia.length > 0 ?
                <div>
                  <div id="cl"><br/></div>
                  <div className="linea"></div>
                  <div id="cl"><br/></div>
                  {
                    sessionStorage._ROL==='ADMIN' ?
                      <div>
                        <ReactHTMLTableToExcel
                          className="derecha sin_submit"
                          table="table-to-xls"
                          filename="Asistencias"
                          buttonText={<img src="img/ico_excel.svg" alt="Excel" className="derecha sin_submit" title="Descargar Excel"/>}
                          />
                        <div id="cl"><br/></div>
                      </div>
                    :
                      null
                  }
                  <div className="pa_flex_siguiente">
                    {this.state.consulta>0 ? 'Consulta de asistencias' : 'Asistencia de hoy'}
                    , resultados encontrados: {this.state.lista_asistencia.length}
                    {
                      this.state.lista_asistencia.length>25 ? 
                        <select name="mostrar" className="select" onChange={this.mostrarChange} style={{width:90,float:'right'}}>
                          <option value={25}>25</option>
                          {this.state.lista_asistencia.length>=50 ? <option value={50}>50</option> : null}
                          {this.state.lista_asistencia.length>=100 ? <option value={100}>100</option> : null}
                          {this.state.lista_asistencia.length>=500 ? <option value={500}>500</option> : null}
                          {this.state.lista_asistencia.length>=1000 ? <option value={1000}>1000</option> : null}
                          <option value={99999999999999999999}>Todas</option>
                        </select>
                      :
                        null
                    }
                  </div>
                  <div className="tabla_responsiva">
                    <table className="carrillo" cellspacing="3">
                      <tr>
                        <th>#</th>
                        <th>Usuario</th>
                        <th>Fecha</th>
                        <th>Entrada</th>
                        <th>Salida</th>
                        {
                          sessionStorage._ROL==='ADMIN' ?
                            <>
                              <th>Nota</th>
                              <th></th>
                            </>
                          :
                            null
                        }
                      </tr>
                      {
                        this.state.lista_asistencia.slice(this.state.inicial, this.state.mostrar).map((item, i) =>
                          <tr key={i}>
                            <td>{item[0]}</td>
                            <td>{this.state.w_usuarios.filter(p => p[0]===item[2]).map(nombre => nombre[1])}</td>
                            <td>{new Date(item[4]).getDate()}/{window.meses[new Date(item[4]).getMonth()]}/{new Date(item[4]).getFullYear()}</td>
                            <td>{item[5]}</td>
                            <td>{item[6]}</td>
                            {
                              sessionStorage._ROL==='ADMIN' ?
                                <>
                                  <td>
                                    {item[6]<'15:50:00' ? 'Falta' : item[5]>'09:10:00' ? 'Retardo' : item[6] ? 'Asistencia' : ''}
                                    {item[7] ? <>({item[7]})</> : ''}
                                  </td>
                                  <td>
                                    <img src="img/editar.png" alt="Editar" title="Editar" height="20px" onClick={this.handleShow.bind(this, item[1])} className="grises puntero"/>
                                    <img src="img/eliminar.png" alt="Eliminar" title="Eliminar" height="20px" onClick={this.delSo.bind(this, item[1])} className="grises puntero"/>
                                  </td>
                                </>
                              :
                                null
                            }
                          </tr>
                        )
                      }
                    </table>
                    <div id="cl"><br/></div>
                  </div>
                  <div className="pa_flex_siguiente">
                    <div>{this.state.inicial === 0 ? null : <button onClick={this.verSiguiente.bind(this,1)} className="siguiente">Anterior</button>}</div>
                    <div>{this.state.mostrar >= this.state.lista_asistencia.length ? null : <button onClick={this.verSiguiente.bind(this,2)} className="siguiente">Siguiente</button>}</div>
                  </div>
                  {
                    sessionStorage._ROL==='ADMIN' ?
                      <table id="table-to-xls" style={{display:"none"}}>
                        <tr>
                          <th>#</th>
                          <th>Usuario</th>
                          <th>Fecha</th>
                          <th>Entrada</th>
                          <th>Salida</th>
                          <th>Nota</th>
                          <th>IP Entrada</th>
                          <th>IP Salida</th>
                          <th>Checo con</th>
                        </tr>
                        {
                          this.state.lista_asistencia.map((item, i) =>
                            <tr key={i}>
                              <td>{item[0]}</td>
                              <td>{this.state.w_usuarios.filter(p => p[0]===item[2]).map(nombre => nombre[1])}</td>
                              <td>{new Date(item[4]).getDate()}/{window.meses[new Date(item[4]).getMonth()]}/{new Date(item[4]).getFullYear()}</td>
                              <td>{item[5]}</td>
                              <td>{item[6]}</td>
                              <td>
                                {item[6]<'15:50:00' ? 'Falta' : item[5]>'09:10:00' ? 'Retardo' : item[6] ? 'Asistencia' : ''}
                                {item[7] ? <>({item[7]})</> : ''}
                              </td>
                              <td>{item[8]}</td>
                              <td>{item[9]}</td>
                              <td>{this.state.w_usuarios.filter(p => p[0]===item[3]).map(nombre => nombre[1])}</td>
                            </tr>
                          )
                        }
                      </table>
                    :
                      null
                  }
                </div> 
              :
                null
            }
          </div>
          <Pie />
        </div>
        <Modal
          show={this.state.show}
          onHide={this.handleHide.bind(this)}
          dialogClassName="modal-90w"
          className="modal_fer"
          aria-labelledby="example-custom-modal-styling-title">

          <Modal.Body className="modal_cuadro_dos" data-aos="fade-down">
            <div className="jh-close" onClick={this.handleHide.bind(this)}>X</div>
              <form id="formulario">
                <center><h10>{this.state.ver ? 'Editar asistencia' : 'Registrar asistencia'}</h10></center>
                <div id="cl"><br/></div>
                *Usuario:<br/>
                <select name="ch_usuario" className="select" onChange={this.handleChange} required disabled={this.state.ver ? true : false}>
                  <option value="" selected={this.state.ch_usuario === ""  ? true : false}>- Selecciona -</option> 
                  {
                    this.state.w_usuarios.filter(p => p[3]!=='ADMIN' && p[2]===3).map((item, i) =>
                      <option value={item[0]} key={i} selected={this.state.ch_usuario === item[0] ? true : false}>{item[1]}</option>
                    )
                  }
                </select>
                <div id="cl"></div>
                *Fecha:<br/>
                <input type="date" name="ch_fecha" value={this.state.ch_fecha} placeholder="Día/Mes/Año" onChange={this.handleChange} required disabled={this.state.ver ? true : false}/>
                <div id="cl"></div>
                *Entrada:<br/>
                <input type="time" name="ch_entrada" value={this.state.ch_entrada} placeholder="HH:MM" onChange={this.handleChange} required/>
                <div id="cl"></div>
                *Salida:<br/>
                <input type="time" name="ch_salida" value={this.state.ch_salida} placeholder="HH:MM" onChange={this.handleChange} required/>
                <div id="cl"></div>
                Nota:<br/>
                <textarea name="ch_nota" id="area" onChange={this.handleChange} value={this.state.ch_nota} placeholder="Ejemplo Justificante"/> 
                <div id="cl"></div>
                <h12>Campos obligatorios (*)</h12>
                <div id="cl"><br/></div>
                <center><div onClick={this.handleClick} className="button puntero">{this.state.ver ? 'Editar' : 'Registrar'}</div></center>
                <div id="cl"></div>
                <div className="espaciado_final"></div>
              </form>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    ver_smenu: state.ver_smenu
  }
};

const Liga = Checador;
export default connect(mapStateToProps)(Liga);