import { ES, IN } from './actionTypes';

const SMenu = (cambia) =>{
	if(cambia==='ver_grande'){
		return {type: IN}
	}else{
		return {type: ES}
	}

	//return {type: cambia}
};

export default SMenu;