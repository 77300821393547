import { ES, IN } from './actionTypes';

const stateIni = {
	ver_smenu: IN
}

const reducerMenu = (state = stateIni, action) => {
	switch (action.type) {
		case IN:
			return ({ver_smenu: ES})
		case ES:
			return ({ver_smenu: IN})
		default:
		return state
	}
}

export default reducerMenu;