import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'

class Errores extends Component {
  componentWillMount () {
    window.H5_loading.show();
    if(window.obtenerGet('activar')){
      if(window.obtenerGet('activar')==='si'){
        sessionStorage.setItem('_dondeIr', 'pagar')
      }
      if(window.obtenerGet('activar')!=='si' && window.obtenerGet('activar')!=='no' && window.obtenerGet('activar')!=='no_pago' && window.obtenerGet('activar')!=='si_pago'){
        this.props.history.push('inicio');
      }
    }else{
      this.props.history.push('inicio');
    }
    window.scrollTo(0, 0)
  }
  
  componentDidMount () {
    window.H5_loading.hide();
  }

  render () {
    return (
      <div>
        <div className="max_width padding_centrado">
          {
            window.obtenerGet('activar')==='si' ?
              <center><h10>Tu cuenta ha sido activada.</h10></center>
            :
              window.obtenerGet('activar')==='no_pago' ?
                <center><h10>Ha ocurrido un error al realizar el pago, intentalo nuevamente.</h10></center>
              :
                window.obtenerGet('activar')==='si_pago' ?
                  <center><h10>Gracias, has realizado tu pedido y se esta validando. Revisa tu correo electrónico para ver el seguimiento de tu pedido.</h10></center>
                :
                  <center><h10>Ha ocurrido un error intentalo más tarde.</h10></center>
          }
        </div>
      </div>
    )
  }
}

export default withRouter(Errores);